import { LogoFormat } from "src/core/utility/imageProcessing";

export interface PiktogrammItem {
  FILEID: string;
  TYP: LogoFormat.SVG;
  BEZ: string;
  CONTENT: string;
}

export interface PiktogrammeBackendResponse {
  Meta: any;
  Items: PiktogrammItem[];
}

export const defaultPiktogramm: PiktogrammItem = Object.freeze({
  FILEID: "",
  TYP: LogoFormat.SVG,
  BEZ: "",
  CONTENT: "",
});
