import { ExpansionPanelActionEvent } from "@progress/kendo-react-layout";
import AbkOenormEditor from "../../../../../core/components/organisms/AbkOenormEditor";
import ABKControlledExpansionPanel from "../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import editorTools from "../../../../../core/components/organisms/AbkOenormEditor/editorTools";
import * as React from "react";
import CustomViewHtmlHook from "../../../../../core/components/organisms/AbkOenormEditor/CustomHTMLDialog/CustomViewHtmlHook";

interface NotizPanelProps {
  title: React.ReactNode;
  isExpanded: boolean;
  onAction: ((event: ExpansionPanelActionEvent) => void) | undefined;
  notiz: string;
  setNotiz: (notiz: string) => void;
}

const NotizPanel = ({
  title,
  isExpanded,
  onAction,
  notiz,
  setNotiz,
}: NotizPanelProps) => {
  return (
    <ABKControlledExpansionPanel
      title={title}
      key="notiz"
      className={"notiz-section"}
      expanded={isExpanded}
      onAction={onAction}
      style={{ padding: "0" }}
    >
      {isExpanded && (
        <AbkOenormEditor
          tools={[
            ...editorTools.oenormTools,
            ...editorTools.fontTools,
            ...editorTools.commonTools,
            [CustomViewHtmlHook],
            ...editorTools.extraTools,
            ...editorTools.oenormTabelleTools,
            ...editorTools.tableTools,
          ]}
          value={notiz}
          setValue={(value) => {
            setNotiz(value);
          }}
          hidden={!isExpanded}
        />
      )}
    </ABKControlledExpansionPanel>
  );
};

export default NotizPanel;
