import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { QUERY_KEY_PARAMETERLISTE } from "./useGetSingleParameterliste";
import { Parameterliste } from "../interfaces/parameterlisten";

const useGetAllContainerParameterlisten = (db: string, contId: string) => {
  const getAllContainerParameterlisten = async () => {
    const response = await axios.get(`/api/db/${db}/cont/${contId}/pl`);
    const parameterlisten = response.data.Items;
    return parameterlisten as Parameterliste[];
  };

  return useQuery({
    queryKey: [QUERY_KEY_PARAMETERLISTE, db, contId],
    queryFn: () => getAllContainerParameterlisten(),
  });
};

export default useGetAllContainerParameterlisten;
