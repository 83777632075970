import { DATA_KEY } from "../../../../../abk-shared/components/organisms/ABKBasisGrid/constants";
import formatNumber from "../../../../../abk-shared/utility/formatNumber";
import {
  EintragBackend,
  EintragFrontend,
} from "../../../interfaces/parameterlisten";
import {
  booleanFieldMapper,
  booleanFieldReverseMapper,
  eintragShowIfMapper,
  eintragShowIfReverseMapper,
  parTypeMapper,
  parTypeReverseMapper,
} from "./maps";

export const backendEintragToFrontend = (backendEintrag: EintragBackend) =>
  ({
    ...backendEintrag,
    ITEMTYPE: parTypeMapper.get(backendEintrag.ITEMTYPE),
    MENGENABH: booleanFieldMapper.get(backendEintrag.MENGENABH),
    FAVORIT: booleanFieldMapper.get(backendEintrag.FAVORIT),
    SUMMEBILDEN: booleanFieldMapper.get(backendEintrag.SUMMEBILDEN),
    REQUIRED: booleanFieldMapper.get(backendEintrag.REQUIRED),
    SHOWIF: eintragShowIfMapper.get(backendEintrag.SHOWIF),
    /*
      Wir erstellen einen `internalValue` für `NUMNK`, weil wir wollen, dass
      es im Column-Menü nicht als `null` angezeigt wird, wenn wir keinen
      Wert für das Column-Menü haben.
    */
    NUMNK: createInternalValueForNumber(backendEintrag.NUMNK),
    NUMMIN: createInternalValueForNumber(
      backendEintrag.NUMMIN,
      backendEintrag.NUMNK
    ),
    NUMMAX: createInternalValueForNumber(
      backendEintrag.NUMMAX,
      backendEintrag.NUMNK
    ),
    Funktion: returnEmptyStringIfEmptyArray(backendEintrag.Funktion),
    Produkt: returnEmptyStringIfEmptyArray(backendEintrag.Produkt),
  }) as EintragFrontend;

/*
  Wir konvertieren ein leeres Array in einen leeren String, weil wir wollen,
  dass es im Column-Menü nur einen Wert gibt, wenn wir leere Arrays haben.
  Das Column-Menü wird für jedes leere Array einen Wert anzeigen, wenn wir
  das Array nicht in einen leeren String konvertieren.
*/
function returnEmptyStringIfEmptyArray(array: unknown[] | null | undefined) {
  if (!Array.isArray(array)) return "";
  if (array.length === 0) return "";
  return array;
}

export const createInternalValueForNumber = (
  number: number | null | undefined,
  numberOfFractionDigits?: number
) => ({
  [DATA_KEY.DISPLAYED]: formatNumber(number, numberOfFractionDigits),
  [DATA_KEY.INTERNAL]: number,
});

export const frontendEintragToBackend = (frontendEintrag: EintragFrontend) =>
  ({
    ...frontendEintrag,
    ITEMTYPE: parTypeReverseMapper.get(frontendEintrag.ITEMTYPE),
    MENGENABH: booleanFieldReverseMapper.get(frontendEintrag.MENGENABH),
    FAVORIT: booleanFieldReverseMapper.get(frontendEintrag.FAVORIT),
    SUMMEBILDEN: booleanFieldReverseMapper.get(frontendEintrag.SUMMEBILDEN),
    REQUIRED: booleanFieldReverseMapper.get(frontendEintrag.REQUIRED),
    SHOWIF: eintragShowIfReverseMapper.get(frontendEintrag.SHOWIF),
    NUMNK: frontendEintrag.NUMNK.internal,
    NUMMIN: frontendEintrag.NUMMIN.internal,
    NUMMAX: frontendEintrag.NUMMAX.internal,
    Funktion: Array.isArray(frontendEintrag.Funktion)
      ? frontendEintrag.Funktion
      : [],
    Produkt: Array.isArray(frontendEintrag.Produkt)
      ? frontendEintrag.Produkt
      : [],
  }) as EintragBackend;
