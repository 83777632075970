import { Button } from "@progress/kendo-react-buttons";
import { WindowActionsBar } from "@progress/kendo-react-dialogs";
import { ABKPageLoader } from "../../../../abk-shared/components/atoms/ABKPageLoader";
import ABKWindow from "../../../../abk-shared/components/molecules/ABKWindow";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { xIcon, sparklesIcon } from "@progress/kendo-svg-icons";
import React from "react";
import { Job, AISummary } from "../../../../core/interfaces/Job";
import { Cont } from "../../../../core/interfaces/Cont";
import { Versioned } from "../../../../core/interfaces/Versioned";

const AIGenerateBeschreibung = ({
  pageState,
  updatePageState,
}: {
  pageState: Versioned<Cont>;
  updatePageState: (state: any) => void;
}) => {
  const [generatingBeschreibung, setGeneratingBeschreibung] = useState(false);
  const anchorRef = useRef<any>(null);

  // State to hold the text area value
  const [textAreaValue, setTextAreaValue] = useState<string>("");
  const [showAIPrompt, setShowAIPrompt] = useState<boolean>(false);

  // Ref to keep track of the current index in the typewriter effect
  const indexRef = useRef<number>(1); // Start from 1 to include the first character
  // Ref to store the timeout ID
  const timeoutRef = useRef<any>(null);
  // State to determine when typing is completed
  const [typingCompleted, setTypingCompleted] = useState<boolean>(false);

  const toggleAIPrompt = () => {
    setShowAIPrompt(!showAIPrompt);
  };

  // Typewriter function to display text one character at a time
  const typeWriter = (text: string) => {
    if (indexRef.current <= text.length) {
      setTextAreaValue(text.substring(0, indexRef.current));
      indexRef.current += 1;
      // Adjust the speed by changing the timeout duration
      timeoutRef.current = setTimeout(() => typeWriter(text), 5);
    } else {
      // Typing finished
      clearTimeout(timeoutRef.current);
      indexRef.current = 1; // Reset to 1 for next time
      setTypingCompleted(true);
    }
  };

  // Cleanup the timeout when the component unmounts
  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <>
      <Button
        className="k-toolbar-button"
        ref={anchorRef}
        svgIcon={showAIPrompt ? xIcon : sparklesIcon}
        onClick={async () => {
          toggleAIPrompt();
          setGeneratingBeschreibung(true);
          const url = `/api/db/${pageState?.value.DBNAME}/cont/${pageState?.value.CONTID}/run/aisummary`;
          try {
            const response = await axios.post(url);
            const job: Job = response.data;
            const aiSummary: AISummary = JSON.parse(job.RESULTDATA);
            setGeneratingBeschreibung(false);
            setTextAreaValue(""); // Reset the text area
            indexRef.current = 1; // Start from 1 to include the first character
            setTypingCompleted(false);
            typeWriter(aiSummary.summary); // Start the typewriter effect
          } catch (error) {
            // Handle error appropriately
            console.error("Error generating AI summary:", error);
            setGeneratingBeschreibung(false);
          }
        }}
      />
      {showAIPrompt && (
        <ABKWindow
          initialWidth={500}
          onClose={() => setShowAIPrompt(false)}
          title="AI Prompt"
        >
          <div style={{ padding: "1rem" }}>
            {generatingBeschreibung ? (
              <ABKPageLoader />
            ) : (
              // Display the text area value which updates with the typewriter effect
              textAreaValue
            )}
          </div>
          {/* Show action buttons only after typing is completed */}
          {!generatingBeschreibung && typingCompleted && (
            <WindowActionsBar layout="stretched">
              <Button
                type="button"
                onClick={() => {
                  updatePageState({ BESCH: textAreaValue });
                  setShowAIPrompt(false);
                }}
              >
                Ersetzen
              </Button>
              <Button
                type="button"
                themeColor={"primary"}
                onClick={() => {
                  updatePageState({
                    BESCH: pageState?.value.BESCH + "\n\n" + textAreaValue,
                  });
                  setShowAIPrompt(false);
                }}
              >
                Unten anhängen
              </Button>
            </WindowActionsBar>
          )}
        </ABKWindow>
      )}
    </>
  );
};

export default AIGenerateBeschreibung;
