import { Outlet, useParams } from "react-router-dom";
import useDrawerStore from "../../core/stores/useDrawerStore";
import React, { useEffect } from "react";
import useAppBarState from "../../core/stores/useAppBarState";
import useGetVersion from "../Startseite/hooks/useGetVersion";
import { changeFavIcon } from "../../core/utility/favIcon";
import WrapperTemplate from "../../core/components/templates/WrapperTemplate";
import { navigationItems } from "../../Router/Layout/navigationItems";

const UserLayout: React.FC = () => {
  const db = useParams().db;
  const { setAppBarTitle } = useAppBarState();
  const { setCurrentDrawerItems } = useDrawerStore();

  const { data: sysinfo } = useGetVersion();

  useEffect(() => {
    const drawerItems = [
      navigationItems.profile(db),
      navigationItems["local-browser-configuration"](db),
    ];

    setCurrentDrawerItems(drawerItems);
    changeFavIcon("/assets/favicons/Menu.User.Tabicon.svg");
  }, [db, setCurrentDrawerItems]);

  useEffect(() => {
    if (sysinfo) {
      setAppBarTitle(`Benutzer  ${sysinfo.AuthUser}`);
    } else {
      setAppBarTitle("Benutzerprofil");
    }
  }, [setAppBarTitle, sysinfo]);

  return (
    <>
      <WrapperTemplate includeTitle includeDescription>
        <Outlet />
      </WrapperTemplate>
    </>
  );
};

export default UserLayout;
