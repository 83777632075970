import AbkColors from "../../../../../../constants/AbkColors";

const DragAndDropLine = () => (
  <tr
    style={{
      outlineStyle: "solid",
      outlineWidth: 1,
      outlineColor: AbkColors.green,
    }}
  />
);

export default DragAndDropLine;
