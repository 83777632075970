import {
  Parameterliste,
  ParameterlisteFrontend,
} from "../../../../interfaces/parameterlisten";
import { statusMapper } from "./maps";

export const backendParameterlisteToFrontend = (
  parameterliste: Parameterliste
) =>
  ({
    ...parameterliste,
    Kenndaten: {
      ...parameterliste.Kenndaten,
      STATUS: statusMapper.get(parameterliste.Kenndaten.STATUS),
    },
  }) as ParameterlisteFrontend;
