import {
  GridColumnPropsCustom,
  GridColumnsMigrateFunction,
  PersistedColumns,
} from "../../../../../../../abk-shared/components/organisms/ABKBasisGrid/interfaces/GridColumns";

export default function createColumnsDefinitionForParameterlisten() {
  const columns = [
    {
      field: "Kenndaten.BEZ",
      title: "Bezeichnung",
      orderIndex: 1,
      minWidth: 100,
    },
    {
      field: "Kenndaten.KENNUNG",
      title: "Kennung",
      orderIndex: 2,
      minWidth: 100,
    },
    {
      field: "Kenndaten.VERSIONSNR",
      title: "Version",
      orderIndex: 3,
      minWidth: 100,
    },
    {
      field: "Kenndaten.VERSIONSDATE",
      title: "Versionsdatum",
      orderIndex: 4,
      minWidth: 100,
    },
    {
      field: "Kenndaten.STATUS",
      title: "Status",
      orderIndex: 5,
      minWidth: 100,
    },
    {
      title: "Format",
      field: "SUBTYPE",
      orderIndex: 6,
      minWidth: 100,
    },
    {
      title: "Funktion",
      field: "Kenndaten.FUNKTGSKENN",
      orderIndex: 7,
      minWidth: 100,
      tooltip: "Gliederungssystem Funktion",
    },
    {
      title: "Produktgruppen",
      field: "Kenndaten.PRODGSKENN",
      orderIndex: 8,
      minWidth: 100,
      tooltip: "Gliederungssystem Produktgruppen",
    },
    {
      field: "DATAID",
      title: "Data-ID",
      show: false,
      orderIndex: 9,
      minWidth: 100,
    },
  ] as GridColumnPropsCustom[];

  const version = 4;

  const migrate: GridColumnsMigrateFunction = (persistedColumns, version) => {
    if (version === 1) {
      const newPersistedColumns = persistedColumns as PersistedColumns;
      newPersistedColumns.columns = [
        {
          field: "Kenndaten.BEZ",
          title: "Bezeichnung",
          orderIndex: 1,
          minWidth: 100,
        },
        {
          field: "Kenndaten.KENNUNG",
          title: "Kennung",
          orderIndex: 2,
          minWidth: 100,
        },
        {
          field: "Kenndaten.VERSIONSNR",
          title: "Version",
          orderIndex: 3,
          minWidth: 100,
        },
        {
          field: "Kenndaten.VERSIONSDATE",
          title: "Versionsdatum",
          orderIndex: 4,
          minWidth: 100,
        },
        {
          field: "Kenndaten.STATUS",
          title: "Status",
          orderIndex: 5,
          minWidth: 100,
        },
        {
          title: "Format",
          field: "SUBTYPE",
          orderIndex: 6,
          minWidth: 100,
        },
        {
          title: "GS Funktion",
          field: "Kenndaten.FUNKTGSKENN",
          orderIndex: 7,
          minWidth: 100,
        },
        {
          title: "GS Produktgruppe",
          field: "Kenndaten.PRODGSKENN",
          orderIndex: 8,
          minWidth: 100,
        },
        {
          field: "DATAID",
          title: "Data-ID",
          show: false,
          orderIndex: 9,
          minWidth: 100,
        },
      ] as GridColumnPropsCustom[];

      return newPersistedColumns;
    }

    if (version === 2) {
      const newPersistedColumns = persistedColumns as PersistedColumns;
      const dataId = newPersistedColumns.columns[8];
      dataId.title = "Data-ID";

      return newPersistedColumns;
    }

    if (version === 3) {
      const newPersistedColumns = persistedColumns as PersistedColumns;

      /*
        Vorher waren die Namen nicht aussagekräftig und beinhalteten Abkürzungen,
        die der User nicht verstehen konnte.
      */
      const funktion = newPersistedColumns.columns[6];
      funktion.title = "Funktion";
      funktion.tooltip = "Gliederungssystem Funktion";

      const produktgruppen = newPersistedColumns.columns[7];
      produktgruppen.title = "Produktgruppen";
      produktgruppen.tooltip = "Gliederungssystem Produktgruppen";

      return newPersistedColumns;
    }

    return persistedColumns as PersistedColumns;
  };

  return { columns, version, migrate };
}
