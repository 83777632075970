import { useEffect, useState } from "react";
import ABKWindow from "../../../../../../abk-shared/components/molecules/ABKWindow";
import { parTypePanelsMap } from "../EintragBearbeitenDialog/panels/ParTypePanelsMap";
import { Button } from "@progress/kendo-react-buttons";
import { useEintragBearbeitenState } from "../EintragBearbeitenDialog/useEintragBearbeitenState";
import useIsMobileTemplate from "../../../../../../abk-shared/utility/useIsMobileTemplate";
import "./ModalStyles.scss";
import RawSaveBalken from "../../../../../../abk-shared/components/molecules/ABKSaveBalken/RawSaveBalken";
import ABKConfirmationDialog from "../../../../../../abk-shared/components/molecules/ABKConfirmationDialog";
import useGetTemplateItem from "./useGetTemplateItem";
import { Loader } from "@progress/kendo-react-indicators";
import { backendEintragToFrontend } from "../convertBackendEintrag";
import ValidationErrorStoreProvider from "src/abk-shared/stores/ValidationErrorStoreProvider";
import { SaveType } from "../SaveTypeEnum";

type Props = {
  db: string;
  contId: string;
  plID: string;
  saveType: SaveType;
  beforeItemId?: string;
};

export const WrapperNewItemDialog = (props: Props) => (
  <ValidationErrorStoreProvider>
    <NewItemDialog {...props} />
  </ValidationErrorStoreProvider>
);

const NewItemDialog = ({ db, contId, plID, saveType, beforeItemId }: Props) => {
  const [confirmationDialog, setConfirmationDialog] = useState<boolean>(false);
  const { data, isLoading } = useGetTemplateItem(db, contId, plID);

  const {
    setShowNewItemDialog,
    setPageState,
    pageState,
    instance,
    newItemDialogPromise,
  } = useEintragBearbeitenState();

  useEffect(() => {
    if (!data) return;

    const templateItem = backendEintragToFrontend(data);
    setPageState(templateItem);
  }, [data, setPageState]);

  const isMobile = useIsMobileTemplate();

  const browserWindowHeight = isMobile
    ? window.innerHeight
    : window.innerHeight * 0.7;

  const panels: Function[] = pageState
    ? parTypePanelsMap.get(pageState.ITEMTYPE)
    : ([] as any);

  const onCancelConfirmationDialog = () => {
    setConfirmationDialog(false);
    setShowNewItemDialog(true);
  };

  const onConfirmClosingNewItemDialog = () => {
    if (newItemDialogPromise)
      newItemDialogPromise.resolve({ shouldProceed: false });

    setShowNewItemDialog(false);
    setConfirmationDialog(false);
  };

  return (
    <div className="modal-overlay">
      {confirmationDialog && (
        <ABKConfirmationDialog
          message="Sind sie sicher? Ihre Eingaben gehen dabei verloren."
          onClose={onCancelConfirmationDialog}
          onConfirm={onConfirmClosingNewItemDialog}
        />
      )}

      <ABKWindow
        title={`Eintrag hinzufügen`}
        initialHeight={browserWindowHeight}
        onClose={() => setConfirmationDialog(true)}
      >
        {isLoading && (
          <div className="loader-new-item-dialog">
            <Loader type="infinite-spinner" size="large" />
          </div>
        )}
        {!isLoading && (
          <div className="wrapper-panels-and-balken">
            <div className="panels">
              {panels.map((panel, index) => (
                <div key={index}>{panel && panel(pageState, setPageState)}</div>
              ))}
            </div>
            <div className="save-balken-container">
              <RawSaveBalken
                inline={true}
                balkenMessage="Ihr Eintrag wurde noch nicht gespeichert."
              >
                <Button
                  themeColor="primary"
                  onClick={async () => {
                    if (!newItemDialogPromise) return;

                    newItemDialogPromise.resolve({
                      shouldProceed: true,
                      data: {
                        dbName: instance.DBNAME,
                        contId: instance.CONTID,
                        plID: instance.DATAID,
                        eintrag: pageState ?? undefined,
                        insertType: saveType,
                        fromItemId: beforeItemId,
                      },
                    });
                  }}
                >
                  Erstellen
                </Button>
                <Button
                  themeColor="primary"
                  onClick={() => setConfirmationDialog(true)}
                >
                  Verwerfen
                </Button>
              </RawSaveBalken>
            </div>
          </div>
        )}
      </ABKWindow>
    </div>
  );
};
