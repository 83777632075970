import { NavigationItem } from "../../../interfaces/NavigationItem";
import ABKDrawerItem from "../../atoms/ABKDrawerItem";
import "./style.css";

const DrawerItems = ({
  currentDrawerItems,
  onDrawerItemSelect,
}: {
  currentDrawerItems: NavigationItem[];
  onDrawerItemSelect: (e: NavigationItem) => void;
}) => (
  <ul className="k-drawer-items">
    {currentDrawerItems.map((item, index) => (
      <ABKDrawerItem
        key={index}
        item={item}
        onDrawerItemSelect={onDrawerItemSelect}
      />
    ))}
  </ul>
);

export default DrawerItems;
