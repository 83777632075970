import imageCompression from "browser-image-compression";

export enum LogoFormat {
  JPEG = "JPEG",
  PNG = "PNG",
  GIF = "GIF",
  SVG = "SVG",
  EMPTY = "",
}

// From: https://stackoverflow.com/a/57272491
export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export function getLogoFormat(base64Format: string) {
  if (base64Format === "image/jpeg") return LogoFormat.JPEG;
  if (base64Format === "image/gif") return LogoFormat.GIF;
  if (base64Format === "image/png") return LogoFormat.PNG;
  if (base64Format === "image/svg+xml") return LogoFormat.SVG;
  return LogoFormat.EMPTY;
}

/**
 * Adds the prefix to the string according to the image format. String can be rendered as <img />
 * @param imageString
 * @param imageFormat
 * @returns
 */
export function generateBase64Image(
  imageString: string,
  imageFormat: LogoFormat
) {
  const hasImage = typeof imageString === "string" && imageString.length !== 0;
  const imagePrefix = generateBase64Prefix(imageFormat);
  const imageBase64 = `${imagePrefix}${imageString}`;
  return { hasImage, imageBase64 };
}

function generateBase64Prefix(format: LogoFormat) {
  let imageFormat = "image/png";

  switch (format) {
    case LogoFormat.JPEG:
      imageFormat = "image/jpg";
      break;
    case LogoFormat.PNG:
    case LogoFormat.EMPTY:
      imageFormat = "image/png";
      break;
    case LogoFormat.GIF:
      imageFormat = "image/gif";
      break;
    case LogoFormat.SVG:
      imageFormat = "image/svg+xml";
      break;
    default:
      console.warn(
        `Error: Format ${format} is not supported. Defaulting to PNG.`
      );
  }

  return `data:${imageFormat};base64,`;
}

export async function compressFileToLessThan64kB(inputFile: File) {
  const result = { compressedFile: null as File | null, isCompressed: false };
  if (!inputFile.size) return result;

  const inputFileSizeInKb = inputFile.size / 1024;
  if (inputFileSizeInKb < 64) return result;

  const options = {
    maxSizeMB: 0.063,
    useWebWorker: true,
    /*
      Mache die `maxIteration` größer, da die Default-Anzahl von Iterations
      manchmal nicht genug ist.
      From: https://github.com/Donaldcwl/browser-image-compression/issues/140
    */
    maxIteration: 30,
  };
  result.compressedFile = await imageCompression(inputFile, options);
  result.isCompressed = true;
  return result;
}
