import { OHNE_INHALT } from "../../../../../../../core/constants/text";
import { EintragCheckboxValue } from "../../../../../interfaces/parameterlisten";
import { IField } from "./IField";

interface EintragBearbeitenExpansionPanelVorschauProps {
  title: string;
  fields: IField[];
  shouldShowPreview: boolean;
}
const EintragBearbeitenExpansionPanelVorschau = ({
  title,
  fields,
  shouldShowPreview,
}: EintragBearbeitenExpansionPanelVorschauProps) => {
  const preview = generatePreview(fields);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="k-expander-title" style={{ minWidth: "fit-content" }}>
          {title}
        </div>
        {shouldShowPreview && <span className="Textvorschau">{preview}</span>}
      </div>
    </>
  );
};

export default EintragBearbeitenExpansionPanelVorschau;

function fieldValueIsEmpty(field: IField) {
  const fieldValue = field.value;

  if (field.isCheckbox) return fieldValue === EintragCheckboxValue.notSelected;

  return fieldValue === "" || fieldValue === undefined || fieldValue === null;
}

function generatePreview(fields: IField[]) {
  // case there are no fields
  if (fields.length === 0) return OHNE_INHALT;

  // case all fields are empty -> return ohneInhaltText
  let emptyFields = fields.filter((field) => fieldValueIsEmpty(field));
  if (emptyFields.length === fields.length) return OHNE_INHALT;

  //in case input is empty, then field should not be displayed (neither label nor value)
  let nonEmptyFields = fields.filter((field) => !fieldValueIsEmpty(field));

  let convertedFields = nonEmptyFields.map((field) => {
    if (field.isCheckbox) return field.label; // case it is checkbox -> only return the label
    return field.label + ": " + String(field.value);
  });

  let previewText = convertedFields.join("; ");
  return previewText;
}
