import "./style.css";

type Props = { children: React.ReactNode };

export default function ABKFormFieldInputWrapper({ children }: Props) {
  const classNameForEndToEndTests = "test-abk-form-field-input-wrapper";
  return (
    <div
      className={`abk-form-field-input-wrapper ${classNameForEndToEndTests}`}
    >
      {children}
    </div>
  );
}
