import { ExpansionPanelActionEvent } from "@progress/kendo-react-layout";
import ABKFormFieldInput from "../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import InputMitBeschriftung from "../InputMitBeschriftung";
import ABKControlledExpansionPanel from "../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import ABKFormRow from "../../../../../abk-shared/components/atoms/ABKFormRow";

interface AllgemeinesPanelProps {
  isExpanded: boolean;
  onAction: ((event: ExpansionPanelActionEvent) => void) | undefined;
  allgemeines: boolean;
  setDateiname: ((value: string) => void) | undefined;
  setAnmerkung: ((value: string) => void) | undefined;
  dateiname: string;
  anmerkung: string;
  contType: string;
}

const AllgemeinesPanel = ({
  isExpanded,
  onAction,
  setDateiname,
  setAnmerkung,
  dateiname,
  anmerkung,
  contType,
}: AllgemeinesPanelProps) => {
  return (
    <ABKControlledExpansionPanel
      expanded={isExpanded}
      title={<p style={{ margin: "0", color: "black" }}> Allgemein </p>}
      key="allgemeines"
      className="description-section"
      onAction={onAction}
    >
      {isExpanded && (
        <>
          <ABKFormRow>
            <ABKFormFieldInput
              editorId="dateiname"
              label="Dateiname"
              value={dateiname}
              setValue={setDateiname}
              backendKey="BEZ"
            />
            <InputMitBeschriftung value={contType} />
          </ABKFormRow>
          <ABKFormRow isHalfRow={true}>
            <ABKFormFieldInput
              editorId="Anmerkung"
              label="Anmerkung"
              value={anmerkung}
              setValue={setAnmerkung}
              backendKey="INTANMERK"
            />
          </ABKFormRow>
        </>
      )}
    </ABKControlledExpansionPanel>
  );
};

export default AllgemeinesPanel;
