import { create } from "zustand";
import { persist } from "zustand/middleware";

type GridDataState = {
  collapsedState: string[];
  setCollapsedState: (collapsedState: string[]) => void;
};

const createPersistedCollapsedStateStore = (gridSectorId: string) =>
  create<GridDataState>()(
    persist(
      (set) => ({
        collapsedState: [],
        setCollapsedState: (collapsedState: string[]) =>
          set(() => ({ collapsedState: collapsedState })),
      }),
      {
        name: `grid-${gridSectorId}-collapsedState`,
        version: 1,
      }
    )
  );

export default createPersistedCollapsedStateStore;
