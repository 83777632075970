import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_PARAMETERLISTE } from "src/modules/Datei/queries/useGetSingleParameterliste";
import { PiktogrammeBackendResponse } from "./piktogramm.types";
import { formatWithEtag } from "src/modules/Datei/queries/formatWithEtag";
import { Versioned } from "src/core/interfaces/Versioned";

export const QUERY_KEY_PIKTOGRAMME = "QUERY_KEY_PIKTOGRAMME";

export default function useGetPiktogramme(
  dbName: string,
  contId: string,
  plID: string
) {
  return useQuery({
    queryKey: [
      dbName,
      contId,
      plID,
      QUERY_KEY_PARAMETERLISTE,
      QUERY_KEY_PIKTOGRAMME,
    ],
    queryFn: async () => {
      const response = await axios.get(
        `/api/db/${dbName}/cont/${contId}/pl/${plID}/pikto`
      );

      return formatWithEtag(response) as Versioned<PiktogrammeBackendResponse>;
    },
  });
}
