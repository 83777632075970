import ABKAddressSelect from "../../../../../../../abk-shared/components/atoms/ABKAddressSelect";
import ABKForm from "../../../../../../../abk-shared/components/molecules/ABKForm";
import ABKFormFieldInput from "../../../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import ABKKommunikationSelect from "./ABKKommunikationSelect";
import {
  Firma,
  Kontaktperson,
  Parameterliste,
} from "../../../../../interfaces/parameterlisten";
import ABKControlledExpansionPanel from "../../../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import ABKFormRow from "../../../../../../../abk-shared/components/atoms/ABKFormRow";

interface HerausgeberFormProps {
  pageState: Parameterliste;
  setPageState: (parameterliste: Parameterliste) => void;
  isOpen: boolean;
  updateSections: () => void;
  readOnly: boolean;
}

const HerausgeberForm = ({
  pageState,
  setPageState,
  isOpen,
  updateSections,
  readOnly,
}: HerausgeberFormProps) => {
  const kenndaten = pageState?.Kenndaten || {};
  const firma = kenndaten.Herausgeber?.firma || {};
  const kontaktperson = kenndaten.Herausgeber?.person || {};

  const updateFirma = (updatedFirma: Partial<Firma>) => {
    setPageState({
      ...pageState,
      Kenndaten: {
        ...kenndaten,
        Herausgeber: {
          ...kenndaten.Herausgeber,
          firma: {
            ...firma,
            ...updatedFirma,
          },
        },
      },
    });
  };

  const updateKontaktperson = (
    updatedKontaktperson: Partial<Kontaktperson>
  ) => {
    setPageState({
      ...pageState,
      Kenndaten: {
        ...kenndaten,
        Herausgeber: {
          ...kenndaten.Herausgeber,
          person: {
            ...kontaktperson,
            ...updatedKontaktperson,
          },
        },
      },
    });
  };

  const backendKeyPrefix = {
    firma: "Kenndaten.Herausgeber.firma",
    person: "Kenndaten.Herausgeber.person",
  };

  return (
    <ABKControlledExpansionPanel
      title="Herausgeber"
      expanded={isOpen}
      onAction={updateSections}
    >
      <ABKForm
        title="Firma"
        sections={[
          {
            formElements: [
              <ABKFormRow>
                <ABKFormFieldInput
                  editorId="name"
                  label="Name"
                  value={firma.name || ""}
                  backendKey={`${backendKeyPrefix.firma}.name`}
                  setValue={
                    readOnly
                      ? undefined
                      : (value) => updateFirma({ name: value })
                  }
                />
              </ABKFormRow>,
              <ABKAddressSelect
                value={firma.kommunikation?.adresse || {}}
                setValue={(value) =>
                  updateFirma({
                    kommunikation: {
                      ...firma.kommunikation,
                      adresse: {
                        ...firma.kommunikation?.adresse,
                        ...value,
                      },
                    },
                  })
                }
                readOnly={readOnly}
                backendKeyPrefix={backendKeyPrefix.firma}
              />,
              <ABKKommunikationSelect
                value={firma.kommunikation || {}}
                setValue={(value) => updateFirma({ kommunikation: value })}
                readOnly={readOnly}
                backendKeyPrefix={backendKeyPrefix.firma}
              />,
            ],
          },
        ]}
      />
      <ABKForm
        title="Kontaktperson"
        sections={[
          {
            formElements: [
              <ABKFormRow>
                <ABKFormFieldInput
                  editorId="vorname"
                  label="Vorname"
                  value={kontaktperson.vorname || ""}
                  setValue={
                    readOnly
                      ? undefined
                      : (value) => updateKontaktperson({ vorname: value })
                  }
                  backendKey={`${backendKeyPrefix.person}.vorname`}
                />
                <ABKFormFieldInput
                  editorId="nachname"
                  label="Nachname"
                  value={kontaktperson.nachname || ""}
                  setValue={
                    readOnly
                      ? undefined
                      : (value) => updateKontaktperson({ nachname: value })
                  }
                  backendKey={`${backendKeyPrefix.person}.nachname`}
                />
              </ABKFormRow>,
              <ABKAddressSelect
                value={kontaktperson.kommunikation?.adresse || {}}
                setValue={(value) =>
                  updateKontaktperson({
                    kommunikation: {
                      ...kontaktperson.kommunikation,
                      adresse: {
                        ...kontaktperson.kommunikation?.adresse,
                        ...value,
                      },
                    },
                  })
                }
                readOnly={readOnly}
                backendKeyPrefix={backendKeyPrefix.person}
              />,
              <ABKKommunikationSelect
                value={kontaktperson.kommunikation || {}}
                setValue={(value) =>
                  updateKontaktperson({ kommunikation: value })
                }
                readOnly={readOnly}
                backendKeyPrefix={backendKeyPrefix.person}
              />,
            ],
          },
        ]}
      />
    </ABKControlledExpansionPanel>
  );
};

export default HerausgeberForm;
