import { useEffect, useState } from "react";
import { IField } from "./IField";
import EintragBearbeitenExpansionPanelVorschau from "./EintragBearbeitenExpansionPanelVorschau";
import ABKForm from "../../../../../../../abk-shared/components/molecules/ABKForm";
import ABKFormRow from "../../../../../../../abk-shared/components/atoms/ABKFormRow";
import ABKFormCheckbox from "../../../../../../../abk-shared/components/atoms/ABKFormCheckbox";
import ABKFormFieldInput from "../../../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import { EintragFrontend } from "../../../../../interfaces/parameterlisten";
import ABKNumericInput from "../../../../../../../abk-shared/components/atoms/ABKNumericInput";
import ABKControlledExpansionPanel from "../../../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import { booleanFieldMapper, booleanFieldReverseMapper } from "../../maps";
import { createInternalValueForNumber } from "../../convertBackendEintrag";
import { PropsEintragPanel } from "./ParTypePanelsMap";

const ZahlPanel = ({ setPageState, pageState }: PropsEintragPanel) => {
  const [expanded, setExpanded] = useState(false);

  const [fields, setFields] = useState<IField[]>([]);

  const fieldEinheit: IField = {
    label: "Einheit",
    value: pageState?.EH || "",
    isCheckbox: false,
  };
  const numberOfDecimals = pageState?.NUMNK?.internal;
  const fieldNachkommastellen: IField = {
    label: "Nachkommastellen",
    value: numberOfDecimals || "",
    isCheckbox: false,
  };
  const fieldMinimalwert: IField = {
    label: "Minimalwert",
    value: pageState?.NUMMIN.displayed,
    isCheckbox: false,
  };
  const fieldMaximalwert: IField = {
    label: "Maximalwert",
    value: pageState?.NUMMAX.displayed,
    isCheckbox: false,
  };
  const fieldSummeBilden: IField = {
    label: "Summe bilden",
    value: pageState?.SUMMEBILDEN || false,
    isCheckbox: true,
  };
  const fieldMengenabhängigerKennwert: IField = {
    label: "Mengenabhängiger Kennwert",
    value: pageState?.MENGENABH || false,
    isCheckbox: true,
  };

  useEffect(() => {
    setFields([
      fieldEinheit,
      fieldNachkommastellen,
      fieldMinimalwert,
      fieldMaximalwert,
      fieldSummeBilden,
      fieldMengenabhängigerKennwert,
    ]);
  }, [pageState]);

  return (
    <ABKControlledExpansionPanel
      testProps={{ ariaLabel: "expansion-panel-zahl" }}
      title={
        <EintragBearbeitenExpansionPanelVorschau
          title="Zahl"
          fields={fields}
          data-testid="test-expansion-panel-title-zahl"
          shouldShowPreview={!expanded}
        />
      }
      expanded={expanded}
      onAction={() => setExpanded(!expanded)}
    >
      <ABKForm
        sections={[
          {
            formElements: [
              <ABKFormRow>
                <ABKFormFieldInput
                  data-testid="test-label-zahl-einheit"
                  editorId={"EH"}
                  label={fieldEinheit.label}
                  value={fieldEinheit.value as string}
                  setValue={(value: string) => {
                    setPageState({
                      ...pageState,
                      EH: value,
                    } as EintragFrontend);
                  }}
                />
                <ABKNumericInput
                  editorId={"NUMNK"}
                  label={fieldNachkommastellen.label}
                  value={numberOfDecimals}
                  setValue={(value: number | null) => {
                    setPageState({
                      ...pageState,
                      NUMNK: createInternalValueForNumber(value),
                    } as EintragFrontend);
                  }}
                  readOnly={false}
                  onlyDecimal={true}
                  backendKey="NUMNK"
                  tooltip="Ganze Zahl von 0 bis 5 erlaubt"
                />
              </ABKFormRow>,

              <ABKFormRow>
                <ABKNumericInput
                  editorId={"NUMMIN"}
                  label={fieldMinimalwert.label}
                  value={pageState?.NUMMIN?.internal}
                  setValue={(value: number | null) => {
                    setPageState({
                      ...pageState,
                      NUMMIN: createInternalValueForNumber(value),
                    } as EintragFrontend);
                  }}
                  customFormat={`n${numberOfDecimals || 0}`}
                  readOnly={false}
                  backendKey="NUMMIN"
                />
                <ABKNumericInput
                  editorId={"NUMMax"}
                  label={fieldMaximalwert.label}
                  value={pageState?.NUMMAX?.internal}
                  setValue={(value: number | null) => {
                    setPageState({
                      ...pageState,
                      NUMMAX: createInternalValueForNumber(value),
                    } as EintragFrontend);
                  }}
                  customFormat={`n${numberOfDecimals || 0}`}
                  readOnly={false}
                  backendKey="NUMMAX"
                />
              </ABKFormRow>,

              <ABKFormRow>
                <ABKFormCheckbox
                  editorId={"SUMMEBILDEN"}
                  label={fieldSummeBilden.label}
                  value={booleanFieldReverseMapper.get(pageState?.SUMMEBILDEN)}
                  setValue={(value: boolean) => {
                    setPageState({
                      ...pageState,
                      SUMMEBILDEN: booleanFieldMapper.get(value),
                    } as EintragFrontend);
                  }}
                  tooltip={
                    "Bei der Auswahl von 'Summe bilden' wird der Kennwert für jede Leistungsgruppe bzw. jedes Leistungsverzeichnis summiert und als Gesamtwert angezeigt."
                  }
                />
                <ABKFormCheckbox
                  editorId={"MENGENABH"}
                  label={fieldMengenabhängigerKennwert.label}
                  value={booleanFieldReverseMapper.get(pageState?.MENGENABH)}
                  setValue={(value: boolean) => {
                    setPageState({
                      ...pageState,
                      MENGENABH: booleanFieldMapper.get(value),
                    } as EintragFrontend);
                  }}
                />
              </ABKFormRow>,
            ],
          },
        ]}
      />
    </ABKControlledExpansionPanel>
  );
};

export default ZahlPanel;
