import { KEY_REMOVE_FROM_FILTERING } from "../../../constants";
import { DragHandleCell } from "./DragHandleCell";

export const FIELD_SELECTED = "selected";
export const FIELD_DRAG_AND_DROP = "drag-and-drop";

export const WIDTH_ACTION_COLUMN = {
  [FIELD_SELECTED]: 34,
  [FIELD_DRAG_AND_DROP]: 17,
};

export const actionColumnDefaultProps = {
  locked: true,
  /*
    Deaktiviere die Anordnung für diese Spalte, weil sie "locked" ist.
    From: https://www.telerik.com/kendo-react-ui/components/knowledge-base/grid-disable-reordering/#toc-solution
  */
  orderIndex: 0,
  minWidth: WIDTH_ACTION_COLUMN[FIELD_SELECTED],
  manualWidth: WIDTH_ACTION_COLUMN[FIELD_SELECTED],
  headerSelectionValue: false,
  [KEY_REMOVE_FROM_FILTERING]: true,
};

export const columnSelectedField = {
  ...actionColumnDefaultProps,
  field: FIELD_SELECTED,
};

export const columnDragAndDrop = {
  ...actionColumnDefaultProps,
  minWidth: WIDTH_ACTION_COLUMN[FIELD_DRAG_AND_DROP],
  manualWidth: WIDTH_ACTION_COLUMN[FIELD_DRAG_AND_DROP],
  field: FIELD_DRAG_AND_DROP,
  cell: DragHandleCell,
  // Leerzeichen um nichts im Spaltenheader anzuzeigen. "" wird das `field` anzeigen.
  title: " ",
};

const fieldsActionColumns = [FIELD_SELECTED, FIELD_DRAG_AND_DROP];

export const isFieldOfActionColumn = (field: string | undefined) =>
  field && fieldsActionColumns.includes(field);
