import { DatePicker } from "@progress/kendo-react-dateinputs";
import React from "react";
import { load, LocalizationProvider } from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import numbers from "cldr-numbers-full/main/de/numbers.json";
import caGregorian from "cldr-dates-full/main/de/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/de/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/de/timeZoneNames.json";
import ABKFormFieldInputWrapper from "../ABKFormFieldInputWrapper";
import ABKLabel from "../ABKLabel";
import {
  addClassNameInputReadOnly,
  addClassNameInputError,
} from "../../../utility/addClassNameFormInput";
import ABKFormErrorMessage from "../ABKFormErrorMessage";
import useGetValidationErrorMessage from "../../../stores/ValidationErrorStoreProvider/useGetValidationErrorMessage";

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  caGregorian,
  dateFields,
  timeZoneNames
);

// Inspired from: https://www.telerik.com/kendo-react-ui/components/grid/globalization/
const language = "de-DE";

interface AbkFormFieldInputProps {
  editorId: string;
  label: string;
  value: Date | null;
  setValue: (value: Date | null) => void;
  readOnly: boolean;
  errorMessage?: React.ReactNode;
  backendKey?: string;
}

const ABKDatePickerInput: React.FC<AbkFormFieldInputProps> = (props) => {
  const errorMessage = useGetValidationErrorMessage(props.backendKey);
  let classNameInput = "";
  classNameInput = addClassNameInputReadOnly(classNameInput, props.readOnly);
  classNameInput = addClassNameInputError(classNameInput, props.errorMessage);

  return (
    <LocalizationProvider language={language}>
      <ABKFormFieldInputWrapper>
        <ABKLabel editorId={props.editorId} className="k-d-flex">
          {props.label}
        </ABKLabel>
        <DatePicker
          className={classNameInput}
          format="dd.MM.yyyy"
          id={props.editorId}
          lang={language}
          name={props.editorId}
          value={props.value}
          onChange={(e) => props.setValue(e.target.value)}
          formatPlaceholder={{ day: "Tag", month: "Monat", year: "Jahr" }}
          placeholder={props.readOnly ? "" : undefined}
          toggleButton={props.readOnly ? () => null : undefined}
          /*
            Wenn es `readOnly` ist, wir wollen vermeiden, dass der User auf
            den DatePicker klickt, und der Placeholder zeigt.
            Wenn der "dateInput" `() => null` ist, wird kein Placeholder gezeigt,
            wenn der User auf einen `readOnly` DatePicker klickt.
          */
          dateInput={props.readOnly && !props.value ? () => null : undefined}
          adaptive={true}
        />
        {errorMessage && (
          <ABKFormErrorMessage>{errorMessage}</ABKFormErrorMessage>
        )}
      </ABKFormFieldInputWrapper>
    </LocalizationProvider>
  );
};

export default ABKDatePickerInput;
