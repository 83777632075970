import ABKFormCheckbox from "../../../../../../../abk-shared/components/atoms/ABKFormCheckbox";
import ABKFormFieldInput from "../../../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import ABKFormRow from "../../../../../../../abk-shared/components/atoms/ABKFormRow";
import ABKForm from "../../../../../../../abk-shared/components/molecules/ABKForm";
import { EintragFrontend } from "../../../../../interfaces/parameterlisten";
import { useEffect, useState } from "react";
import { IField } from "./IField";
import EintragBearbeitenExpansionPanelVorschau from "./EintragBearbeitenExpansionPanelVorschau";
import ABKControlledExpansionPanel from "../../../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import { booleanFieldMapper, booleanFieldReverseMapper } from "../../maps";
import { PropsEintragPanel } from "./ParTypePanelsMap";

const labels = {
  VARNAME: "Parameterkennung",
  REQUIRED: "Eingabe erforderlich",
  FAVORIT: "Favorit",
};

const ParameterPanel = ({ setPageState, pageState }: PropsEintragPanel) => {
  const [expanded, setExpanded] = useState(false);

  const [fields, setFields] = useState<IField[]>([]);

  useEffect(() => {
    setFields([
      {
        label: labels.VARNAME,
        value: pageState?.VARNAME || "",
        isCheckbox: false,
      },
      {
        label: labels.REQUIRED,
        value: pageState?.REQUIRED || false,
        isCheckbox: true,
      },
      {
        label: labels.FAVORIT,
        value: pageState?.FAVORIT || false,
        isCheckbox: true,
      },
    ]);
  }, [pageState]);

  return (
    <ABKControlledExpansionPanel
      title={
        <EintragBearbeitenExpansionPanelVorschau
          title="Parameter"
          fields={fields}
          data-testid="test-expansion-panel-title"
          shouldShowPreview={!expanded}
        />
      }
      expanded={expanded}
      onAction={() => setExpanded(!expanded)}
    >
      <ABKForm
        sections={[
          {
            formElements: [
              <ABKFormRow>
                <ABKFormFieldInput
                  data-testid="test-label-parameterkennung"
                  editorId={"VARNAME"}
                  label={labels.VARNAME}
                  value={pageState?.VARNAME}
                  setValue={(value: string) => {
                    setPageState({
                      ...pageState,
                      VARNAME: value,
                    } as EintragFrontend);
                  }}
                  backendKey="VARNAME"
                />
                <ABKFormCheckbox
                  editorId={"REQUIRED"}
                  label={labels.REQUIRED}
                  value={booleanFieldReverseMapper.get(pageState?.REQUIRED)}
                  setValue={(value: boolean) => {
                    setPageState({
                      ...pageState,
                      REQUIRED: booleanFieldMapper.get(value),
                    } as EintragFrontend);
                  }}
                />
              </ABKFormRow>,

              <ABKFormRow>
                <ABKFormCheckbox
                  editorId={"FAVORIT"}
                  label={labels.FAVORIT}
                  value={booleanFieldReverseMapper.get(pageState?.FAVORIT)}
                  setValue={(value: boolean) => {
                    setPageState({
                      ...pageState,
                      FAVORIT: booleanFieldMapper.get(value),
                    } as EintragFrontend);
                  }}
                />
              </ABKFormRow>,
            ],
          },
        ]}
      />
    </ABKControlledExpansionPanel>
  );
};

export default ParameterPanel;
