import "@progress/kendo-react-layout";
import * as urls from "../../../core/constants/urls";
import * as Icons from "../../../abk-shared/interfaces/abk9SvgIcons";
import { TitlesHelp } from "../../../core/constants/titles";
import createLinkURL from "../../../core/components/molecules/ABKBreadcrumbs/ABKBreadcrumbLink/createLinkURL";
import createNavigationItem from "./createNavigationItem";
import { MAIN_PAGE_TEMPLATE } from "../../../core/stores/useMainPageTemplateState";

export type NavigationItemKey = keyof typeof navigationItems;

export const navigationItems = {
  // Startseite

  open: (dbName: string | undefined) =>
    createNavigationItem(
      "open",
      "Öffnen",
      MAIN_PAGE_TEMPLATE.TITLE,
      Icons.Menu_Start_FileOpen,
      createLinkURL(urls.OPEN, dbName)
    ),

  new: (dbName: string | undefined) =>
    createNavigationItem(
      "new",
      "Erstellen",
      "Datei erstellen",
      Icons.Menu_Start_FileNew,
      createLinkURL(urls.TEMPLATES, dbName)
    ),

  files: (dbName: string | undefined) =>
    createNavigationItem(
      "files",
      "Verwalten",
      "Dateien verwalten",
      Icons.Menu_Start_FileManage,
      createLinkURL(urls.FILES, dbName)
    ),

  import: (dbName: string | undefined) =>
    createNavigationItem(
      "import",
      "Importieren",
      "Dateien importieren",
      Icons.General_Import,
      createLinkURL(urls.IMPORT, dbName)
    ),

  // Datei

  file: (dbName: string | undefined, contId: string | undefined) =>
    createNavigationItem(
      ":contId",
      "Datei",
      "öffnen",
      Icons.View_Show,
      createLinkURL(urls.FILE_INFO, dbName, contId)
    ),

  [urls.FILE_INFO_SUFFIX]: (
    dbName: string | undefined,
    contId: string | undefined
  ) =>
    createNavigationItem(
      urls.FILE_INFO_SUFFIX,
      "Datei-Info",
      "Datei-Info",
      Icons.Menu_File_FileInfo,
      createLinkURL(urls.FILE_INFO, dbName, contId)
    ),

  [urls.FILE_PROTOCOL_SUFFIX]: (
    dbName: string | undefined,
    contId: string | undefined
  ) =>
    createNavigationItem(
      urls.FILE_PROTOCOL_SUFFIX,
      "Datei-Protokoll",
      "Datei-Protokoll",
      Icons.Menu_File_FileLog,
      createLinkURL(urls.FILE_PROTOCOL, dbName, contId)
    ),

  [urls.FILE_WEITEREPARAMETERLISTEN_SUFFIX]: (
    dbName: string | undefined,
    contId: string | undefined
  ) =>
    createNavigationItem(
      urls.FILE_WEITEREPARAMETERLISTEN_SUFFIX,
      "Parameterlisten",
      "Parameterlisten",
      Icons.Dummy_Missing,
      createLinkURL(urls.FILE_WEITEREPARAMETERLISTEN, dbName, contId)
    ),

  [urls.FILE_PL_KENNDATEN_SUFFIX]: (
    dbName: string | undefined,
    contId: string | undefined
  ) =>
    createNavigationItem(
      urls.FILE_PL_KENNDATEN_SUFFIX,
      "Kenndaten",
      "Kenndaten",
      Icons.Dummy_Missing,
      createLinkURL(urls.FILE_PL_KENNDATEN, dbName, contId),
      true
    ),

  [urls.FILE_EINTRAEGE_SUFFIX]: (
    dbName: string | undefined,
    contId: string | undefined
  ) =>
    createNavigationItem(
      urls.FILE_EINTRAEGE_SUFFIX,
      "Einträge",
      "Einträge",
      Icons.Dummy_Missing,
      createLinkURL(urls.FILE_EINTRAEGE, dbName, contId),
      true
    ),

  [urls.FILE_PIKTOGRAMME_SUFFIX]: (
    dbName: string | undefined,
    contId: string | undefined
  ) =>
    createNavigationItem(
      urls.FILE_PIKTOGRAMME_SUFFIX,
      "Piktogramme",
      "Piktogramme",
      Icons.Dummy_Missing,
      createLinkURL(urls.FILE_PIKTOGRAMME, dbName, contId),
      true
    ),

  [urls.FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX]: (
    dbName: string | undefined,
    contId: string | undefined
  ) =>
    createNavigationItem(
      urls.FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX,
      "Verknüpfte Gliederungssysteme",
      "Verknüpfte Gliederungssysteme",
      Icons.Dummy_Missing,
      createLinkURL(urls.FILE_WEITEREGLIEDERUNGSSYSTEME, dbName, contId)
    ),

  [urls.FILE_AUFBAU_SUFFIX]: (
    dbName: string | undefined,
    contId: string | undefined
  ) =>
    createNavigationItem(
      urls.FILE_AUFBAU_SUFFIX,
      "Aufbau",
      "Aufbau",
      Icons.Dummy_Missing,
      createLinkURL(urls.FILE_AUFBAU, dbName, contId)
    ),

  [urls.FILE_GRAFIKEN_SUFFIX]: (
    dbName: string | undefined,
    contId: string | undefined
  ) =>
    createNavigationItem(
      urls.FILE_GRAFIKEN_SUFFIX,
      "Grafiken",
      "Grafiken",
      Icons.Dummy_Missing,
      createLinkURL(urls.FILE_GRAFIKEN, dbName, contId)
    ),

  [urls.ADMIN_SUFFIX]: (dbName: string | undefined) =>
    createNavigationItem(
      urls.ADMIN_SUFFIX,
      "Admin-Center",
      "Admin-Center",
      Icons.Menu_Admin,
      createLinkURL(urls.ADMIN, dbName)
    ),

  [urls.ADMIN_ORG_SUFFIX]: (dbName: string | undefined) =>
    createNavigationItem(
      urls.ADMIN_ORG_SUFFIX,
      "Organisation",
      "Organisation",
      Icons.Menu_Admin_Company,
      createLinkURL(urls.ADMIN_ORG, dbName)
    ),

  [urls.ADMIN_DEPARTMENTS_SUFFIX]: (dbName: string | undefined) =>
    createNavigationItem(
      urls.ADMIN_DEPARTMENTS_SUFFIX,
      "Abteilungen",
      "Abteilungen",
      Icons.Menu_Admin_Departments,
      createLinkURL(urls.ADMIN_DEPARTMENTS, dbName)
    ),

  [urls.ADMIN_USERS_SUFFIX]: (dbName: string | undefined) =>
    createNavigationItem(
      urls.ADMIN_USERS_SUFFIX,
      "Benutzer",
      "Benutzer",
      Icons.Menu_Admin_Users,
      createLinkURL(urls.ADMIN_USERS, dbName)
    ),

  [urls.ADMIN_LICENSES_SUFFIX]: (dbName: string | undefined) =>
    createNavigationItem(
      urls.ADMIN_LICENSES_SUFFIX,
      "Lizenzen",
      "Lizenzen",
      Icons.Menu_Admin_License,
      createLinkURL(urls.ADMIN_LICENSES, dbName)
    ),

  [urls.ADMIN_DB_PROTOCOL_SUFFIX]: (dbName: string | undefined) =>
    createNavigationItem(
      urls.ADMIN_DB_PROTOCOL_SUFFIX,
      "Datenbank-Protokoll",
      "Datenbank-Protokoll",
      Icons.Menu_Admin_DatabaseLog,
      createLinkURL(urls.ADMIN_DB_PROTOCOL, dbName)
    ),

  [urls.ADMIN_DATABASES_SUFFIX]: (dbName: string | undefined) =>
    createNavigationItem(
      urls.ADMIN_DATABASES_SUFFIX,
      "Datenbanken",
      "Datenbanken",
      Icons.Menu_Admin_DatabaseInfo,
      createLinkURL(urls.ADMIN_DATABASES, dbName)
    ),

  [urls.ADMIN_DB_STATUS_SUFFIX]: (dbName: string | undefined) =>
    createNavigationItem(
      urls.ADMIN_DB_STATUS_SUFFIX,
      "Datenbank-Status",
      "Datenbank-Status",
      Icons.Menu_Admin_DatabaseState,
      createLinkURL(urls.ADMIN_DB_STATUS, dbName)
    ),

  [urls.ADMIN_SERVER_LOG_SUFFIX]: (dbName: string | undefined) =>
    createNavigationItem(
      urls.ADMIN_SERVER_LOG_SUFFIX,
      "Server-Log",
      "Server-Log",
      Icons.Menu_Admin_ServerLog,
      createLinkURL(urls.ADMIN_SERVER_LOG, dbName)
    ),

  [urls.ADMIN_SERVER_INFO_SUFFIX]: (dbName: string | undefined) =>
    createNavigationItem(
      urls.ADMIN_SERVER_INFO_SUFFIX,
      "Server-Info",
      "Server-Info",
      Icons.Menu_Admin_ServerInfo,
      createLinkURL(urls.ADMIN_SERVER_INFO, dbName)
    ),

  [urls.ADMIN_SERVER_STATUS_SUFFIX]: (dbName: string | undefined) =>
    createNavigationItem(
      urls.ADMIN_SERVER_STATUS_SUFFIX,
      "Server-Status",
      "Server-Status",
      Icons.Menu_Admin_ServerState,
      createLinkURL(urls.ADMIN_SERVER_STATUS, dbName)
    ),

  // User

  [urls.USER_SUFFIX]: (dbName: string | undefined) =>
    createNavigationItem(
      urls.USER_SUFFIX,
      "Benutzer",
      "Benutzer",
      Icons.Menu_User,
      createLinkURL(urls.USER, dbName)
    ),

  [urls.USER_PROFILE_SUFFIX]: (dbName: string | undefined) =>
    createNavigationItem(
      urls.USER_PROFILE_SUFFIX,
      "Profil",
      "Profil",
      Icons.Menu_User,
      createLinkURL(urls.USER_PROFILE, dbName)
    ),

  [urls.USER_LOCAL_BROWSER_CONFIG_SUFFIX]: (dbName: string | undefined) =>
    createNavigationItem(
      urls.USER_LOCAL_BROWSER_CONFIG_SUFFIX,
      "Lokale Browsereinstellung",
      "Lokale Browsereinstellung",
      Icons.Menu_Admin_DatabaseInfo,
      createLinkURL(urls.USER_LOCAL_BROWSER_CONFIG, dbName)
    ),

  // Help

  [urls.HELP_SUFFIX]: () =>
    createNavigationItem(
      urls.HELP_SUFFIX,
      "Hilfe-Center",
      "Hilfe-Center",
      Icons.Menu_Helpcenter,
      urls.HELP_START
    ),

  [urls.HELP_START_SUFFIX]: () =>
    createNavigationItem(
      urls.HELP_START_SUFFIX,
      TitlesHelp.AppInfo,
      TitlesHelp.AppInfo,
      Icons.Menu_Help_AppInfo,
      urls.HELP_ABOUT
    ),

  [urls.HELP_SYSINFO_SUFFIX]: () =>
    createNavigationItem(
      urls.HELP_SYSINFO_SUFFIX,
      TitlesHelp.SystemInfo,
      TitlesHelp.SystemInfo,
      Icons.Menu_Help_SysInfo,
      urls.HELP_SYSINFO
    ),

  [urls.HELP_ADMIN_HELP_SUFFIX]: () =>
    createNavigationItem(
      urls.HELP_ADMIN_HELP_SUFFIX,
      TitlesHelp.AdminHelp,
      TitlesHelp.AdminHelp,
      Icons.Menu_Admin_ServerLog,
      urls.HELP_ADMIN_HELP
    ),
};
