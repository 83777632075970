import React from "react";
import { GridRef } from "../useGridReorderRows/gridReorderRowsContexts";
import { getScrollTopLocalStorage } from "./scrollTopLocalStorage";

export default function useSetInitialScrollTop(
  gridRef: GridRef,
  isMobileGrid: boolean,
  gridUniqueId: string
) {
  // Inspiriert von: https://feedback.telerik.com/kendo-react-ui/1657695-data-grid-with-virtual-scrolling-scrolls-jumps-to-top-when-the-total-number-of-records-changes
  React.useEffect(() => {
    if (isMobileGrid) return;

    const element = getGridVirtualScrollingContainer(gridRef);

    const scrollTop = getScrollTopLocalStorage(gridUniqueId, isMobileGrid);

    if (element) element.scrollTop = scrollTop;
  }, [isMobileGrid]);
}

function getGridVirtualScrollingContainer(gridRef: GridRef) {
  return gridRef?.current?.element?.querySelector(
    ".k-grid-content.k-virtual-content"
  );
}
