import { Link, useNavigate } from "react-router-dom";
import { ContWithLastChangedModified } from "../../../../core/interfaces/Cont";
import { useQuickInfoState } from "../../../../abk-shared/stores/useQuickInfoState";
import {
  getFileInfoUrlForContainer,
  useContainersWithFormattedDate,
} from "../../../../core/utility/cont";
import useDuplicateCont from "../../hooks/useDuplicateCont";
import useGetCont, {
  QUERY_KEY_GET_CONTAINER,
  RequestType,
} from "../../hooks/useGetCont";
import useSaveContAsTemplate from "../../hooks/useSaveContAsTemplate";
import React from "react";
import useDeleteCont from "../../hooks/useDeleteCont";
import { DialogResponse } from "./DeleteDialog";
import { ContainerToExport, ExportDialogResponse } from "./ExportDialog";
import usePostExport from "./ExportDialog/usePostExport";
import { PropsCustomCell } from "../../../../abk-shared/components/organisms/ABKBasisGrid/components/CustomCell";
import { SvgIcon } from "@progress/kendo-react-common";
import { AbkIconsMap } from "../../../../abk-shared/interfaces/abk9SvgIcons";
import {
  GridColumnPropsCustom,
  GridColumnsMigrateFunction,
  PersistedColumns,
} from "../../../../abk-shared/components/organisms/ABKBasisGrid/interfaces/GridColumns";
import useQuickInfoOnGridSelection from "../../../../core/components/templates/MainPageTemplate/generateSidebarElements/useQuickInfoOnGridSelection";
import { DATA_KEY } from "../../../../abk-shared/components/organisms/ABKBasisGrid/constants";
import ABKBasisGridCellLink from "../../../../abk-shared/components/organisms/ABKBasisGrid/components/ABKBasisGridCellLink";
import { useQueryClient } from "@tanstack/react-query";
import useIsGridLoading from "../../../../core/utility/useIsGridLoading";
import { GenericObject } from "../../../../abk-shared/interfaces/GenericObject";
import { GridRowSelection } from "../../../../abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelectionInterfaces";

const COLUMN_FIELDS = {
  DATEINAME: "BEZ",
  TYP: "ContTypeInfo.TXT",
  BESITZER: "OWNER",
  ÄNDERUNGSDATUM: "LASTCHANGED",
  ANMERKUNG: "INTANMERK",
  CONTID: "CONTID",
};

const useVerwalten = (
  database: string,
  requestType: RequestType,
  setExportDialogPromise: React.Dispatch<
    React.SetStateAction<{
      resolve: (value: ExportDialogResponse) => void;
    } | null>
  >,
  setDeleteDialogPromise: React.Dispatch<
    React.SetStateAction<{
      resolve: (value: DialogResponse) => void;
    } | null>
  >
) => {
  const { data, isLoading, isFetching } = useGetCont(database, requestType);
  const containersWithFormattedDate = useContainersWithFormattedDate(data);

  // Inspired from: https://www.telerik.com/kendo-react-ui/components/grid/columns/width/#toc-setting-a-minimum-column-width
  const columnsDefinition = React.useMemo(() => {
    const columns = [
      {
        field: "Datei",
        title: "Datei",
        orderIndex: 1,
        children: [
          {
            field: COLUMN_FIELDS.DATEINAME,
            title: "Dateiname",
            minWidth: 100,
            orderIndex: 2,
          },
          {
            field: COLUMN_FIELDS.TYP,
            title: "Typ",
            minWidth: 50,
            manualWidth: 50,
            removeFromFiltering: true,
            orderIndex: 3,
          },
          {
            field: COLUMN_FIELDS.BESITZER,
            title: "Besitzer",
            minWidth: 100,
            orderIndex: 4,
          },
          {
            field: COLUMN_FIELDS.ÄNDERUNGSDATUM,
            title: "Änderungsdatum",
            minWidth: 100,
            orderIndex: 5,
            hasInternalValue: true,
          },
          {
            field: COLUMN_FIELDS.CONTID,
            title: "Container-ID",
            minWidth: 100,
            orderIndex: 6,
          },
        ],
      },
      {
        field: "Notizen",
        title: "Notizen",
        orderIndex: 7,
        children: [
          {
            field: COLUMN_FIELDS.ANMERKUNG,
            title: "Anmerkung",
            minWidth: 100,
            orderIndex: 8,
          },
        ],
      },
    ] as GridColumnPropsCustom[];

    const version = 5;

    const migrate: GridColumnsMigrateFunction = (persistedColumns, version) => {
      if (version === 1) {
        const newPersistedColumns = persistedColumns as PersistedColumns;
        const datei = newPersistedColumns.columns[0];
        const lastChanged = datei.children![3];
        // Wurde in Version 2 hinzugefügt, um die Daten zu sortieren
        lastChanged.hasInternalValue = true;

        return newPersistedColumns;
      }

      if (version === 2) {
        const newPersistedColumns = persistedColumns as PersistedColumns;
        const datei = newPersistedColumns.columns[0];
        const lastChanged = datei.children![3];
        // Wurde in Version 3 unbenannt
        delete (lastChanged as any).hasDisplayedValue;
        lastChanged.hasInternalValue = true;

        return newPersistedColumns;
      }

      if (version === 3) {
        const newPersistedColumns = persistedColumns as PersistedColumns;
        const notiz = newPersistedColumns.columns[1];
        const anmerkung = notiz.children![3];
        // Wurde in Version 4 unbenannt von `ANMERK` nach `INTANMERK`
        anmerkung.field = COLUMN_FIELDS.ANMERKUNG;

        return newPersistedColumns;
      }

      if (version === 4) {
        const newPersistedColumns = persistedColumns as PersistedColumns;
        // Die Spalte ContID wurde in Version 5 dazugefügt.
        const datei = newPersistedColumns.columns[0];
        datei.children?.push({
          field: COLUMN_FIELDS.CONTID,
          title: "Container-ID",
          minWidth: 100,
          orderIndex: 6,
        });
        const notiz = newPersistedColumns.columns[1];
        const anmerkung = notiz.children![3];
        notiz.orderIndex = 7;
        anmerkung.orderIndex = 8;

        return newPersistedColumns;
      }

      return persistedColumns as PersistedColumns;
    };

    return { columns, version, migrate };
  }, []);

  const customCells = React.useMemo(
    () => [
      {
        columnField: COLUMN_FIELDS.DATEINAME,
        render: (props: PropsCustomCell) => (
          <ABKBasisGridCellLink
            {...props}
            text={props.dataItem.BEZ}
            to={getFileInfoUrlForContainer(
              props.dataItem.DBNAME,
              props.dataItem.CONTID
            )}
          />
        ),
      },
      {
        columnField: COLUMN_FIELDS.TYP,
        render: (props: PropsCustomCell) => (
          <td {...props.tdProps} title={props.dataItem.ContTypeInfo.TXT}>
            <SvgIcon
              width={24}
              icon={AbkIconsMap.get(props.dataItem.ContTypeInfo.ICONID)}
            />
          </td>
        ),
      },
    ],
    []
  );

  const generateQuickInfoForOneItem = async (
    item: ContWithLastChangedModified
  ) => {
    return [
      {
        title: "Dateiname",
        content: (
          <Link to={getFileInfoUrlForContainer(item.DBNAME, item.CONTID)}>
            {item.BEZ || ""}
          </Link>
        ),
      },
      {
        title: "Besitzer",
        content: item.OWNER || "",
      },
      {
        title: "Änderungsdatum",
        content: item.LASTCHANGED[DATA_KEY.DISPLAYED] || "",
      },
      {
        title: "Anmerkung",
        content: item.INTANMERK || "Keine Anmerkung vorhanden",
      },
    ];
  };
  const onSelectionChange = useQuickInfoOnGridSelection(
    generateQuickInfoForOneItem
  );

  const { resetQuickInfo, variableDisplayed } = useQuickInfoState();

  const [selectedContainers, setSelectedContainers] = React.useState<
    ContWithLastChangedModified[]
  >([]);

  const [isExportDialogOpen, setIsExportDialogOpen] = React.useState(false);
  const toggleExportDialog = React.useCallback(
    () => setIsExportDialogOpen(!isExportDialogOpen),
    [isExportDialogOpen]
  );

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const toggleDeleteDialog = React.useCallback(
    () => setIsDeleteDialogOpen(!isDeleteDialogOpen),
    [isDeleteDialogOpen]
  );

  const { isGridLoading, setIsGridLoading } = useIsGridLoading(isFetching);

  const navigate = useNavigate();
  const mutationDelete = useDeleteCont(database);
  const mutationDuplicate = useDuplicateCont(database);
  const mutationSaveAsTemplate = useSaveContAsTemplate(database);
  const mutationExport = usePostExport();

  const queryClient = useQueryClient();

  const containerActionHandlers = React.useMemo(
    () => ({
      open: (
        _event: any,
        selectedContainers: ContWithLastChangedModified[]
      ) => {
        const containerSelected = selectedContainers[0];
        const containerId = containerSelected.CONTID;
        if (database === undefined) return;

        navigate(getFileInfoUrlForContainer(database, containerId));
      },
      delete: async (
        _event: any,
        selectedContainers: ContWithLastChangedModified[]
      ) => {
        toggleDeleteDialog();
        setSelectedContainers(selectedContainers);
        const { shouldProceed } = await new Promise<DialogResponse>(
          (resolve) => {
            setDeleteDialogPromise({ resolve });
          }
        );
        if (!shouldProceed) return;

        setIsGridLoading(true);
        for (const container of selectedContainers) {
          try {
            await mutationDelete.mutateAsync(container);
          } catch {
            // Fahre fort, auch wenn es einen Fehler gibt, um das Loading zu beenden.
          }
        }

        /*
          Mache ein Refetch ganz am Ende, um zu vermeiden, dass das Backend
          gleichzeitg löscht und liest die Datenbank. Da hatten wir einmal
          eine Race Condition, in den Playwright-Tests.
        */
        queryClient.invalidateQueries({
          queryKey: [database, QUERY_KEY_GET_CONTAINER],
        });
        // setIsGridLoading(false) wird vom "useEffect" für "isFetching" zurückgesetzt

        if (
          containersAreDisplayedInQuickInfo(
            selectedContainers,
            variableDisplayed
          )
        )
          resetQuickInfo();
      },
      duplicate: async (
        _event: any,
        selectedContainers: ContWithLastChangedModified[]
      ) => {
        setIsGridLoading(true);
        const containerSelected = selectedContainers[0];
        const containerId = containerSelected.CONTID;
        try {
          await mutationDuplicate.mutateAsync(containerId);
        } catch {
          setIsGridLoading(false);
        }
        // setIsGridLoading(false) wird vom "useEffect" für "isFetching" zurückgesetzt
      },
      saveAsTemplate: async (
        _event: any,
        selectedContainers: ContWithLastChangedModified[]
      ) => {
        setIsGridLoading(true);
        const containerSelected = selectedContainers[0];
        const containerId = containerSelected.CONTID;
        try {
          await mutationSaveAsTemplate.mutateAsync(containerId);
        } catch {
          setIsGridLoading(false);
        }
        // setIsGridLoading(false) wird vom "useEffect" für "isFetching" zurückgesetzt
      },
      export: async (
        _event: any,
        selectedContainers: ContWithLastChangedModified[]
      ) => {
        toggleExportDialog();
        setSelectedContainers(selectedContainers);
        const { shouldProceed, containersToExport } =
          await new Promise<ExportDialogResponse>((resolve) => {
            setExportDialogPromise({ resolve });
          });
        if (!shouldProceed) return;

        setIsGridLoading(true);
        for (const container of containersToExport as ContainerToExport[]) {
          try {
            await mutationExport.mutateAsync({
              dbName: container.dbName,
              contId: container.contId,
              exportFormat: container.exportFormat,
            });
          } catch {}
        }
        setIsGridLoading(false);
      },
    }),
    [
      database,
      mutationDelete,
      mutationDuplicate,
      mutationExport,
      mutationSaveAsTemplate,
      navigate,
      resetQuickInfo,
      setDeleteDialogPromise,
      setExportDialogPromise,
      toggleDeleteDialog,
      toggleExportDialog,
      variableDisplayed,
      queryClient,
      setIsGridLoading,
    ]
  );

  const gridActions = React.useCallback(
    (gridRowSelection: GridRowSelection) => {
      const numberOfSelectedItems = gridRowSelection.selectedItems.length;
      if (numberOfSelectedItems === 0) return [];

      const commonActions = [
        {
          icon: "Edit.Delete",
          text: "Löschen",
          onClick: containerActionHandlers.delete,
        },
        {
          icon: "General.Export",
          text: "Exportieren",
          onClick: containerActionHandlers.export,
        },
      ];

      if (numberOfSelectedItems <= 1) {
        return [
          {
            icon: "Navigation.Open",
            text: "Öffnen",
            onClick: containerActionHandlers.open,
          },
          {
            icon: "Grid.Copy",
            text: "Duplizieren",
            onClick: containerActionHandlers.duplicate,
          },
          {
            icon: "Symbol.Star",
            text: "Als Vorlage speichern",
            onClick: containerActionHandlers.saveAsTemplate,
          },
          ...commonActions,
        ];
      }

      return [<>{`${numberOfSelectedItems} ausgewählt`}</>, ...commonActions];
    },
    [containerActionHandlers]
  );

  const shouldReturnLoader = !database || isLoading;

  return {
    shouldReturnLoader,
    onSelectionChange,
    containers: containersWithFormattedDate,
    columnsDefinition,
    gridActions,
    customCells,
    toggleDeleteDialog,
    isDeleteDialogOpen,
    toggleExportDialog,
    isExportDialogOpen,
    selectedContainers,
    isGridLoading,
  };
};

export function containersAreDisplayedInQuickInfo(
  selectedContainers: GenericObject[],
  variableDisplayed: GenericObject | GenericObject[]
) {
  if (!Array.isArray(selectedContainers)) return false;

  if (selectedContainers.length === 1) {
    if (Array.isArray(variableDisplayed)) return false;
    return selectedContainers.includes(variableDisplayed);
  }

  if (!Array.isArray(variableDisplayed)) return false;
  return selectedContainers.every(
    (selectedContainer, index) => selectedContainer === variableDisplayed[index]
  );
}

export default useVerwalten;
