import { Button } from "@progress/kendo-react-buttons";
import useCheckContainer from "../../queries/useCheckContainer";
import React from "react";
import { Job } from "../../../../core/interfaces/Job";
import { addToJobsDisplayed } from "../../../../core/db/dbJobs";
import { Loader } from "@progress/kendo-react-indicators";
import "./style.css";

type Props = { dbName: string; contId: string };
export default function ButtonCheckContainer({ dbName, contId }: Props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const { mutateAsync } = useCheckContainer();

  return (
    <Button
      className="button-check-container"
      onClick={async () => {
        setIsLoading(true);
        try {
          const response = await mutateAsync({ dbName, contId });
          const job: Job | undefined = response.data;
          addToJobsDisplayed(job, dbName);
        } catch {}
        setIsLoading(false);
      }}
    >
      {isLoading ? <Loader /> : "Prüfen"}
    </Button>
  );
}
