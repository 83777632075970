import { Navigate, useLocation, useOutlet } from "react-router-dom";
import { useEffect } from "react";
import { LAST_USED } from "../../core/constants/urls";
import useTabNavigationStore from "../../core/stores/useTabNavigationStore";

export interface Tab {
  id: number;
  label: string;
  to: string;
}

const TabsLayout = ({
  routePrefix,
  tabs,
}: {
  routePrefix: string;
  tabs: Tab[];
}) => {
  const { setTabs, resetTabs } = useTabNavigationStore();
  const outlet = useOutlet();
  const location = useLocation();

  useEffect(() => {
    setTabs(tabs);
    return () => resetTabs();
  }, []);

  useEffect(() => {
    resetTabs();
    setTabs(tabs);
  }, [location, routePrefix]);

  return <>{outlet || <Navigate to={LAST_USED} />}</>;
};

export default TabsLayout;
