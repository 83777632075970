import React from "react";
import ABKNumericInput from "../../../../../../../abk-shared/components/atoms/ABKNumericInput";
import { AllgemeinFieldInputProps } from "./AllgemeinForm";

export default function Version({
  readOnly,
  setPageState,
  pageState,
  kenndaten,
}: AllgemeinFieldInputProps) {
  const setVersionNumber = React.useCallback(
    (version: number | null) => {
      if (version === null) version = 1;
      setPageState({
        ...pageState,
        Kenndaten: {
          ...kenndaten,
          VERSIONSNR: version,
        },
      });
    },
    [pageState, kenndaten, setPageState]
  );

  return (
    <ABKNumericInput
      editorId="version"
      label="Version"
      value={kenndaten.VERSIONSNR}
      setValue={setVersionNumber}
      readOnly={readOnly}
      backendKey="VERSIONSNR"
    />
  );
}
