import { useState } from "react";

export default function useListItem(
  dataItem: any,
  data: any[],
  setData: (data: any[]) => void
) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState(dataItem);

  const handleEdit = () => {
    setIsEditMode(!isEditMode);
  };

  const handleDelete = (id: string) => {
    setData(data.filter((item: any) => item.id !== id));
  };

  const handleSave = () => {
    setIsEditMode(false);
    setData(
      data.map((item: any) => (item.id === dataItem.id ? editedData : item))
    );
  };

  return {
    handleDelete,
    handleEdit,
    handleSave,
    isEditMode,
    setIsEditMode,
    editedData,
    setEditedData,
  };
}
