import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import { AbkIconsMap } from "../../../../../interfaces/abk9SvgIcons";
import ABKNavigationButtonItem from "../../../../atoms/ABKNavigationButtonItem";
import ABKOptionsIconButton from "../../../../atoms/ABKOptionsIconButton";
import { GridActionObject } from "../../interfaces/GridAction";
import DataStateContext from "../../hooks/useGridDataState/DataStateContext";
import { SelectionContext } from "../../hooks/useGridReorderRows/gridReorderRowsContexts";

type Props = {
  isMobileGrid: boolean;
};

export default function ABKToolbarGridActions({ isMobileGrid }: Props) {
  const { dataState, dataResultWithSelectedField, gridActions } =
    React.useContext(DataStateContext);
  const gridRowSelection = React.useContext(SelectionContext);

  const actions = gridActions
    ? gridActions(gridRowSelection, dataResultWithSelectedField, dataState)
    : [];
  const numberOfActionsDisplayed = isMobileGrid
    ? 3
    : // Zeige alle Aktionen für die Desktop-Version
      undefined;

  const firstActions = actions.slice(0, numberOfActionsDisplayed);
  const actionsDisplayed = firstActions.map((action, index) => {
    if (React.isValidElement(action)) {
      return (
        <span className="k-align-self-center" key={`reactNode_${index}`}>
          {action}
        </span>
      );
    }

    const actionObject = action as GridActionObject;
    return (
      <Button
        svgIcon={AbkIconsMap.get(actionObject.icon)}
        title={actionObject.text}
        fillMode="link"
        {...(isMobileGrid && { className: "grid-toolbar-button" })}
        onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
          actionObject.onClick(event, gridRowSelection.selectedItems);
        }}
        key={actionObject.text}
      />
    );
  });

  const remainingActions = isMobileGrid
    ? actions.slice(numberOfActionsDisplayed)
    : []; // Zeige alle Aktionen für die Desktop-Version
  const actionsInDropdown =
    remainingActions.length === 0 ? null : (
      <React.Fragment key="menu">
        <ABKOptionsIconButton
          buttonClassName="grid-toolbar-button"
          title="Weitere Funktionen"
          insidePopupElement={
            <>
              {(remainingActions as GridActionObject[]).map((actionObject) => (
                <ABKNavigationButtonItem
                  text={actionObject.text}
                  onClick={(
                    event: React.MouseEvent<HTMLElement, MouseEvent>
                  ) => {
                    actionObject.onClick(event, gridRowSelection.selectedItems);
                  }}
                  icon={actionObject.icon}
                  key={actionObject.text}
                />
              ))}
            </>
          }
        />
      </React.Fragment>
    );

  const toolbarLeftElements = [...actionsDisplayed, actionsInDropdown];

  return <>{toolbarLeftElements}</>;
}
