import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import useAppBarState from "../../core/stores/useAppBarState";
import useDrawerStore from "../../core/stores/useDrawerStore";
import { changeFavIcon } from "../../core/utility/favIcon";
import WrapperTemplate from "../../core/components/templates/WrapperTemplate";
import useCurrentDBStore from "../../core/stores/useCurrentDBStore";
import { DB } from "../../abk-shared/interfaces/db";
import { navigationItems } from "./navigationItems";

const Layout = () => {
  const { setAppBarTitle, setAppBarActionButton } = useAppBarState();
  const { setCurrentDrawerItems } = useDrawerStore();

  const params = useParams();
  const dbInUrl = params.db ?? "";

  const { currentDB } = useCurrentDBStore();
  const isValidDB = !!currentDB && currentDB.DBNAME === dbInUrl;
  const dbName = isValidDB ? (currentDB as DB).DBNAME : dbInUrl;

  useEffect(() => {
    const drawerItems = [
      navigationItems.open(dbName),
      navigationItems.new(dbName),
      navigationItems.files(dbName),
      navigationItems.import(dbName),
    ];

    setAppBarTitle("Startseite");
    setAppBarActionButton(null);
    setCurrentDrawerItems(drawerItems);
    changeFavIcon("/favicon.ico");
  }, [dbName, setAppBarTitle, setAppBarActionButton, setCurrentDrawerItems]);

  return (
    <>
      <WrapperTemplate includeDescription includeTitle>
        <Outlet />
      </WrapperTemplate>
    </>
  );
};

export default Layout;
