import React from "react";
import axios from "axios";
import useIsGridLoading from "../../../../../../../core/utility/useIsGridLoading";
import { Parameterliste } from "../../../../../interfaces/parameterlisten";
import createParameterlisteURL from "../../createParameterlisteURL";
import useAddParameterliste from "./useAddParameterliste";
import useAddParameterlisteAbove from "./useAddParameterlisteAbove";
import useDuplicateParameterliste from "./useDuplicateParameterliste";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { QUERY_KEY_PARAMETERLISTE } from "../../../../../queries/useGetSingleParameterliste";
import { useParameterlistenState } from "./useParameterlistenState";
import { DialogResponse } from "../../../../../../Startseite/pages/Verwalten/DeleteDialog";

export default function useParameterlistenActionHandlers(
  dbName: string,
  contId: string,
  isFetching: boolean
) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isGridLoading, setIsGridLoading } = useIsGridLoading(isFetching);
  const mutationAddParameterliste = useAddParameterliste(dbName, contId);
  const mutationAddParameterlisteAbove = useAddParameterlisteAbove();
  const mutationDuplicateParameterliste = useDuplicateParameterliste(
    dbName,
    contId
  );

  const { setShowDeleteDialog, setDeleteDialogPromise } =
    useParameterlistenState();

  const parameterlistenActionHandlers = React.useMemo(
    () => ({
      open: (_event: any, selectedParameterlisten: Parameterliste[]) => {
        const parameterlisteSelected = selectedParameterlisten[0];
        const parameterlisteId = parameterlisteSelected.DATAID;
        const parameterlisteUrl = createParameterlisteURL(
          parameterlisteId,
          parameterlisteSelected.DBNAME,
          parameterlisteSelected.CONTID
        );
        navigate(parameterlisteUrl);
      },

      delete: async (
        _event: any,
        selectedParameterlisten: Parameterliste[]
      ) => {
        const dbname = selectedParameterlisten[0].DBNAME;
        const contid = selectedParameterlisten[0].CONTID;

        setShowDeleteDialog(true);
        const { shouldProceed } = await new Promise<DialogResponse>(
          (resolve) => {
            setDeleteDialogPromise({ resolve });
          }
        );
        if (!shouldProceed) return;

        const deletePL = async (dataid: string) => {
          const response = await axios.delete(
            `/api/db/${dbname}/cont/${contid}/pl/${dataid}`
          );
          return response.data;
        };

        for (const pl of selectedParameterlisten) {
          await deletePL(pl.DATAID);
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_PARAMETERLISTE, dbname, contid],
          });
        }
      },

      createAtEnd: async () => {
        setIsGridLoading(true);
        try {
          await mutationAddParameterliste.mutateAsync();
        } catch {
          setIsGridLoading(false);
        }
      },

      createBeforeRow: async (selectedItem: Parameterliste) => {
        setIsGridLoading(true);
        try {
          await mutationAddParameterlisteAbove.mutateAsync(selectedItem);
        } catch {
          setIsGridLoading(false);
        }
      },
      duplicate: async (selectedItem: Parameterliste) => {
        try {
          const duplicatedItem =
            await mutationDuplicateParameterliste.mutateAsync(selectedItem);
          // duplicatedItem.data
          console.log("duplicated item is: ");
          console.log(duplicatedItem.data);
          return duplicatedItem.data;
        } catch {
          setIsGridLoading(false);
          return;
        }
      },
    }),
    [
      mutationAddParameterliste,
      mutationAddParameterlisteAbove,
      mutationDuplicateParameterliste,
      navigate,
      queryClient,
      setDeleteDialogPromise,
      setIsGridLoading,
      setShowDeleteDialog,
    ]
  );

  return { parameterlistenActionHandlers, isGridLoading };
}
