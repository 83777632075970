import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Parameterliste } from "../../../../../interfaces/parameterlisten";
import { useNavigate } from "react-router-dom";
import createParameterlisteURL from "../../createParameterlisteURL";

export default function useAddParameterlisteAbove() {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (selectedParameterliste: Parameterliste) =>
      axios.post<Parameterliste>(
        `/api/db/${selectedParameterliste.DBNAME}/cont/${selectedParameterliste.CONTID}/pl`,
        undefined,
        {
          // "Content-Type" explizit hineingegeben, ohne den verursacht es einen Fehler
          headers: { "Content-Type": "application/json" },
          params: { insertbefore: selectedParameterliste.DATAID },
        }
      ),
    onSuccess: (response) => {
      const newParameterliste = response.data;
      const parameterlisteURL = createParameterlisteURL(
        newParameterliste.DATAID,
        newParameterliste.DBNAME,
        newParameterliste.CONTID
      );
      navigate(parameterlisteURL);
    },
  });
}
