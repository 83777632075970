import { GenericObject } from "../../../../../interfaces/GenericObject";
import { setGroupIds } from "@progress/kendo-react-data-tools";
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  process,
  State,
} from "@progress/kendo-data-query";
import { DATA_KEY } from "../../constants";
import { produce } from "immer";

export function processAndSetGroupIds(
  items: GenericObject[],
  dataState: State,
  fieldsWithInternalValue: string[]
) {
  const { sort, group, filter } = defineSortAndGroupToProcessInternalValue(
    dataState,
    fieldsWithInternalValue
  );
  const dataStateWithAllItems = dataStateWithTakeAllData(items, {
    ...dataState,
    sort,
    group,
    filter,
  });
  const dataResult = process(items, dataStateWithAllItems);
  setGroupIds({ data: dataResult.data, group: dataState.group });
  return dataResult;
}

const dataStateWithTakeAllData = (
  items: GenericObject[],
  currentDataState: State
) => ({
  ...currentDataState,
  take: items.length,
});

function defineSortAndGroupToProcessInternalValue(
  newDataState: State,
  fieldsWithInternalValue: string[]
) {
  const sort = newDataState.sort?.map((sortDescriptor) => {
    let fieldSorted = sortDescriptor.field;
    if (fieldsWithInternalValue.includes(sortDescriptor.field))
      fieldSorted = `${sortDescriptor.field}.${DATA_KEY.INTERNAL}`;

    return {
      ...sortDescriptor,
      field: fieldSorted,
    };
  });

  const group = newDataState.group?.map((groupDescriptor) => {
    let fieldGrouped = groupDescriptor.field;
    if (fieldsWithInternalValue.includes(groupDescriptor.field))
      fieldGrouped = `${groupDescriptor.field}.${DATA_KEY.INTERNAL}`;

    return {
      ...groupDescriptor,
      field: fieldGrouped,
    };
  });

  const newFilter = newDataState.filter;
  const columnMenuFilters = newFilter?.filters;

  const filter = hasColumnMenuFilters(columnMenuFilters)
    ? createColumnMenuFiltersWithDisplayedValue(
        newFilter,
        columnMenuFilters as CompositeFilterDescriptor[],
        fieldsWithInternalValue
      )
    : newFilter;

  return { sort, group, filter };
}

function hasColumnMenuFilters(
  columnMenuFilters:
    | (CompositeFilterDescriptor | FilterDescriptor)[]
    | undefined
) {
  const firstColumnMenuFilter = (
    columnMenuFilters?.[0] as CompositeFilterDescriptor
  )?.filters;

  return Array.isArray(firstColumnMenuFilter);
}

function createColumnMenuFiltersWithDisplayedValue(
  filter: CompositeFilterDescriptor | undefined,
  columnMenuFilters: CompositeFilterDescriptor[],
  fieldsWithInternalValue: string[]
) {
  const columnMenuFiltersProcessed: CompositeFilterDescriptor[] =
    columnMenuFilters.map((columnMenuFilter) => {
      const filters = (columnMenuFilter.filters as FilterDescriptor[]).map(
        (filterDescriptor) =>
          generateFilterWithDisplayedValue(
            filterDescriptor,
            fieldsWithInternalValue
          )
      );

      return {
        ...columnMenuFilter,
        filters,
      };
    });

  return {
    ...filter,
    logic: filter?.logic || "or", // `|| "or"` ist für TypeScript
    filters: columnMenuFiltersProcessed,
  };
}

function generateFilterWithDisplayedValue(
  filterDescriptor: FilterDescriptor,
  fieldsWithInternalValue: string[]
) {
  const fieldFiltered = filterDescriptor.field;
  const result = {
    ...filterDescriptor,
    field: fieldFiltered,
  };

  if (
    typeof fieldFiltered === "string" &&
    fieldsWithInternalValue.includes(fieldFiltered)
  )
    result.field = `${filterDescriptor.field}.${DATA_KEY.DISPLAYED}`;

  return result;
}

/*
  Erstelle einen "dummy" Filter, um die Suche mit den Column-Menüs Filters
  zu unterstützen. Dieser "dummy" Filter vermeidet, dass wir einen Bug mit den
  Column-Menüs haben. Der Bug kann folgendes beschrieben werden:
    - "a" in der Suche eingeben
    - Das Column-Menü von der ersten Spalten, z.B. "Dateiname", öffnen
    - Es wird "1 gewählte Objekte" angezeigt, auch wenn noch keine Checkbox selektiert ist
    - Eine weitere Checkbox im Column-Menü zu selektieren funktioniert nicht, das
    ändert die Filters nicht
    -> Das Column-Menü liest die erste Spalte in den Filtern, und betracht sie als
    ein gewähltes Objekt
*/
const DUMMY_SEARCH_FILTER = {
  logic: "or",
  field: "DUMMY_COLUMN_WHICH_DOES_NOT_EXIST",
  operator: "contains",
};

const ID_SEARCH_FILTER = "search";

type CompositeFilterDescriptorCustom = {
  id?: "search";
  logic: "or" | "and";
  filters: Array<FilterDescriptor | CompositeFilterDescriptorCustom>;
};

export function generateFiltersToMatchDisplayedValue(
  fieldsFiltered: string[],
  fieldsWithInternalValue: string[],
  newFilterValue: string
) {
  if (newFilterValue === "") return [];

  const filtersGenerated = fieldsFiltered.map((field) => {
    let fieldFiltered = field;
    if (fieldsWithInternalValue.includes(field))
      fieldFiltered = `${field}.${DATA_KEY.DISPLAYED}`;

    return {
      field: fieldFiltered,
      operator: "contains",
      value: newFilterValue,
    };
  });

  const filters: CompositeFilterDescriptorCustom = {
    logic: "or",
    filters: [DUMMY_SEARCH_FILTER, ...filtersGenerated],
    id: ID_SEARCH_FILTER,
  };
  return [filters];
}

export function createNewFilterState(
  filterState: CompositeFilterDescriptorCustom | undefined,
  filtersToMatchSearch: CompositeFilterDescriptorCustom[]
) {
  const newFilterState: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };

  const existingFilters = filterState?.filters;
  if (!existingFilters) {
    newFilterState.filters = filtersToMatchSearch;
    return newFilterState;
  }

  const indexOfSearchFilters = existingFilters.findIndex(
    (filter) => (filter as any)?.id === ID_SEARCH_FILTER
  );
  const hasSearchFilter =
    indexOfSearchFilters != null && indexOfSearchFilters !== -1;
  if (!hasSearchFilter) {
    newFilterState.filters = [...existingFilters, ...filtersToMatchSearch];
    return newFilterState;
  }

  const existingFiltersWithoutSearchFilters = produce(
    existingFilters,
    (draft) => {
      draft.splice(indexOfSearchFilters, 1);
    }
  );
  newFilterState.filters = [
    ...existingFiltersWithoutSearchFilters,
    ...filtersToMatchSearch,
  ];
  return newFilterState;
}

export function createActiveColumnMenus(
  activeColumnMenus: Record<string, true>,
  filter: FilterDescriptor | CompositeFilterDescriptor
) {
  if (!filter.hasOwnProperty("filters")) return activeColumnMenus;

  const firstElement = (filter as CompositeFilterDescriptor).filters[0];
  if (!firstElement.hasOwnProperty("field")) return activeColumnMenus;

  const field = (firstElement as FilterDescriptor).field;
  if (typeof field !== "string") return activeColumnMenus;

  activeColumnMenus[field] = true;
  return activeColumnMenus;
}
