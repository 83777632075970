import { GridToolbar } from "@progress/kendo-react-grid";
import React from "react";
import "./styles.scss";
import ABKBasisGridSearch from "src/abk-shared/components/organisms/ABKBasisGrid/components/ABKBasisGridToolbar/ABKBasisGridSearch";
import { Button } from "@progress/kendo-react-buttons";
import { AbkIconsMap } from "src/abk-shared/interfaces/abk9SvgIcons";

interface Props {
  filterValue: string;
  isMobileGrid: boolean;
  showDeleteButton: boolean;
  onFilterChange: (newValue: string) => void;
  onInsertButtonClick: () => void;
  onDeleteButtonClick: () => void;
}

export const PiktogrammToolbar = ({
  filterValue,
  isMobileGrid,
  showDeleteButton,
  onFilterChange,
  onInsertButtonClick,
  onDeleteButtonClick,
}: Props) => {
  return (
    <GridToolbar className="grid-toolbar" size="small">
      <div className="wrapper-tools" data-testid="piktogramm-toolbar">
        <div className="left-elements">
          <Button
            svgIcon={AbkIconsMap.get("Grid.InsertEnd")}
            title={"Hinzufügen"}
            fillMode="link"
            {...(isMobileGrid && { className: "toolbar-left-button" })}
            onClick={() => {
              onInsertButtonClick();
            }}
          />
          {showDeleteButton && (
            <Button
              svgIcon={AbkIconsMap.get("Edit.Delete")}
              title={"Löschen"}
              fillMode="link"
              {...(isMobileGrid && { className: "toolbar-left-button" })}
              onClick={() => {
                onDeleteButtonClick();
              }}
            />
          )}
        </div>
        <div className="right-elements">
          <ABKBasisGridSearch
            isMobileGrid={isMobileGrid}
            filterValue={filterValue}
            onFilterChange={onFilterChange}
          />
        </div>
      </div>
    </GridToolbar>
  );
};
