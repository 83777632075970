import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { QUERY_KEY_PARAMETERLISTE } from "./useGetSingleParameterliste";
import { Versioned } from "../../../core/interfaces/Versioned";
import { Parameterliste } from "../interfaces/parameterlisten";
import { useValidationError } from "../../../abk-shared/stores/ValidationErrorStoreProvider";
import { setValidationErrorFromABKError } from "../../../abk-shared/stores/ValidationErrorStoreProvider/useGetValidationErrorMessage";
import { formatWithEtag } from "./formatWithEtag";

type MutationSaveParameterListe = {
  db: string;
  contId: string;
  parameterliste: Versioned<Parameterliste>;
};
export default function useSaveParameterliste() {
  const { setValidationErrors } = useValidationError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      db,
      contId,
      parameterliste,
    }: MutationSaveParameterListe) => {
      return axios.put(
        `/api/db/${db}/cont/${contId}/pl/${parameterliste.value.DATAID}`,
        parameterliste.value,
        {
          headers: {
            "If-Match": parameterliste.etag,
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: (response, variables) => {
      setValidationErrors([]);
      queryClient.setQueryData(
        [
          QUERY_KEY_PARAMETERLISTE,
          variables.db,
          variables.contId,
          variables.parameterliste.value.DATAID,
        ],
        formatWithEtag(response)
      );
    },
    onError: (error) =>
      setValidationErrorFromABKError(error, setValidationErrors),
  });
}
