import React from "react";
import { InfoItemContent, InfoItemObject } from ".";
import QuickInfoSubTitle from "./QuickInfoSubTitle";
import QuickInfoContentWithMultipleLines from "./QuickInfoContentWithMultipleLines";
import ABKNoContent from "../../../atoms/ABKNoContent";

type Props = {
  itemContent: InfoItemContent | null;
  shouldDisplayNoContent?: boolean;
};
export default function ItemContent({
  itemContent,
  shouldDisplayNoContent = true,
}: Props) {
  if (!itemContent && shouldDisplayNoContent) return <ABKNoContent />;

  if (typeof itemContent === "string") {
    // From: https://stackoverflow.com/a/45709854
    const newLineRegex = /\r?\n/;
    const hasNewLines = newLineRegex.test(itemContent);
    if (hasNewLines) {
      return (
        <QuickInfoContentWithMultipleLines
          itemContent={itemContent.split(newLineRegex)}
        />
      );
    }

    return <p className="quick-info-item-content">{itemContent}</p>;
  }

  if (Array.isArray(itemContent)) {
    return (
      <div className="k-pl-sm">
        {itemContent.map((content, index) => {
          const key = `quick-info-sub-item-${index}`;
          if (React.isValidElement(content))
            return <React.Fragment key={key}>{content}</React.Fragment>;

          const infoItem = content as InfoItemObject;
          return (
            <React.Fragment key={key}>
              <QuickInfoSubTitle title={infoItem.title} />
              <ItemContent
                itemContent={infoItem.content}
                shouldDisplayNoContent={shouldDisplayNoContent}
              />
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  return <>{itemContent}</>;
}
