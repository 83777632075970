import _ from "lodash";
import ABKLabel from "../../atoms/ABKLabel";
import { SvgIcon } from "@progress/kendo-react-common";
import { Dummy_Empty } from "../../../interfaces/abk9SvgIcons";

export default function Columns({ objectKeys }: { objectKeys: string[] }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        borderBottom: "1px solid #000",
        padding: 4,
        margin: "4px 0",
      }}
    >
      <SvgIcon
        width={20}
        height={20}
        style={{ marginRight: 2 }}
        icon={Dummy_Empty}
      />
      {objectKeys.map((key) => (
        <ABKLabel style={{ flex: 1, color: "gray" }} key={key}>
          {key}
        </ABKLabel>
      ))}
      <SvgIcon width={20} height={20} icon={Dummy_Empty} />
      <SvgIcon width={20} height={20} icon={Dummy_Empty} />
    </div>
  );
}
