import "./style.scss";

type Props = { children: React.ReactNode };
export default function ABKFormErrorMessage({ children }: Props) {
  const classNameForEndToEndTests = "test-abk-form-error-message";
  return (
    <div
      className={`form-field-input-error-message ${classNameForEndToEndTests}`}
    >
      {children}
    </div>
  );
}
