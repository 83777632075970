import "./style.css";
import ABKMobileModal from "../../atoms/ABKMobileModal";
import DrawerItems from "./DrawerItems";
import DrawerLogo from "./DrawerLogo";
import { NavigationItem } from "../../../interfaces/NavigationItem";

interface Props {
  open: boolean;
  onClose: () => void;
  logopath: string;
  currentDrawerItems: NavigationItem[];
  onDrawerItemSelect: (e: NavigationItem) => void;
}

const ABKMobileDrawer = (props: Props) => {
  return (
    <ABKMobileModal open={props.open} onClose={props.onClose} position="left">
      <div className="mobile-abk-logo-container">
        <DrawerLogo logopath={props.logopath} />
      </div>
      <div className="drawer-items-container">
        <DrawerItems
          currentDrawerItems={props.currentDrawerItems}
          onDrawerItemSelect={props.onDrawerItemSelect}
        />
      </div>
    </ABKMobileModal>
  );
};

export default ABKMobileDrawer;
