import React from "react";
import { Splitter, SplitterOnChangeEvent } from "@progress/kendo-react-layout";
import InfoHub from "./InfoHub";
import Quickinfo from "./QuickInfo";
import NotificationBox, { INotification } from "./NotificationBox";
import useResetQuickInfoOnNotificationDelete from "./useResetQuickInfoOnNotificationDelete";

interface SidebarProps {
  notifications: INotification[];
  onCloseNotification: (notification: INotification) => void;
  infoHubOptions?: React.ReactNode;
  onNotificationClick?: FunctionOnNotificationClick;
  isMobile?: boolean;
}

export type FunctionOnNotificationClick = (
  notification: INotification
) => Promise<void>;

const Sidebar: React.FC<SidebarProps> = ({
  notifications,
  onCloseNotification,
  infoHubOptions,
  onNotificationClick,
  isMobile,
}: SidebarProps) => {
  const [nestedPanes, setNestedPanes] = React.useState<Array<any>>([
    { size: "40%", max: "80%" },
    {},
  ]);
  const onNestedChange = (event: SplitterOnChangeEvent) => {
    setNestedPanes(event.newState);
  };

  useResetQuickInfoOnNotificationDelete(notifications);

  const sideBarHeight = isMobile ? "100%" : "calc(100vh - 45px)";
  return (
    <>
      <Splitter
        className="sidebar"
        style={{ height: sideBarHeight }}
        panes={nestedPanes}
        orientation={"vertical"}
        onChange={onNestedChange}
      >
        <div
          data-testid="sidebar"
          className="pane-content"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <InfoHub infoHubOptions={infoHubOptions} />
          <NotificationBox
            notifications={notifications}
            onCloseNotification={onCloseNotification}
            onNotificationClick={onNotificationClick}
          />
        </div>
        <div
          data-testid="quick-info"
          className="pane-content"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Quickinfo />
        </div>
      </Splitter>
    </>
  );
};

export default Sidebar;
