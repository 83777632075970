import { SvgIcon } from "@progress/kendo-react-common";
import { General_DragDrop } from "../../../../../../../interfaces/abk9SvgIcons";
import "./style.scss";

type Props = { style?: React.CSSProperties; dataDragHandle?: string };

const DragAndDropIcon = ({ style, dataDragHandle }: Props) => (
  // Innerhalb eines `div` um ein Ellipsis zu vermeiden in den Zellen
  <div style={style} data-drag-handle={dataDragHandle}>
    <SvgIcon
      className="drag-and-drop-icon"
      style={{ pointerEvents: "none" }}
      icon={General_DragDrop}
      height={20}
      width={20}
    />
  </div>
);

export default DragAndDropIcon;
