import ValidationErrorStoreProvider from "../../../../abk-shared/stores/ValidationErrorStoreProvider";
import { useIsValidDbAndContIdInURL } from "../../../../core/utility/useIsValidDB";
import Allgemein from "./allgemein";
import "./DateiInfoPage.css";

const FileInfoWrapper = () => {
  const { isValid, db, contId } = useIsValidDbAndContIdInURL();
  if (!isValid) return <></>;

  return (
    <ValidationErrorStoreProvider>
      <Allgemein
        key={`file-info-${db}-${contId}`}
        database={db as string}
        contId={contId as string}
      />
    </ValidationErrorStoreProvider>
  );
};

export default FileInfoWrapper;
