import { Outlet, useParams } from "react-router-dom";
import useDrawerStore from "../../core/stores/useDrawerStore";
import { useEffect } from "react";
import useAppBarState from "../../core/stores/useAppBarState";
import { changeFavIcon } from "../../core/utility/favIcon";
import WrapperTemplate from "../../core/components/templates/WrapperTemplate";
import { navigationItems } from "../../Router/Layout/navigationItems";

const AdminLayout: React.FC = () => {
  const db = useParams().db;
  const { setAppBarTitle } = useAppBarState();
  const { setCurrentDrawerItems } = useDrawerStore();

  useEffect(() => {
    const drawerItems = [
      navigationItems.org(db),
      navigationItems.departments(db),
      navigationItems.users(db),
      navigationItems.licenses(db),
      navigationItems["db-protocol"](db),
      navigationItems.databases(db),
      navigationItems["db-status"](db),
      navigationItems["server-log"](db),
      navigationItems["server-info"](db),
      navigationItems["server-status"](db),
    ];
    setAppBarTitle("Admin-Center");
    changeFavIcon("/assets/favicons/Menu.Admin.Tabicon.svg");
    setCurrentDrawerItems(drawerItems);
  }, [db, setAppBarTitle, setCurrentDrawerItems]);

  return (
    <WrapperTemplate includeTitle includeDescription>
      <Outlet />
    </WrapperTemplate>
  );
};

export default AdminLayout;
