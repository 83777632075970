import { GridToolbar } from "@progress/kendo-react-grid";
import React from "react";
import ABKOptionsIconButton from "../../../../atoms/ABKOptionsIconButton";
import ABKNavigationButtonItem from "../../../../atoms/ABKNavigationButtonItem";
import ABKBasisGridSearch from "./ABKBasisGridSearch";
import { Checkbox } from "@progress/kendo-react-inputs";
import "./style.scss";
import ABKToolbarGridActions from "./ABKToolbarGridActions";
import { AbkIconsMap } from "../../../../../interfaces/abk9SvgIcons";
import { GridColumnPropsCustom } from "../../interfaces/GridColumns";
import ABKToggleColumns from "./ABKToggleColumns";
import { FunctionColumnToggleVisibility } from "../../hooks/useGridColumns/toggleColumnVisibility";

type Props = {
  filterValue: string;
  onFilterChange: (newValue: string) => void;
  excelExport: {
    disabled: boolean;
    excelExport: () => void;
  };
  isMobileGrid: boolean;
  headerSelectionValue: boolean;
  onHeaderSelectionChange: (event: any) => void;
  isSelectionEnabled: boolean;
  columns: GridColumnPropsCustom[];
  onColumnToggleVisibility: FunctionColumnToggleVisibility;
};

const ABKBasisGridToolbar: React.FC<Props> = ({
  filterValue,
  onFilterChange,
  excelExport,
  isMobileGrid,
  headerSelectionValue,
  onHeaderSelectionChange,
  isSelectionEnabled,
  columns,
  onColumnToggleVisibility,
}) => {
  return (
    <GridToolbar className="grid-toolbar" size="small">
      <div className="wrapper-tools">
        <div className="left-elements">
          <ABKToolbarGridActions isMobileGrid={isMobileGrid} />
        </div>
        <div className="right-elements">
          <ABKBasisGridSearch
            isMobileGrid={isMobileGrid}
            filterValue={filterValue}
            onFilterChange={onFilterChange}
          />
          <ABKOptionsIconButton
            buttonClassName="grid-toolbar-button"
            title="Tabellenmenü"
            svgIcon={AbkIconsMap.get("Dummy.Missing")}
            insidePopupElement={
              <>
                <ABKNavigationButtonItem
                  text="Nach Excel exportieren"
                  onClick={excelExport.excelExport}
                  icon="Data.Format.XLS"
                  disabled={excelExport.disabled}
                  title="Nach Excel exportieren"
                />
                {!isMobileGrid && (
                  <ABKToggleColumns
                    columns={columns}
                    onColumnToggleVisibility={onColumnToggleVisibility}
                  />
                )}
              </>
            }
          />
        </div>
      </div>
      {isSelectionEnabled && isMobileGrid && (
        <div className="k-flex-none k-align-self-end">
          <Checkbox
            style={{ marginRight: 8, marginBottom: 5, width: 30, height: 30 }}
            value={headerSelectionValue}
            onChange={(event) => {
              onHeaderSelectionChange(event);
            }}
          />
        </div>
      )}
    </GridToolbar>
  );
};

export default ABKBasisGridToolbar;
