import { KEY_REMOVE_FROM_FILTERING } from "../../../../../../abk-shared/components/organisms/ABKBasisGrid/constants";
import {
  GridColumnPropsCustom,
  GridColumnsMigrateFunction,
  PersistedColumns,
} from "../../../../../../abk-shared/components/organisms/ABKBasisGrid/interfaces/GridColumns";

export default function createEintraegeColumnsDefinition() {
  const columns = [
    {
      field: "Allgemein",
      title: "Allgemein",
      orderIndex: 1,
      minWidth: 100,
      children: [
        {
          field: "VARNAME",
          title: "Parameterkennung",
          orderIndex: 2,
          minWidth: 100,
        },
        {
          field: "ITEMTYPE",
          title: "Kategorie",
          orderIndex: 3,
          minWidth: 100,
        },
        {
          field: "MENGENABH",
          title: "Mengenabhängig",
          orderIndex: 4,
          minWidth: 100,
          [KEY_REMOVE_FROM_FILTERING]: true,
        },
        {
          field: "BEZ",
          title: "Bezeichnung",
          orderIndex: 5,
          minWidth: 100,
        },
        {
          field: "EH",
          title: "Einheit",
          orderIndex: 6,
          minWidth: 100,
        },
        {
          field: "FAVORIT",
          title: "Favorit",
          orderIndex: 7,
          minWidth: 100,
          [KEY_REMOVE_FROM_FILTERING]: true,
        },
        {
          field: "SHOWIF",
          title: "Ausblenden",
          show: false,
          orderIndex: 8,
          minWidth: 100,
        },
        {
          field: "SUMMEBILDEN",
          title: "Summe bilden",
          orderIndex: 9,
          minWidth: 100,
        },
        {
          field: "REQUIRED",
          title: "Erforderlich",
          orderIndex: 10,
          minWidth: 100,
          [KEY_REMOVE_FROM_FILTERING]: true,
        },
        {
          field: "ITEMID",
          title: "Parameter-ID",
          show: false,
          orderIndex: 11,
          minWidth: 100,
        },
        {
          field: "Funktion",
          title: "Funktion",
          show: false,
          orderIndex: 12,
          width: 100,
        },
        {
          field: "Produkt",
          title: "Produktgruppe",
          show: false,
          orderIndex: 13,
          width: 100,
        },
        {
          field: "PIKTID",
          title: "Piktogramm-ID",
          show: false,
          orderIndex: 14,
          width: 100,
        },
      ],
    },
    {
      field: "Zahl",
      title: "Zahl",
      orderIndex: 18,
      show: false,
      minWidth: 100,
      children: [
        {
          field: "NUMNK",
          title: "Nachkommastellen",
          orderIndex: 19,
          minWidth: 100,
          show: false,
          hasInternalValue: true,
        },
        {
          field: "NUMMIN",
          title: "Minimalwert",
          orderIndex: 20,
          minWidth: 100,
          show: false,
          hasInternalValue: true,
        },
        {
          field: "NUMMAX",
          title: "Maximalwert",
          orderIndex: 21,
          minWidth: 100,
          show: false,
          hasInternalValue: true,
        },
        {
          field: "FORMEL",
          title: "Rechenansatz",
          orderIndex: 22,
          show: false,
        },
        {
          field: "AWList_Num",
          title: "Auswahlliste bei Typ Zahl",
          orderIndex: 23,
          width: 100,
          show: false,
        },
      ],
    },
    {
      field: "Text",
      title: "Text",
      show: false,
      orderIndex: 24,
      children: [
        {
          field: "AWList_Text",
          title: "Auswahlliste bei Typ Text einzeilig",
          orderIndex: 25,
          width: 100,
          show: false,
        },
      ],
    },
    {
      field: "Sonstige",
      title: "Sonstige",
      show: false,
      orderIndex: 26,
      width: 100,
      children: [
        {
          field: "AWUSEREN",
          title: "Benutzereingabe für Auswahlliste",
          orderIndex: 27,
          width: 100,
          show: false,
          [KEY_REMOVE_FROM_FILTERING]: true,
        },
      ],
    },
  ] as GridColumnPropsCustom[];

  const version = 4;

  const migrate: GridColumnsMigrateFunction = (persistedColumns, version) => {
    if (version === 1) {
      const newPersistedColumns = persistedColumns as PersistedColumns;
      // Children wurde in Version 2 hinzugefügt
      const firstColumn = newPersistedColumns.columns[0];
      firstColumn.field = "Allgemein";
      firstColumn.title = "Allgemein";
      firstColumn.children = [
        {
          field: "VARNAME",
          title: "Parameterkennung",
          orderIndex: 2,
          minWidth: 100,
        },
        {
          field: "ITEMTYPE",
          title: "Kategorie",
          orderIndex: 3,
          minWidth: 100,
        },
        {
          field: "MENGENABH",
          title: "Mengenabhängig",
          orderIndex: 4,
          minWidth: 100,
          [KEY_REMOVE_FROM_FILTERING]: true,
        },
        {
          field: "BEZ",
          title: "Bezeichnung",
          orderIndex: 5,
          minWidth: 100,
        },
        {
          field: "EH",
          title: "Einheit",
          orderIndex: 6,
          minWidth: 100,
        },
        {
          field: "FAVORIT",
          title: "Favorit",
          orderIndex: 7,
          minWidth: 100,
          [KEY_REMOVE_FROM_FILTERING]: true,
        },
        {
          field: "SHOWIF",
          title: "Ausblenden",
          show: false,
          orderIndex: 8,
          minWidth: 100,
        },
        {
          field: "SUMMEBILDEN",
          title: "Summe bilden",
          orderIndex: 9,
          minWidth: 100,
        },
        {
          field: "REQUIRED",
          title: "Erforderlich",
          orderIndex: 10,
          minWidth: 100,
          [KEY_REMOVE_FROM_FILTERING]: true,
        },
        {
          field: "ITEMID",
          title: "Parameter-ID",
          show: false,
          orderIndex: 11,
          minWidth: 100,
        },
        {
          field: "Funktion",
          title: "Funktion",
          show: false,
          orderIndex: 12,
          width: 100,
        },
        {
          field: "Produkt",
          title: "Produktgruppe",
          show: false,
          orderIndex: 13,
          width: 100,
        },
        {
          field: "PIKTID",
          title: "Piktogramm-ID",
          show: false,
          orderIndex: 14,
          width: 100,
        },
      ] as GridColumnPropsCustom[];

      newPersistedColumns.columns.push({
        field: "Zahl",
        title: "Zahl",
        orderIndex: 18,
        show: false,
        minWidth: 100,
        children: [
          {
            field: "NUMNK",
            title: "Nachkommastellen",
            orderIndex: 19,
            minWidth: 100,
          },
          {
            field: "NUMMIN",
            title: "Minimalwert",
            orderIndex: 20,
            minWidth: 100,
          },
          {
            field: "NUMMAX",
            title: "Maximalwert",
            orderIndex: 21,
            minWidth: 100,
          },
          {
            field: "FORMEL",
            title: "Rechenansatz",
            orderIndex: 22,
          },
          {
            field: "AWList_Num",
            title: "Auswahlliste bei Typ Zahl",
            orderIndex: 23,
            width: 100,
          },
        ] as GridColumnPropsCustom[],
      });

      newPersistedColumns.columns.push({
        field: "Text",
        title: "Text",
        show: false,
        orderIndex: 24,
        children: [
          {
            field: "AWList_Text",
            title: "Auswahlliste bei Typ Text einzeilig",
            orderIndex: 25,
            width: 100,
          },
        ],
      });

      newPersistedColumns.columns.push({
        field: "Sonstige",
        title: "Sonstige",
        show: false,
        orderIndex: 26,
        width: 100,
        children: [
          {
            field: "AWUSEREN",
            title: "Benutzereingabe für Auswahlliste",
            orderIndex: 27,
            width: 100,
          },
        ],
      });

      return newPersistedColumns;
    }

    if (version === 2) {
      const newPersistedColumns = persistedColumns as PersistedColumns;

      /*
          Benutze `hasInternalValue`, um das Default-Browser-Format für die
          Zahlen anzuzeigen
        */
      const columnZahl = newPersistedColumns.columns[1];
      const childrenZahl = columnZahl.children as GridColumnPropsCustom[];

      const columnNUMMIN = childrenZahl[1];
      columnNUMMIN.hasInternalValue = true;

      const columnNUMMAX = childrenZahl[2];
      columnNUMMAX.hasInternalValue = true;

      // Füge den Key hinzu, um einen Bug mit dem Filtering zu beheben
      const columnSonstiges = newPersistedColumns.columns[3];
      const columnBenutzereingabe = (
        columnSonstiges.children as GridColumnPropsCustom[]
      )[0];
      columnBenutzereingabe[KEY_REMOVE_FROM_FILTERING] = true;

      return newPersistedColumns as PersistedColumns;
    }

    if (version === 3) {
      const newPersistedColumns = persistedColumns as PersistedColumns;

      const columnZahl = newPersistedColumns.columns[1];
      const columnNUMNK = (columnZahl.children as GridColumnPropsCustom[])[0];
      // Wurde in Version 4 hinzugefügt
      columnNUMNK.hasInternalValue = true;

      return newPersistedColumns as PersistedColumns;
    }

    return persistedColumns as PersistedColumns;
  };

  return { columns, version, migrate };
}
