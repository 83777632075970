import { Loader } from "@progress/kendo-react-indicators";
import useGetJobsOfContainer from "../../../../core/queries/jobs/useGetJobsOfContainer";
import JobGrid from "../../../../core/components/organisms/JobGrid";

type Props = { dbName: string };
const DbProtocolPage = ({ dbName }: Props) => {
  const { data, isLoading } = useGetJobsOfContainer(dbName, "_main");

  if (isLoading || !data) return <Loader />;

  return <JobGrid jobs={data} gridUniqueId={`jobs-${dbName}`} />;
};

export default DbProtocolPage;
