import {
  CONTID_PLACEHOLDER,
  DATABASE_PLACEHOLDER,
} from "../../../../constants/urls";

export default function createLinkURL(
  breadcrumbPath: string | undefined,
  database: string | undefined,
  contId?: string
) {
  const linkDefaultURL = "#";
  if (!breadcrumbPath) return linkDefaultURL;

  let linkURL = breadcrumbPath;

  linkURL = replaceDatabasePlaceholderInURL(linkURL, database);

  if (!linkURL.includes(CONTID_PLACEHOLDER)) return linkURL;

  if (!contId) return linkDefaultURL;

  return linkURL.replace(CONTID_PLACEHOLDER, contId);
}

function replaceDatabasePlaceholderInURL(
  url: string | undefined,
  database: string | undefined
) {
  if (!url) return "";

  if (!url.includes(DATABASE_PLACEHOLDER)) return url;

  if (!database) database = "_default_";
  return url.replace(DATABASE_PLACEHOLDER, database);
}
