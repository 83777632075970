import ABKExpansionPanel from "../../../../../../../abk-shared/components/atoms/ABKExpansionPanel";

const ZuordnungFunktionenPanel = () => {
  return (
    <ABKExpansionPanel title="Zuordnung Funktionen">
      Zuordnung Funktionen
    </ABKExpansionPanel>
  );
};

export default ZuordnungFunktionenPanel;
