import { GenericObject } from "../../../../../interfaces/GenericObject";
import { SelectedState } from "./gridRowSelectionInterfaces";

const getSelectedItems = (
  items: GenericObject[],
  selectedState: SelectedState,
  idGetter: Function
) =>
  items.filter((container) =>
    isItemSelected(container, selectedState, idGetter)
  );

const isItemSelected = (
  item: GenericObject,
  selectedState: SelectedState,
  idGetter: Function
) => selectedState[idGetter(item)];

export default getSelectedItems;
