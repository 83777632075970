import { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import useIsMobileTemplate from "../../../../../../abk-shared/utility/useIsMobileTemplate";
import PiktogrammDetailsPanel from "./PiktogrammDetailsPanel";
import { useCreatePiktogramm } from "../useCreatePiktogramm";
import { defaultPiktogramm, PiktogrammItem } from "../piktogramm.types";
import ABKConfirmationDialog from "src/abk-shared/components/molecules/ABKConfirmationDialog";
import RawSaveBalken from "src/abk-shared/components/molecules/ABKSaveBalken/RawSaveBalken";
import ABKWindow from "src/abk-shared/components/molecules/ABKWindow";
import ValidationErrorStoreProvider from "src/abk-shared/stores/ValidationErrorStoreProvider";
import { isPiktogrammItemValid } from "../isPiktogrammItemValid";

type Props = {
  db: string;
  contId: string;
  plID: string;
  closeDialog: () => void;
};

export const NewPiktogrammDialog = ({
  db,
  contId,
  plID,
  closeDialog,
}: Props) => {
  const [confirmationDialog, setConfirmationDialog] = useState<boolean>(false);
  const [newPiktogramm, setNewPiktogramm] =
    useState<PiktogrammItem>(defaultPiktogramm);

  const mutationCreatePiktogramm = useCreatePiktogramm(db, contId, plID);

  const isMobile = useIsMobileTemplate();

  const browserWindowHeight = isMobile
    ? window.innerHeight
    : window.innerHeight * 0.7;

  const onCancelConfirmationDialog = () => {
    setConfirmationDialog(false);
  };

  const onConfirmClosingNewItemDialog = () => {
    setConfirmationDialog(false);
    closeDialog();
  };

  return (
    <ValidationErrorStoreProvider>
      <div className="modal-overlay">
        {confirmationDialog && (
          <ABKConfirmationDialog
            message="Sind sie sicher? Ihre Eingaben gehen dabei verloren."
            onClose={onCancelConfirmationDialog}
            onConfirm={onConfirmClosingNewItemDialog}
          />
        )}

        <ABKWindow
          title={`Piktogramm hinzufügen`}
          initialHeight={browserWindowHeight}
          onClose={() => setConfirmationDialog(true)}
        >
          <div className="wrapper-panels-and-balken">
            <div className="panels">
              <PiktogrammDetailsPanel
                piktogramm={newPiktogramm}
                setPiktogramm={setNewPiktogramm}
              />
            </div>
            <div className="save-balken-container">
              <RawSaveBalken
                inline={true}
                balkenMessage="Ihr Piktogramm wurde noch nicht gespeichert."
              >
                <Button
                  themeColor="primary"
                  onClick={async () => {
                    mutationCreatePiktogramm.mutate(newPiktogramm, {
                      onSettled: () => closeDialog(),
                    });
                  }}
                  disabled={!isPiktogrammItemValid(newPiktogramm)}
                >
                  Erstellen
                </Button>
                <Button
                  themeColor="primary"
                  onClick={() => setConfirmationDialog(true)}
                >
                  Verwerfen
                </Button>
              </RawSaveBalken>
            </div>
          </div>
        </ABKWindow>
      </div>
    </ValidationErrorStoreProvider>
  );
};
