import { getter } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
  Grid,
  GridCustomCellProps,
  GridHandle,
} from "@progress/kendo-react-grid";
import { loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import { debounce } from "lodash";
import React from "react";
import { ABKBasisGridProps } from ".";
import ABKBasisGridContextMenu from "./components/ABKBasisGridContextMenu";
import ABKBasisGridToolbar from "./components/ABKBasisGridToolbar";
import CustomCell from "./components/CustomCell";
import HeaderCustomCell from "./components/HeaderCustomCell";
import LoadingPanel from "./components/LoadingPanel";
import createColumns from "./createColumns";
import useExcelExport from "./hooks/useExcelExport";
import useGridColumns from "./hooks/useGridColumns/useGridColumns";
import useGridContextMenu from "./hooks/useGridContextMenu";
import useGridDataState from "./hooks/useGridDataState/useGridDataState";
import useGridRowSelection from "./hooks/useGridRowSelection/useGridRowSelection";
// Diese Datei war importiert von: https://github.com/telerik/kendo-react-messages/blob/master/messages/de-DE/de-DE.json
import germanMessages from "./de-DE.json";
import { OnRefChangeType } from "react-resize-detector/build/types/types";
import VirtualizedMobileGridRows from "./components/VirtualizedMobileGridRows";
import MessageNoRecords from "./components/MessageNoRecords";
import GroupHeaderCustomCell from "./components/HeaderCustomCell/GroupHeaderCustomCell";
import { isContextMenuOnGroupedColumnHeader } from "./utils/groupingFunctions";
import useFieldsWithInternalValue from "./hooks/useFieldsWithInternalValue";
import { DragAndDrop } from "@progress/kendo-react-common";
import { DraggableRow } from "./components/rowsReordering/DraggableRow";
import { DragHint } from "./components/rowsReordering/DragHint";
import useGridReorderRows from "./hooks/useGridReorderRows";
import BasisGridContextProvider from "./BasisGridContextProvider";
import useSetInitialScrollTop from "./hooks/useSetInitialScrollTop";
import { GRID_ROW_HEIGHT } from "./constants";
import { setScrollTopLocalStorage } from "./hooks/useSetInitialScrollTop/scrollTopLocalStorage";
import { FIELD_SELECTED } from "./hooks/useGridColumns/gridActionColumns";
import CheckboxCell from "./hooks/useGridColumns/gridActionColumns/CheckboxCell";

// Inspired from: https://www.telerik.com/kendo-react-ui/components/grid/globalization/
const language = "de-DE";
loadMessages(germanMessages, language);

type Props = ABKBasisGridProps & {
  isMobileGrid: boolean;
  wrapperGridRef: OnRefChangeType<HTMLDivElement>;
  isGroupable?: boolean;
  sortable?: boolean;
};

export default function BasisGrid({
  data,
  columnsDefinition,
  persistedDataStateId,
  dataItemKey,
  mobileGrid,
  gridActions,
  onSelectionChange,
  isLoading,
  customCells,
  wrapperGridRef,
  isMobileGrid,
  groupable = true,
  sortable = true,
  reorderRowsConfig,
  initialization,
}: Props) {
  const gridRef = React.useRef<GridHandle>(null);
  useSetInitialScrollTop(gridRef, isMobileGrid, persistedDataStateId.unique);

  const idGetter = React.useMemo(() => getter(dataItemKey), [dataItemKey]);
  const gridRowSelection = useGridRowSelection(
    data,
    dataItemKey,
    idGetter,
    onSelectionChange,
    initialization?.selectedItems
  );

  const columns = columnsDefinition.columns;

  const gridDataState = useGridDataState(
    data,
    columns,
    persistedDataStateId.sector,
    gridRowSelection.updateSelectedStateAfterFilter
  );

  const isSelectionEnabled = gridActions !== undefined;
  const isReorderingEnabled = reorderRowsConfig !== undefined;
  const gridColumns = useGridColumns(
    persistedDataStateId.sector,
    columnsDefinition,
    wrapperGridRef,
    gridDataState.dataState,
    isSelectionEnabled,
    isMobileGrid,
    isReorderingEnabled
  );

  const headerSelectionValue = gridRowSelection.checkHeaderSelectionValue(
    gridDataState.dataResult.total
  );
  if (isSelectionEnabled) {
    const columnWithCheckbox = gridColumns.columnsDisplayed[0];
    columnWithCheckbox.headerSelectionValue = headerSelectionValue;
  }

  const gridContextMenu = useGridContextMenu(
    gridRowSelection.setDataItemSelected
  );

  const dataResultWithSelectedField =
    gridRowSelection.addSelectedFieldToDataResult(gridDataState.dataResult);

  const gridReorderRows = useGridReorderRows(
    reorderRowsConfig,
    isReorderingEnabled,
    gridRowSelection.selectedItems,
    dataResultWithSelectedField.data,
    dataItemKey
  );

  const excelExport = useExcelExport(
    gridColumns.columnsDisplayed,
    dataResultWithSelectedField.data,
    gridDataState.dataState
  );

  const fieldsWithInternalValue = useFieldsWithInternalValue(columns);

  return (
    <BasisGridContextProvider
      gridRef={gridRef}
      gridReorderRows={gridReorderRows}
      gridRowSelection={gridRowSelection}
      dataState={gridDataState.dataState}
      dataResultWithSelectedField={dataResultWithSelectedField.data}
      gridActions={gridActions}
    >
      <DragAndDrop>
        {isLoading && <LoadingPanel />}
        <ABKBasisGridToolbar
          filterValue={gridDataState.filterValue}
          onFilterChange={gridDataState.onFilterChange}
          excelExport={{
            disabled: dataResultWithSelectedField.total === 0,
            excelExport: excelExport.excelExport,
          }}
          isMobileGrid={isMobileGrid}
          headerSelectionValue={headerSelectionValue}
          onHeaderSelectionChange={(event) =>
            gridRowSelection.onHeaderSelectionChange(
              event,
              dataResultWithSelectedField.data
            )
          }
          isSelectionEnabled={isSelectionEnabled}
          columns={gridColumns.columns}
          onColumnToggleVisibility={gridColumns.onColumnToggleVisibility}
        />
        <LocalizationProvider language={language}>
          <ExcelExport
            data={dataResultWithSelectedField.data}
            ref={excelExport.ref}
            group={gridDataState.dataState.group}
          >
            {excelExport.columns}
          </ExcelExport>
          {isMobileGrid && mobileGrid ? (
            dataResultWithSelectedField.total === 0 ? (
              <MessageNoRecords />
            ) : (
              <VirtualizedMobileGridRows
                dataItems={dataResultWithSelectedField.data}
                mobileGridProps={mobileGrid}
                filterValue={gridDataState.filterValue}
                setDataItemSelected={gridRowSelection.setDataItemSelected}
                gridUniqueId={persistedDataStateId.unique}
              />
            )
          ) : (
            <Grid
              ref={gridRef}
              className="grid-table"
              data={dataResultWithSelectedField}
              onScroll={(event) => {
                setScrollTopLocalStorage(
                  event.nativeEvent.target,
                  persistedDataStateId.unique,
                  false
                );
              }}
              {...gridDataState.dataState}
              onDataStateChange={gridDataState.onDataStateChange}
              sortable={
                sortable
                  ? {
                      mode: "multiple",
                    }
                  : false
              }
              resizable={true}
              onColumnResize={debounce(gridColumns.onColumnResize, 200)}
              reorderable={true}
              onColumnReorder={gridColumns.onColumnReorder}
              size="small"
              selectable={{
                enabled: !reorderRowsConfig,
                drag: false,
                cell: false,
                mode: "multiple",
              }}
              groupable={groupable}
              onExpandChange={gridDataState.onExpandChange}
              expandField="expanded"
              dataItemKey={dataItemKey}
              selectedField={FIELD_SELECTED}
              onSelectionChange={(event) => {
                gridRowSelection.onSelectionChange(event);
                gridContextMenu.setShow(false);
              }}
              onHeaderSelectionChange={(event) =>
                gridRowSelection.onHeaderSelectionChange(
                  event,
                  dataResultWithSelectedField.data
                )
              }
              onContextMenu={(event) => {
                /*
                  Wir wollen vermeiden, dass ein ContextMenü erscheint, wenn wir
                  auf ein Grouped Column Header klicken.
                  https://ib-data.atlassian.net/browse/ABK9-774
                */
                if (isContextMenuOnGroupedColumnHeader(event)) return;

                gridContextMenu.handleContextMenuOpen(
                  event.syntheticEvent,
                  event.dataItem
                );
              }}
              cells={{
                headerCell: (props) => (
                  <HeaderCustomCell
                    {...props}
                    columns={columns}
                    isColumnMenuActive={gridDataState.isColumnMenuActive}
                  />
                ),
                group: {
                  groupHeader: (props) => (
                    <GroupHeaderCustomCell {...props} columns={columns} />
                  ),
                },
                data: (props: GridCustomCellProps) => (
                  <CustomCell
                    {...props}
                    columns={columns}
                    filterValue={gridDataState.filterValue}
                    customCells={customCells}
                  />
                ),
                select: {
                  data: (props: GridCustomCellProps) => (
                    <CheckboxCell {...props} />
                  ),
                },
              }}
              {...(reorderRowsConfig && {
                rowRender: (row, rowProps) => (
                  <DraggableRow elementProps={row.props} {...rowProps} />
                ),
              })}
              scrollable="virtual"
              rowHeight={GRID_ROW_HEIGHT}
              style={{ height: "auto" }}
              total={gridDataState.dataResult.total}
            >
              {gridColumns.columnsDisplayed.map((parentColumn) =>
                createColumns(
                  "gridColumn",
                  parentColumn,
                  data,
                  fieldsWithInternalValue,
                  gridColumns.setWidth
                )
              )}
            </Grid>
          )}
        </LocalizationProvider>
        <DragHint
          ref={gridReorderRows.hintRef}
          portal={gridRef}
          activeItem={gridReorderRows.activeItem}
          dragHintKey={reorderRowsConfig?.dragHintKey}
        />
        <ABKBasisGridContextMenu gridContextMenu={gridContextMenu} />
      </DragAndDrop>
    </BasisGridContextProvider>
  );
}
