import PLEintraegeGrid from "./PLEintraegeGrid";
import useInitiatePageWithContainerData from "../../useInitiatePageWithContainerData";
import { WrapperEintragBearbeitenDialog } from "./EintragBearbeitenDialog";
import { useEintragBearbeitenState } from "./EintragBearbeitenDialog/useEintragBearbeitenState";
import { WrapperNewItemDialog } from "./Dialoge/NewItemDialog";
import { SaveType } from "./SaveTypeEnum";

type Props = { db: string; contId: string; plID: string };
const EintraegePage = ({ db, contId, plID }: Props) => {
  const {
    currentEintrag,
    openDialog,
    setOpenDialog,
    showNewItemDialog,
    newItemSaveType,
  } = useEintragBearbeitenState();

  useInitiatePageWithContainerData(db, contId);

  return (
    <>
      <PLEintraegeGrid db={db} contId={contId} plID={plID} />
      {openDialog && (
        <WrapperEintragBearbeitenDialog onClose={() => setOpenDialog(false)} />
      )}
      {showNewItemDialog && (
        <WrapperNewItemDialog
          db={db}
          contId={contId}
          plID={plID}
          saveType={newItemSaveType}
          beforeItemId={
            newItemSaveType === SaveType.InsertBefore
              ? currentEintrag?.ITEMID
              : undefined
          }
        />
      )}
    </>
  );
};

export default EintraegePage;
