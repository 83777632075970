import { SVGIcon } from "@progress/kendo-svg-icons";
import { MAIN_PAGE_TEMPLATE } from "../../../core/stores/useMainPageTemplateState";
import { SvgIcon } from "@progress/kendo-react-common";
import { NavigationItem } from "../../../abk-shared/interfaces/NavigationItem";

export default function createNavigationItem(
  id: string,
  text: string,
  title: string,
  svgIcon: SVGIcon,
  url: string,
  shouldOverrideBreadcrumb = false,
  description = MAIN_PAGE_TEMPLATE.DESCRIPTION
): NavigationItem {
  const breadcrumbProperties = {
    icon: <SvgIcon icon={svgIcon} style={{ marginRight: 5 }} />,
    path: url,
    shouldOverrideBreadcrumb,
  };

  const drawerProperties = {
    svgIcon: svgIcon,
    route: url,
  };

  return {
    id: id,
    text: text,
    title: title,
    description: description,
    ...breadcrumbProperties,
    ...drawerProperties,
  };
}
