import AbkAppBar from "../organisms/AbkAppBar";
import "./style.css";
import { useEffect, useState } from "react";
import useNotificationStore, {
  ErrorNotification,
} from "../../stores/useNotificationStore";
import { useQuickInfoState } from "../../../abk-shared/stores/useQuickInfoState";
import { INotification } from "../../../abk-shared/components/organisms/ABKSideBar/NotificationBox";
import { DrawerSelectEvent } from "@progress/kendo-react-layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import useDrawerStore from "../../stores/useDrawerStore";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../db/db";
import { getFileInfoUrlForContainer } from "../../utility/cont";
import { formatDateTimeToBrowserDefault } from "../../../abk-shared/utility/dateUtils";
import useJobNotifications from "./MainPageTemplate/useJobNotifications";
import useScrollableHeader from "./MainPageTemplate/useScrollableHeader";
import { WrapperTemplateProps } from "./WrapperTemplate";
import { useQueryClient } from "@tanstack/react-query";
import MobileStructure from "./MobileStructure";
import DesktopStructure from "./DesktopStructure";
import getQuickInfoForNotification from "./MainPageTemplate/generateSidebarElements/getQuickInfoForNotification";
import useJobDialogStore from "../molecules/ABKConfirmationDialogForJob/useJobDialogStore";
import ABKConfirmationDialogForJob from "../molecules/ABKConfirmationDialogForJob";
import { Button } from "@progress/kendo-react-buttons";

const WebsiteStructure = ({
  children,
  includeTitle,
  includeDescription,
  appBarIncludesDropDown = true,
  isDesktop = false,
}: WrapperTemplateProps) => {
  const params = useParams();
  const database = params.db;

  const {
    errors,
    infos,
    warnings,
    removeNotification,
    clearAll,
    clearErrors,
    clearInfos,
    clearWarnings,
  } = useNotificationStore();

  const { setQuickInfo } = useQuickInfoState();

  const jobNotifications = useJobNotifications();
  const { isDialogOpen } = useJobDialogStore();

  const drafts = useLiveQuery(async () => {
    if (!database) {
      return [];
    } else {
      return db.containerInfoDrafts
        .where("database")
        .equals(database)
        .toArray();
    }
  }, [database]);

  const [draftNotifications, setDraftNotifications] = useState<INotification[]>(
    []
  );

  useEffect(() => {
    if (drafts) {
      setDraftNotifications(
        drafts.map(
          (draft) =>
            ({
              message: (
                <span className="test-fileinfo-draft-notification">
                  Ungespeicherte Eingabe in Ordner Datei-Info (
                  <Link
                    to={getFileInfoUrlForContainer(
                      draft.database,
                      draft.contId
                    )}
                  >
                    {draft.edited.BEZ}
                  </Link>
                  )
                </span>
              ),
              type: "info",
              quickInfo: [
                {
                  title: "Entwurf",
                  content:
                    "Ungespeicherte Eingabe in Ordner Datei-Info (" +
                    draft.edited.BEZ +
                    ")",
                },
                {
                  title: "Letzte Änderung",
                  content: formatDateTimeToBrowserDefault(
                    new Date(draft.latestChange ?? 0)
                  ),
                },
                { title: "Type", content: "INFO" },
              ],
              notificationButton: (
                <Button
                  themeColor="primary"
                  onClick={() => {
                    const url = getFileInfoUrlForContainer(
                      draft.database,
                      draft.contId
                    );
                    navigate(url);
                  }}
                >
                  Öffnen
                </Button>
              ),
              closable: false,
            }) as INotification
        )
      );
    } else {
      setDraftNotifications([]);
    }
  }, [drafts]);

  const { headerRef, scrollableContainerRef } = useScrollableHeader();
  const { currentDrawerItems, toggleDrawer } = useDrawerStore();

  const navigate = useNavigate();

  const onDrawerItemSelect = (e: DrawerSelectEvent) => {
    if (e.itemTarget.props.text === "") {
      toggleDrawer();
    } else {
      navigate(e.itemTarget.props.route);
    }
  };

  const onNotificationClick = async (
    notification: INotification | ErrorNotification
  ) => {
    const { quickInfoContent, quickInfoClipboard } =
      await getQuickInfoForNotification(notification, queryClient);
    setQuickInfo(quickInfoContent, notification, quickInfoClipboard);
  };

  const queryClient = useQueryClient();

  const notifications = [
    ...infos,
    ...draftNotifications,
    ...warnings,
    ...errors,
    ...jobNotifications,
  ];

  const infoHubOptions = <p>test</p>;

  const desktopAndMobileProps = {
    removeNotification,
    onCloseNotification: removeNotification,
    setQuickInfo,
    clearAll,
    clearWarnings,
    clearErrors,
    clearInfos,
    queryClient,
    isDesktop,
    headerRef,
    infoHubOptions,
    includeTitle,
    includeDescription,
    scrollableContainerRef,
    currentDrawerItems,
    onDrawerItemSelect,
    onNotificationClick,
    notifications,
    children,
  };

  return (
    <>
      <AbkAppBar isDesktop={isDesktop} isDropDown={appBarIncludesDropDown} />
      {isDesktop ? (
        <DesktopStructure {...desktopAndMobileProps} />
      ) : (
        <MobileStructure {...desktopAndMobileProps} />
      )}
      {isDialogOpen && <ABKConfirmationDialogForJob />}
    </>
  );
};

export default WebsiteStructure;
