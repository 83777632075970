import "./style.scss";

type Props = { children: React.ReactNode; isHalfRow?: boolean };

export default function ABKFormRow({ children, isHalfRow = false }: Props) {
  const className = isHalfRow ? "abk-form-half-row" : "abk-form-row";

  return (
    <div
      className={"abk-form-row-common " + className}
      onKeyDown={(e) => e.stopPropagation()}
    >
      {children}
    </div>
  );
}
