import React from "react";
import useParameterlistenActionHandlers from "./useParameterlistenActionHandlers";
import ABKBasisGridCellLink from "../../../../../../../abk-shared/components/organisms/ABKBasisGrid/components/ABKBasisGridCellLink";
import { PropsCustomCell } from "../../../../../../../abk-shared/components/organisms/ABKBasisGrid/components/CustomCell";
import { CreateGridActionsFunction } from "../../../../../../../abk-shared/components/organisms/ABKBasisGrid/interfaces/GridAction";
import createParameterlisteURL from "../../createParameterlisteURL";
import createColumnsDefinitionForParameterlisten from "./createColumnsDefinitionForParameterlisten";
import { GridRowSelection } from "../../../../../../../abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelectionInterfaces";
import { Parameterliste } from "../../../../../interfaces/parameterlisten";
import { useNavigate } from "react-router-dom";

const useParameterlisten = (
  dbName: string,
  contId: string,
  isFetching: boolean
) => {
  const navigate = useNavigate();
  const columnsDefinition = React.useMemo(
    createColumnsDefinitionForParameterlisten,
    []
  );

  const customCells = React.useMemo(
    () => [
      {
        columnField: "Kenndaten.BEZ",
        render: (props: PropsCustomCell) => (
          <ABKBasisGridCellLink
            {...props}
            text={props.dataItem.Kenndaten.BEZ}
            to={createParameterlisteURL(
              props.dataItem.DATAID,
              props.dataItem.DBNAME,
              props.dataItem.CONTID
            )}
            testProps={{ dataTestid: "link-to-parameterliste" }}
          />
        ),
      },
      {
        columnField: "Kenndaten.VERSIONSNR",
        render: (props: PropsCustomCell) => (
          <td {...props.tdProps}>{props.dataItem.Kenndaten.VERSIONSNR}</td>
        ),
      },
    ],
    []
  );

  const { parameterlistenActionHandlers, isGridLoading } =
    useParameterlistenActionHandlers(dbName, contId, isFetching);

  const gridActions: CreateGridActionsFunction = React.useCallback(
    (gridRowSelection: GridRowSelection) => {
      const selectedItems = gridRowSelection.selectedItems as Parameterliste[];
      const numberOfSelectedItems = selectedItems.length;

      const defaultActions = [
        {
          icon: "Navigation.Open",
          text: "Öffnen",
          onClick: (event: any) =>
            parameterlistenActionHandlers.open(event, selectedItems),
        },
        {
          icon: "Grid.InsertEnd",
          text: "Hinzufügen am Tabellenende",
          onClick: async () => {
            await parameterlistenActionHandlers.createAtEnd();
          },
        },
        {
          icon: "Grid.InsertBefore",
          text: "Hinzufügen vor aktueller Zeile",
          onClick: async () => {
            await parameterlistenActionHandlers.createBeforeRow(
              selectedItems[0]
            );
          },
        },
        {
          icon: "Edit.Delete",
          text: "Löschen",
          onClick: (event: any) =>
            parameterlistenActionHandlers.delete(event, selectedItems),
        },
        {
          icon: "Grid.Duplicate",
          text: "Duplizieren",
          onClick: async () => {
            const itemToBeDuplicated = selectedItems[0];
            const duplicatedItem =
              await parameterlistenActionHandlers.duplicate(selectedItems[0]);

            const hasError = !duplicatedItem;
            if (hasError) return;

            navigate(`${duplicatedItem.DATAID}`);
          },
        },
        {
          icon: "General.Import",
          text: "Importieren",
          onClick: () => alert("Importieren"),
        },
      ];

      if (numberOfSelectedItems === 1) return defaultActions;

      if (numberOfSelectedItems > 1) {
        const filterTexts = ["Öffnen", "Duplizieren"];
        const filteredActions = defaultActions.filter(
          (action) => !filterTexts.includes(action.text)
        );
        return [
          <>{`${numberOfSelectedItems} ausgewählt`}</>,
          ...filteredActions,
        ];
      }

      return defaultActions.filter(
        (action) =>
          action.text.includes("Importieren") ||
          action.text.includes("Hinzufügen am Tabellenende")
      );
    },
    [parameterlistenActionHandlers]
  );

  return { columnsDefinition, customCells, gridActions, isGridLoading };
};

export default useParameterlisten;
