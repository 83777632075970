import PiktogrammePage from "./PiktogrammePage";
import { useIsValidDbAndContIdInURL } from "../../../../../core/utility/useIsValidDB";
import { useParams } from "react-router-dom";

const Piktogramme = () => {
  const { isValid, db, contId } = useIsValidDbAndContIdInURL();
  const { plID } = useParams();

  if (!isValid) return <></>;

  return (
    <PiktogrammePage
      key={`pl-${db}-${contId}-${plID}`}
      db={db as string}
      contId={contId as string}
      plID={plID as string}
    />
  );
};

export default Piktogramme;
