import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate } from "react-router-dom";
import { NavigationItem } from "../../../../../../abk-shared/interfaces/NavigationItem";
import { itemRender, valueRender } from "./renderFunctions";
import "./style.scss";

type Props = {
  navigationItems: NavigationItem[];
  currentItemId: string;
  keyForItemId: keyof NavigationItem;
};

export default function BreadcrumbDropDown({
  navigationItems,
  currentItemId,
  keyForItemId,
}: Props) {
  const navigate = useNavigate();

  return (
    <DropDownList
      ariaLabel="Menü-Item auswählen"
      data={navigationItems}
      itemRender={itemRender}
      defaultValue={navigationItems.find(
        (item) => item[keyForItemId] === currentItemId
      )}
      value={navigationItems.find(
        (item) => item[keyForItemId] === currentItemId
      )}
      valueRender={valueRender}
      textField="text"
      dataItemKey="text"
      onChange={(event) => {
        const item = event.value;
        navigate(item.route);
      }}
      fillMode="flat"
      popupSettings={{ width: "auto" }}
    />
  );
}
