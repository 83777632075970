import { PropsCustomCell } from "../../../../../../abk-shared/components/organisms/ABKBasisGrid/components/CustomCell";
import { GridRowSelection } from "../../../../../../abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelectionInterfaces";
import {
  AWList_Text,
  EintragFrontend,
} from "../../../../interfaces/parameterlisten";
import BezeichnungCell from "../../WeitereParameterlisten/Parameterlisten/BezeichnungCell";
import BezeichnungCellLink from "../../WeitereParameterlisten/Parameterlisten/BezeichnungCellLink";
import BoldText from "./BoldText";
import GridCheckBox from "./GridCheckbox";
import { EintraegeActionHandlers } from "./useEintraegeActionHandlers";

const createEintraegeCustomCells = (
  eintraegeActionHandlers: EintraegeActionHandlers
) => [
  {
    columnField: "REQUIRED",
    render: (props: PropsCustomCell) => (
      <GridCheckBox
        tdProps={props.tdProps}
        booleanValue={props.dataItem.REQUIRED}
      />
    ),
  },
  {
    columnField: "FAVORIT",
    render: (props: PropsCustomCell) => (
      <GridCheckBox
        tdProps={props.tdProps}
        booleanValue={props.dataItem.FAVORIT}
      />
    ),
  },
  {
    columnField: "MENGENABH",
    render: (props: PropsCustomCell) => (
      <GridCheckBox
        tdProps={props.tdProps}
        booleanValue={props.dataItem.MENGENABH}
      />
    ),
  },
  {
    columnField: "BEZ",
    render: (props: PropsCustomCell) => (
      // Option 1:
      <BezeichnungCell
        tdProps={props.tdProps}
        eintrag={props.dataItem}
        eintraegeActionHandlers={eintraegeActionHandlers}
      />

      // Option 2 (still need to be decided yet -> don't delete):
      // <BezeichnungCellLink
      //   {...props}
      //   tdProps={props.tdProps}
      //   eintrag={props.dataItem}
      //   eintraegeActionHandlers={eintraegeActionHandlers}
      // />
    ),
  },
  {
    columnField: "ITEMTYPE",
    render: (props: PropsCustomCell) => (
      <BoldText text={props.dataItem.ITEMTYPE} props={props} />
    ),
  },
  {
    columnField: "VARNAME",
    render: (props: PropsCustomCell) => (
      <BoldText text={props.dataItem.VARNAME} props={props} />
    ),
  },
  {
    columnField: "EH",
    render: (props: PropsCustomCell) => (
      <BoldText text={props.dataItem.EH} props={props} />
    ),
  },
  {
    columnField: "SUMMEBILDEN",
    render: (props: PropsCustomCell) => (
      <GridCheckBox
        tdProps={props.tdProps}
        booleanValue={props.dataItem.SUMMEBILDEN}
      />
    ),
  },
  {
    columnField: "SHOWIF",
    render: (props: PropsCustomCell) => (
      <BoldText text={props.dataItem.SHOWIF} props={props} />
    ),
  },
  {
    columnField: "ITEMID",
    render: (props: PropsCustomCell) => (
      <BoldText text={props.dataItem.ITEMID} props={props} />
    ),
  },
  {
    columnField: "Funktion",
    render: (props: PropsCustomCell) => (
      <BoldText
        text={
          Array.isArray(props.dataItem.Funktion)
            ? props.dataItem.Funktion.map((f: string) => f).join(", ")
            : props.dataItem.Funktion
        }
        props={props}
      />
    ),
  },
  {
    columnField: "Produkt",
    render: (props: PropsCustomCell) => (
      <BoldText
        text={
          Array.isArray(props.dataItem.Produkt)
            ? props.dataItem.Produkt.map((f: string) => f).join(", ")
            : props.dataItem.Produkt
        }
        props={props}
      />
    ),
  },
  {
    columnField: "AWList_Num",
    render: (props: PropsCustomCell) => (
      <BoldText
        text={
          props.dataItem.AWList_Num &&
          props.dataItem.AWList_Num.map((n: number) => n).join("; ")
        }
        props={props}
      />
    ),
  },
  {
    columnField: "AWList_Text",
    render: (props: PropsCustomCell) => (
      <BoldText
        text={
          props.dataItem.AWList_Text &&
          props.dataItem.AWList_Text.map(
            (t: AWList_Text) =>
              `${t.Eintrag} ${t.Bezeichnung ? `(${t.Bezeichnung})` : ""}`
          ).join("; ")
        }
        props={props}
      />
    ),
  },
  {
    columnField: "FORMEL",
    render: (props: PropsCustomCell) => (
      <BoldText text={props.dataItem.FORMEL} props={props} />
    ),
  },
  {
    columnField: "AWUSEREN",
    render: (props: PropsCustomCell) => (
      <GridCheckBox
        tdProps={props.tdProps}
        booleanValue={props.dataItem.AWUSEREN}
      />
    ),
  },
];
export default createEintraegeCustomCells;
