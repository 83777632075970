import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import ABKImageUploadButton from "src/abk-shared/components/atoms/ABKImageUploadButton";
import ABKInfoPopup from "src/abk-shared/components/atoms/ABKInfoPopup";
import ABKLabel from "src/abk-shared/components/atoms/ABKLabel";
import { AbkIconsMap } from "src/abk-shared/interfaces/abk9SvgIcons";
import downloadFile from "src/core/utility/downloadFile";
import LogoDisplayer from "src/core/components/atoms/LogoDisplayer";
import {
  compressFileToLessThan64kB,
  generateBase64Image,
  getLogoFormat,
  LogoFormat,
  toBase64,
} from "src/core/utility/imageProcessing";

type Props = {
  fileId: string;
  base64Image: string;
  imageFormat: LogoFormat.SVG;
  setBase64Image: (base64: string) => void;
  disabled: boolean;
};

const messageAvailableFormats = "Erlaubte Dateiformate: SVG";

export function UploadLogoPiktogramm({
  fileId,
  imageFormat,
  base64Image,
  setBase64Image,
  disabled,
}: Props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setErrorMessage("");
    setIsLoading(true);
    const inputFile = event.target.files?.[0];
    const userCanceledFileSelection = !inputFile;
    if (userCanceledFileSelection) {
      setIsLoading(false);
      return;
    }

    const type = inputFile.type;
    const logoFormat = getLogoFormat(type);
    if (logoFormat !== LogoFormat.SVG) {
      setErrorMessage(`Dateiformat nicht erlaubt. ${messageAvailableFormats}`);
      setIsLoading(false);
      return;
    }

    const { compressedFile, isCompressed } =
      await compressFileToLessThan64kB(inputFile);
    const fileBase64 = await toBase64(
      isCompressed ? (compressedFile as File) : inputFile
    );
    // Das Back-end kann nicht das Präfix speichern, weil es die `;` entfernen wird
    const fileBase64WithoutPrefix = (fileBase64 as string).split(";base64,")[1];
    setIsLoading(false);
    setBase64Image(fileBase64WithoutPrefix);
  };

  const tooltip = `${messageAvailableFormats}; Maximale Dateigröße: 64kB. Dateien über 64kB werden automatisch verkleinert.`;

  const { hasImage, imageBase64 } = generateBase64Image(
    base64Image,
    imageFormat
  );

  const downloadImage = React.useCallback(() => {
    downloadFile(imageBase64, fileId);
  }, [imageBase64]);

  return (
    <div>
      <ABKLabel editorId="allgemein-status">
        Logo
        <ABKInfoPopup popupContent={tooltip} />
      </ABKLabel>
      <div className="wrapper-logo-buttons">
        <ABKImageUploadButton
          className="k-d-inline-block"
          onChange={handleFileUpload}
          disabled={disabled}
        />
        <Button
          onClick={() => {
            setBase64Image("");
          }}
          svgIcon={AbkIconsMap.get("Edit.Delete")}
          title="Löschen"
          disabled={disabled || !hasImage}
        />
        <Button
          onClick={downloadImage}
          svgIcon={AbkIconsMap.get("General.Export")}
          title="Herunterladen"
          disabled={disabled || !hasImage}
        />
      </div>
      <div className="wrapper-logo-img">
        <LogoDisplayer
          isLoading={isLoading}
          hasImage={hasImage}
          imageBase64={imageBase64}
          errorMessage={errorMessage}
        />
      </div>
    </div>
  );
}
