import { Outlet } from "react-router-dom";
import useDrawerStore from "../../core/stores/useDrawerStore";
import { useEffect } from "react";
import useAppBarState from "../../core/stores/useAppBarState";
import { changeFavIcon } from "../../core/utility/favIcon";
import WrapperTemplate from "../../core/components/templates/WrapperTemplate";
import { navigationItems } from "../../Router/Layout/navigationItems";

const HilfeLayout = () => {
  const { setAppBarTitle } = useAppBarState();
  const { setCurrentDrawerItems } = useDrawerStore();

  useEffect(() => {
    const drawerItems = [
      navigationItems.appinfo(),
      navigationItems.sysinfo(),
      navigationItems["admin-help"](),
    ];
    setAppBarTitle("Hilfe-Center");
    setCurrentDrawerItems(drawerItems);
    changeFavIcon("/assets/favicons/Menu.Helpcenter.Tabicon.svg");
  }, [setAppBarTitle, setCurrentDrawerItems]);

  return (
    <>
      <WrapperTemplate includeTitle includeDescription>
        <Outlet />
      </WrapperTemplate>
    </>
  );
};

export default HilfeLayout;
