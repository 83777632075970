import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Parameterliste } from "../../../../../interfaces/parameterlisten";
import { QUERY_KEY_PARAMETERLISTE } from "../../../../../queries/useGetSingleParameterliste";

export default function useDuplicateParameterliste(
  dbName: string,
  contId: string
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (selectedParameterliste: Parameterliste) => {
      // requestBody = BE request mit new bezeichnung
      return axios.post(`/api/db/${dbName}/cont/${contId}/pl`, null, {
        headers: { "Content-Type": "application/json" },
        params: {
          copyfromdataid: selectedParameterliste.DATAID,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_PARAMETERLISTE, dbName, contId],
      });
    },
  });
}
