import AbkOenormEditor from "../../../../../../../core/components/organisms/AbkOenormEditor";
import editorTools from "../../../../../../../core/components/organisms/AbkOenormEditor/editorTools";
import CustomViewHtmlHook from "../../../../../../../core/components/organisms/AbkOenormEditor/CustomHTMLDialog/CustomViewHtmlHook";
import { EintragFrontend } from "../../../../../interfaces/parameterlisten";
import ABKExpansionPanel from "../../../../../../../abk-shared/components/atoms/ABKExpansionPanel";
import { PropsEintragPanel } from "./ParTypePanelsMap";

const KommentarPanel = ({ setPageState, pageState }: PropsEintragPanel) => {
  return (
    <ABKExpansionPanel title="Kommentar" key="kommentarPanel">
      <AbkOenormEditor
        tools={[
          ...editorTools.oenormTools,
          [CustomViewHtmlHook],
          ...editorTools.extraTools,
          ...editorTools.oenormTabelleTools,
          ...editorTools.tableTools,
        ]}
        value={pageState?.KOMMENTAR ?? ""}
        setValue={(value: string) => {
          setPageState({ ...pageState, KOMMENTAR: value } as EintragFrontend);
        }}
      />
    </ABKExpansionPanel>
  );
};

export default KommentarPanel;
