import * as React from "react";
import * as ReactDOM from "react-dom";
import { GenericObject } from "../../../../../../interfaces/GenericObject";
import HintNumberOfDraggedItems from "./HintNumberOfDraggedItems";
import {
  GridRef,
  ReorderContext,
  SelectionContext,
} from "../../../hooks/useGridReorderRows/gridReorderRowsContexts";
import ABKNoContent from "../../../../../atoms/ABKNoContent";
import DragAndDropIcon from "../../../hooks/useGridColumns/gridActionColumns/DragAndDropIcon";

/*
  Der Code in dieser Datei wurde vom folgenden Beispiel inspiriert:
  https://www.telerik.com/kendo-react-ui/components/grid/rows/row-reordering/#toc-multi-select-reordering
*/
type Props = {
  portal: GridRef;
  activeItem: GenericObject | null;
  dragHintKey?: string;
};
export const DragHint = React.forwardRef<HTMLDivElement, Props>(
  ({ portal, activeItem, dragHintKey, ...props }, ref) => {
    const { selectedItems } = React.useContext(SelectionContext);
    const { isReorderOnlyOnActiveItem } = React.useContext(ReorderContext);

    const dragHintText = activeItem && dragHintKey && activeItem[dragHintKey];
    const shouldDisplayNumberOfDraggedItems =
      !isReorderOnlyOnActiveItem && selectedItems.length > 1;

    const Component = (
      <div
        ref={ref}
        className="k-card"
        {...props}
        style={{
          position: "absolute",
          pointerEvents: "none",
          userSelect: "none",
          padding: 4,
          overflow: "visible",
          cursor: "move",
          display: activeItem ? undefined : "none",
          // `zIndex` über den `ActionColumns`, wie z.B. `SelectedField` und `DragAndDrop`
          zIndex: 1,
          width: "50%",
          flexDirection: "row",
          alignItems: "center",
          gap: 8,
        }}
      >
        <DragAndDropIcon />
        <span className="k-text-ellipsis">
          {dragHintText ? dragHintText : <ABKNoContent />}
        </span>
        {shouldDisplayNumberOfDraggedItems && (
          <HintNumberOfDraggedItems>
            +{selectedItems.length - 1}
          </HintNumberOfDraggedItems>
        )}
      </div>
    );

    return portal && portal.current && portal.current.element
      ? ReactDOM.createPortal(Component, portal.current.element)
      : Component;
  }
);
