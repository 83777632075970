import { Loader } from "@progress/kendo-react-indicators";
import useGetJobsOfContainer from "../../../../core/queries/jobs/useGetJobsOfContainer";
import JobGrid from "../../../../core/components/organisms/JobGrid";
import useInitiatePageWithContainerData from "../useInitiatePageWithContainerData";

type Props = { db: string; contId: string };
const DateiProtocolPage = ({ db, contId }: Props) => {
  useInitiatePageWithContainerData(db, contId);
  const { data, isLoading } = useGetJobsOfContainer(db, contId);

  if (isLoading || !data) return <Loader />;

  return <JobGrid jobs={data} gridUniqueId={`jobs-${db}-${contId}`} />;
};

export default DateiProtocolPage;
