import { UseMutationResult } from "@tanstack/react-query";
import { MutationAddNewPLItem } from "../../../../../queries/useAddNewPLItem";
import {
  SetterShowNewItemDialog,
  SetterNewItemDialogPromise,
  NewItemDialogResponse,
} from "../../EintragBearbeitenDialog/useEintragBearbeitenState";

export async function showNewItemDialogAndMutateIfSuccess(
  setShowNewItemDialog: SetterShowNewItemDialog,
  setNewItemDialogPromise: SetterNewItemDialogPromise,
  mutationAddNewPLItem: UseMutationResult<
    any,
    Error,
    MutationAddNewPLItem,
    unknown
  >
) {
  setShowNewItemDialog(true);
  const { shouldProceed, data } = await new Promise<NewItemDialogResponse>(
    (resolve) => {
      setNewItemDialogPromise({ resolve });
    }
  );
  if (!shouldProceed) return;

  await mutationAddNewPLItem.mutateAsync(data!);
}
