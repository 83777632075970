import {
  GridColumnMenuCheckboxFilter,
  GridColumnMenuProps,
} from "@progress/kendo-react-grid";
import { GenericObject } from "../../../../../interfaces/GenericObject";

export const ABKColumnMenu = (
  props: GridColumnMenuProps & { data: GenericObject[] }
) => {
  const { data } = props;

  return (
    <div className="test-grid-column-menu">
      <GridColumnMenuCheckboxFilter
        {...props}
        data={data}
        expanded={true}
        searchBox={() => null}
      />
    </div>
  );
};
