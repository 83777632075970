import { create } from "zustand";
import { NavigationItem } from "../../abk-shared/interfaces/NavigationItem";

interface DrawerState {
  drawerOpen: boolean;
  toggleDrawer: () => void;
  currentDrawerItems: NavigationItem[];
  setCurrentDrawerItems: (items: NavigationItem[]) => void;
  drawerWidth: number;
  setDrawerWidth: (width: number) => void;
  increaseDrawerWidth: () => void;
  decreaseDrawerWidth: () => void;
}

const useDrawerStore = create<DrawerState>((set) => {
  const localStorageValue = localStorage.getItem("drawerOpen");
  const defaultDrawerOpen = localStorageValue
    ? JSON.parse(localStorageValue)
    : true;

  const localStorageWidth = localStorage.getItem("drawerWidth");
  const defaultDrawerWidth = localStorageWidth
    ? JSON.parse(localStorageWidth)
    : 250;

  return {
    drawerOpen: defaultDrawerOpen,
    toggleDrawer: () => {
      set((state) => {
        const newDrawerOpen = !state.drawerOpen;
        localStorage.setItem("drawerOpen", JSON.stringify(newDrawerOpen));
        return { drawerOpen: newDrawerOpen };
      });
    },
    currentDrawerItems: [],
    setCurrentDrawerItems: (items: NavigationItem[]) =>
      set(() => ({ currentDrawerItems: items })),
    drawerWidth: defaultDrawerWidth,
    setDrawerWidth: (width: number) => {
      set(() => {
        localStorage.setItem("drawerWidth", JSON.stringify(width));
        return { drawerWidth: width };
      });
    },
    increaseDrawerWidth: () => {
      set((state) => {
        const newWidth = state.drawerWidth + 10;
        localStorage.setItem("drawerWidth", JSON.stringify(newWidth));
        return { drawerWidth: newWidth };
      });
    },
    decreaseDrawerWidth: () => {
      set((state) => {
        const newWidth = state.drawerWidth - 10;
        localStorage.setItem("drawerWidth", JSON.stringify(newWidth));
        return { drawerWidth: newWidth };
      });
    },
  };
});

export default useDrawerStore;
