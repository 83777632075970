import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_PIKTOGRAMME } from "./useGetPiktogramme";
import { BACKEND_URL } from "src/core/constants/urls";
import { QUERY_KEY_PARAMETERLISTE } from "src/modules/Datei/queries/useGetSingleParameterliste";

async function deletePiktogramm(url: string): Promise<void> {
  return axios.delete(url);
}

export const useDeletePiktogramm = (
  dbName: string,
  contId: string,
  plID: string
) => {
  const queryClient = useQueryClient();
  const baseUrl = `${BACKEND_URL}/api/db/${dbName}/cont/${contId}/pl/${plID}/pikto`;
  return useMutation({
    mutationFn: async (piktogrammId: string) => {
      return deletePiktogramm(`${baseUrl}/${piktogrammId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          dbName,
          contId,
          plID,
          QUERY_KEY_PARAMETERLISTE,
          QUERY_KEY_PIKTOGRAMME,
        ],
      });
    },
  });
};
