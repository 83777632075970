import { Button } from "@progress/kendo-react-buttons";
import { EintragFrontend } from "../../../../interfaces/parameterlisten";
import { useEintragBearbeitenState } from "../../Einträge/EintragBearbeitenDialog/useEintragBearbeitenState";
import { GridRowSelection } from "../../../../../../abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelectionInterfaces";
import { EintraegeActionHandlers } from "../../Einträge/useEintraege/useEintraegeActionHandlers";
import { TestProps } from "../../../../../../abk-shared/interfaces/TestProps";
import { AbkIconsMap } from "../../../../../../abk-shared/interfaces/abk9SvgIcons";

type Props = {
  tdProps: any;
  eintrag: EintragFrontend;
  eintraegeActionHandlers: EintraegeActionHandlers;
  testProps?: TestProps;
};
const BezeichnungCell = ({
  tdProps,
  eintrag,
  eintraegeActionHandlers,
  testProps,
}: Props) => {
  const handleOnClickBezeichnung = (event: any) => {
    eintraegeActionHandlers.open(event, [eintrag]);
  };

  return (
    <>
      <td {...tdProps} role="gridcell">
        <Button
          svgIcon={AbkIconsMap.get("Navigation.Open")}
          fillMode="flat"
          data-testid={testProps?.dataTestid}
          onClick={handleOnClickBezeichnung}
        >
          {eintrag.BEZ}
        </Button>
      </td>
    </>
  );
};

export default BezeichnungCell;
