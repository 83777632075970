import {
  Sortable,
  SortableItemUIProps,
  SortableOnDragOverEvent,
} from "@progress/kendo-react-sortable";
import ListItem from "./ListItem";
import { useEffect, useState } from "react";
import _ from "lodash";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Grid_InsertEnd } from "../../../interfaces/abk9SvgIcons";
import ABKLabel from "../../atoms/ABKLabel";
import Columns from "./Columns";
import MobileListItem from "./MobileListItem";

type ABKListViewProps = {
  data: any[];
  setData: (data: any[]) => void;
  objectKeys: string[];
  columnNames?: string[];
  titleText?: string;
  label?: string;
  isMobile?: boolean;
};

const ABKListView = ({
  data,
  setData,
  objectKeys,
  columnNames,
  label,
  isMobile,
}: ABKListViewProps) => {
  const columns = columnNames || objectKeys;

  const [componentData, setComponentData] = useState(() =>
    data.map((item) => ({ ...item, id: _.uniqueId() }))
  );

  useEffect(() => {
    const mappedDate = data.map((item) => ({ ...item, id: _.uniqueId() }));
    setComponentData(mappedDate);
  }, [data]);

  const onDragOver = (event: SortableOnDragOverEvent) => {
    // Es benötigt InitialData, um einen komischen bug zu lösen,
    // wo die ganze Seite für 1s weiß blinkt beim Drag and Drop.
    // Vermutung: Interaktion mit anderen Komponenten
    setComponentData(event.newState);
  };

  const updateData = (newData: any[]) => {
    setData(newData);
    setComponentData(newData);
  };

  const handleAddItem = () => {
    updateData([...data, { id: _.uniqueId() }]);
  };

  if (componentData.length === 0) {
    return (
      <span>
        Noch keine Daten Vorhanden. Neues Element hinzufügen:
        <Button svgIcon={Grid_InsertEnd} onClick={handleAddItem} />
      </span>
    );
  }

  return (
    <>
      {label && <ABKLabel editorId={label}>{label}</ABKLabel>}
      <ButtonGroup>
        <Button svgIcon={Grid_InsertEnd} onClick={handleAddItem} />
      </ButtonGroup>
      <div
        style={{ display: "flex", width: "100%", flexDirection: "column" }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {!isMobile ? (
          <>
            <Columns objectKeys={columns} />
            <Sortable
              idField={"id"}
              data={componentData}
              itemUI={(props: SortableItemUIProps) =>
                ListItem(props, objectKeys, updateData, componentData)
              }
              onDragOver={onDragOver}
              onDragEnd={(event) => {
                setData(event.newState);
              }}
            />
          </>
        ) : (
          <>
            {componentData.map((dataItem) => (
              <MobileListItem
                objectKeys={objectKeys}
                columns={columns}
                dataItem={dataItem}
                key={dataItem.id}
                data={componentData}
                setData={updateData}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default ABKListView;
