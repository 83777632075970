import React from "react";
import { formatDateTimeToBrowserDefault } from "../../abk-shared/utility/dateUtils";
import {
  CONTID_PLACEHOLDER,
  DATABASE_PLACEHOLDER,
  FILE_INFO,
} from "../constants/urls";
import { Cont, ContWithLastChangedModified } from "../interfaces/Cont";
import { DATA_KEY } from "../../abk-shared/components/organisms/ABKBasisGrid/constants";

/**
 * Nimmt ein Container ID und gibt sie in einem URL-freundlichen Format zurück.
 * @param contID - container ID im Normalen Format
 * @returns Eine URL-freundliche Container ID, in Ascii, ohne Sonderzeichen, die eine spezielle Funktion in URLs haben.
 */
export const encodeContID = (contID: string): string => {
  return contID
    .replaceAll("#", "%23")
    .replaceAll(/\\/g, "%5C")
    .replaceAll("?", "%3F")
    .replaceAll("&", "%26")
    .replaceAll("=", "%3D");
};

export const getContTypeFromID = (contID: string): string => {
  // Find the position of the first '#'
  const start = contID.indexOf("#");

  // If the first '#' is not found, return an empty string or handle it as needed
  if (start === -1) return "";

  // Find the position of the next '#' after the first
  const end = contID.indexOf("#", start + 1);

  // If the second '#' is not found, return an empty string or handle it as needed
  if (end === -1) return "";

  // Extract and return the type
  return contID.substring(start + 6, end);
};

export const getFileInfoUrlForContainer = (
  database: string,
  containerId: string
) => replaceDBAndContInURL(FILE_INFO, database, containerId);

export const replaceDBAndContInURL = (
  url: string,
  database: string,
  containerId: string
) =>
  url
    .replace(DATABASE_PLACEHOLDER, database)
    .replace(CONTID_PLACEHOLDER, encodeContID(containerId));

const formatContainersDate = (
  containers: Cont[]
): ContWithLastChangedModified[] =>
  containers.map((container) => ({
    ...container,
    LASTCHANGED: {
      [DATA_KEY.INTERNAL]: new Date(container.LASTCHANGED),
      [DATA_KEY.DISPLAYED]: formatDateTimeToBrowserDefault(
        container.LASTCHANGED
      ),
    },
  }));

export function useContainersWithFormattedDate(containers: Cont[] | undefined) {
  const containersWithFormattedDate = React.useMemo(() => {
    const containersArray = containers ?? [];
    return formatContainersDate(containersArray);
  }, [containers]);

  return containersWithFormattedDate;
}
