import { PropsCustomCell } from "../../../../../../abk-shared/components/organisms/ABKBasisGrid/components/CustomCell";
import HighlightedCell from "../../../../../../abk-shared/components/organisms/ABKBasisGrid/components/CustomCell/HighlightedCell";
import { useEintragBearbeitenState } from "../EintragBearbeitenDialog/useEintragBearbeitenState";
import { parTypeMapper } from "../maps";

type Props = {
  text: string;
  props: PropsCustomCell;
};
export default function BoldText({ text, props }: Props) {
  const isBold = props.dataItem.ITEMTYPE === parTypeMapper.get("Set");
  const isGreen = props.dataItem.ITEMTYPE === parTypeMapper.get("Head");

  const { currentEintrag } = useEintragBearbeitenState();
  const isSelected = props.dataItem.ITEMID === currentEintrag?.ITEMID;

  const isSelectedStyle = {
    color: "#464600",
    fontWeight: "bold",
    fontStyle: "italic",
  };

  return (
    <HighlightedCell {...props}>
      <span
        className="k-text-ellipsis"
        title={text}
        style={{
          fontWeight: isBold || isGreen ? "bold" : "normal",
          color: isGreen ? "#999900" : "black",
        }}
      >
        <span style={isSelected ? isSelectedStyle : {}}>{text}</span>
      </span>
    </HighlightedCell>
  );
}
