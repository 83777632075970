import React from "react";
import { GenericObject } from "../../../../../interfaces/GenericObject";
import {
  FunctionReorderInternal,
  ReorderRowsConfig,
} from "./gridReorderRowsInterfaces";
import useReorderWithArrowKeys from "./useReorderWithArrowKeys";

export default function useGridReorderRows(
  reorderRowsConfig: ReorderRowsConfig | undefined,
  isReorderingEnabled: boolean,
  selectedItems: GenericObject[],
  dataResultWithSelectedField: GenericObject[],
  dataItemKey: string
) {
  const hintRef = React.useRef<HTMLDivElement>(null);

  const [activeItem, setActiveItem] = React.useState<GenericObject | null>(
    null
  );

  const [isReordering, setIsReordering] = React.useState(false);

  const activeItemIsSelected =
    selectedItems.find(
      (item) => item[dataItemKey] === activeItem?.[dataItemKey]
    ) !== undefined;
  const isReorderOnlyOnActiveItem = activeItem != null && !activeItemIsSelected;

  const reorder: FunctionReorderInternal = async (
    anchorItem: GenericObject,
    direction: "before" | "after" | null
  ) => {
    if (activeItem === anchorItem || direction === null) return;

    const itemsToReorder = isReorderOnlyOnActiveItem
      ? [activeItem]
      : selectedItems;

    setActiveItem(null);
    if (!reorderRowsConfig) return Promise.resolve();

    setIsReordering(true);
    try {
      await reorderRowsConfig.reorderRows(
        itemsToReorder,
        anchorItem,
        direction
      );
    } catch {}
    setIsReordering(false);
  };

  const dragStart = (dataItem: GenericObject) => {
    setActiveItem(dataItem);
  };

  const dragEnd = () => {
    setActiveItem(null);
  };

  useReorderWithArrowKeys(
    isReorderingEnabled,
    selectedItems,
    dataResultWithSelectedField,
    dataItemKey,
    isReordering,
    reorder
  );

  return {
    hintRef,
    activeItem,
    isReorderOnlyOnActiveItem,
    reorder,
    dragStart,
    dragEnd,
  };
}
