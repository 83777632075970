import { useEffect } from "react";
import "./DateiInfoPage.css";
import "./DialogFenster";
import useGetContInfo from "../../../Startseite/hooks/useGetContInfo";
import Balken from "../../../../abk-shared/components/molecules/ABKSaveBalken";
import { encodeContID } from "../../../../core/utility/cont";
import { convertHtmlToOenorm } from "../../../../core/components/organisms/AbkOenormEditor/CustomHTMLDialog/CustomViewHtml";
import { Loader } from "@progress/kendo-react-indicators";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { AxiosError, AxiosResponse } from "axios";
import { ContainerInfoDraft, db } from "../../../../core/db/db";
import { Cont } from "../../../../core/interfaces/Cont";
import { Versioned } from "../../../../core/interfaces/Versioned";
import DetailsPanel from "./panels/DetailsPanel";
import NotizPanel from "./panels/NotizPanel";
import BeschreibungPanel from "./panels/BeschreibungPanel";
import AllgemeinesPanel from "./panels/AllgemeinesPanel";
import useIsMobileTemplate from "../../../../abk-shared/utility/useIsMobileTemplate";
import PanelTitle from "./panels/PanelPreview";
import useDrafts from "../../hooks/useDrafts";
import _ from "lodash";
import useInitiatePageWithContainerData from "../useInitiatePageWithContainerData";
import useAppBarState from "../../../../core/stores/useAppBarState";
import { useQuickInfoState } from "../../../../abk-shared/stores/useQuickInfoState";
import { formatDateTimeToBrowserDefault } from "../../../../abk-shared/utility/dateUtils";
import { useSaveContainer } from "./useSaveContainer";

const PADDING_BOTTOM = 25;

export interface CurrentForm {
  contid: string;
  etag: string;
  dateiname: string;
  anmerkung: string;
  beschreibung: string;
  notiz: string;
  lockstate: string;
}

type Props = { database: string; contId: string };
const Allgemein = ({ database, contId }: Props) => {
  const isMobileTemplate = useIsMobileTemplate();
  const heightBalken = isMobileTemplate ? 90 : 55;

  const {
    data: versioned,
    error: contLoadingError,
    refetch,
    isFetching,
  } = useGetContInfo(database, contId);

  const is404 =
    contLoadingError instanceof AxiosError &&
    contLoadingError.response?.status === 404;

  const { mutateAsync } = useSaveContainer(database);

  const saveContainer = (): Promise<AxiosResponse<Cont, any>> => {
    const shouldBeautifyHTML = false;
    const notizConverted = convertHtmlToOenorm(
      currentForm?.value.INTNOTIZ || "",
      shouldBeautifyHTML
    );

    const updatedContainer = {
      BEZ: currentForm?.value.BEZ,
      BESCH: currentForm?.value.BESCH,
      INTNOTIZ: notizConverted,
      Meta: container?.Meta,
      CONTID: container?.CONTID,
      CONTTYPE: container?.CONTTYPE,
      INTANMERK: currentForm?.value.INTANMERK,
      OWNER: container?.OWNER,
      LASTCHANGED: container?.LASTCHANGED,
      LOCKSTATE: currentForm?.value.LOCKSTATE,
      SPECIALFILE: container?.SPECIALFILE,
      GROUPNAME: container?.GROUPNAME,
      DBNAME: container?.DBNAME,
    } as Cont;
    const encodedContID = encodeContID(contId!);
    return mutateAsync({
      contId: encodedContID,
      container: updatedContainer,
      etag: currentForm?.etag,
    });
  };

  const {
    sections,
    updateSections,
    isEdited,
    handleReset,
    handleSave,
    pageState: currentForm,
    updatePageState,
    conflictMessage,
  } = useDrafts<Cont, ContainerInfoDraft, string>({
    is404,
    isFetching,
    initialPageState: versioned,
    initialSections: new Map([
      ["allgemeines", true],
      ["beschreibung", false],
      ["notiz", false],
      ["details", false],
    ]),
    backend: {
      doSave: saveContainer,
      doRefetch: refetch,
    },
    drafts: {
      key: (draft: Cont) => draft.CONTID,
      new: (data: Versioned<Cont>, sections: Map<string, boolean>) => ({
        database: database,
        contId: data.value.CONTID,
        etag: data.etag,
        edited: _.cloneDeep(data.value),
        sections: sections,
      }),
      table: db.containerInfoDrafts,
    },
  });

  const container = versioned?.value;

  useInitiatePageWithContainerData(database, contId);

  useEffect(() => {
    window.onbeforeunload = null;
  }, []);

  const options = [
    { value: "", label: "Nicht gesperrt" },
    { value: "?", label: "Hinweis bei Verwendung" },
    { value: "-", label: "Für Verwendung gesperrt" },
    { value: "X", label: "Ausgeblendet" },
  ];

  const handleLockStateChange = (event: DropDownListChangeEvent) => {
    updatePageState({ LOCKSTATE: event.target.value.value });
  };

  const { setAppBarTitle } = useAppBarState();

  const { setQuickInfo } = useQuickInfoState();

  // Aktualisiere die QuickInfo und die AppBar, jedes Mal etwas in der Seite sich ändert
  useEffect(() => {
    if (!currentForm?.value) return;

    setAppBarTitle(currentForm?.value.BEZ);
    setQuickInfo([
      {
        title: "Dateiname",
        content: currentForm?.value.BEZ,
      },
      {
        title: "Besitzer",
        content: currentForm?.value.OWNER,
      },
      {
        title: "Änderungsdatum",
        content: formatDateTimeToBrowserDefault(currentForm.value.LASTCHANGED),
      },
      {
        title: "Anmerkung",
        content: currentForm?.value.INTANMERK || "Keine Anmerkung vorhanden",
      },
    ]);
  }, [currentForm, setAppBarTitle, setQuickInfo]);

  if (is404 || (!is404 && contLoadingError)) {
    return <div></div>;
  }

  if (!currentForm) {
    return <Loader />;
  }

  const lastChanged = currentForm?.value.LASTCHANGED;

  return (
    <div className="expansion-panel-container">
      <AllgemeinesPanel
        isExpanded={sections.get("allgemeines") ?? false}
        onAction={() => {
          const newSections = new Map(sections);
          newSections.set("allgemeines", !sections.get("allgemeines"));
          updateSections(newSections);
        }}
        allgemeines={false}
        setDateiname={(value) => {
          updatePageState({ BEZ: value });
        }}
        setAnmerkung={(value) => {
          updatePageState({ INTANMERK: value });
        }}
        dateiname={currentForm.value.BEZ}
        anmerkung={currentForm.value.INTANMERK}
        contType={currentForm.value.CONTTYPE}
      />

      {/* Description Section */}
      <BeschreibungPanel
        title={
          <PanelTitle
            title="Beschreibung"
            panelContent={currentForm.value.BESCH}
            shouldShowPreview={!sections.get("beschreibung")}
          />
        }
        isExpanded={sections.get("beschreibung") ?? false}
        onAction={() => {
          const newSections = new Map(sections);
          newSections.set("beschreibung", !sections.get("beschreibung"));
          updateSections(newSections);
        }}
        beschreibung={currentForm.value.BESCH}
        updatePageState={updatePageState}
        pageState={currentForm}
        setBeschreibung={(e) => {
          updatePageState({ BESCH: e.target.value });
        }}
      />

      {/* Note Section */}
      <NotizPanel
        title={
          <PanelTitle
            title="Notiz"
            panelContent={currentForm.value.INTNOTIZ}
            shouldShowPreview={!sections.get("notiz")}
            contentIsHTML={true}
          />
        }
        isExpanded={sections.get("notiz") ?? false}
        onAction={() => {
          const newSections = new Map(sections);
          newSections.set("notiz", !sections.get("notiz"));
          updateSections(newSections);
        }}
        notiz={currentForm.value.INTNOTIZ}
        setNotiz={(value) => {
          updatePageState({ INTNOTIZ: value });
        }}
      />

      {/* Detail Section */}
      {/* TODO: use ABKControlledExpansionPanel */}
      <DetailsPanel
        isExpanded={sections.get("details") ?? false}
        onAction={() => {
          const newSections = new Map(sections);
          newSections.set("details", !sections.get("details"));
          updateSections(newSections);
        }}
        currentForm={currentForm}
        container={container}
        lastChanged={lastChanged}
        dropdownProps={{ options, handleLockStateChange }}
      />

      <div
        style={{
          paddingTop: isEdited ? heightBalken + PADDING_BOTTOM : PADDING_BOTTOM,
        }}
      />
      <Balken
        edited={is404 || isEdited}
        height={heightBalken}
        onSave={handleSave}
        conflictMessage={conflictMessage}
        onReset={handleReset}
      />
    </div>
  );
};

export default Allgemein;
