const AbkColors = {
  green: "#999900",
  orange: "#FFCC66",
  blue: "#336666",
  tuquoise: "#336666",
  purple: "#990066",
  grey: "#999999",
  white: "#FFFFFF",
};

export default AbkColors;
