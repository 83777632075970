import useGetAllContainerParameterlisten from "../../../../queries/useGetAllContainerParameterlisten";
import PLGrid from "./PLGrid";
import React from "react";
import { backendParameterlisteToFrontend } from "./convertBackendParameterliste";
import { Loader } from "@progress/kendo-react-indicators";
import ABKConfirmationDialog from "../../../../../../abk-shared/components/molecules/ABKConfirmationDialog";
import { useParameterlistenState } from "./useParameterlisten/useParameterlistenState";

const Parameterlisten = ({
  dbName,
  contId,
}: {
  dbName: string;
  contId: string;
}) => {
  const {
    data: parameterlisten,
    isLoading,
    isFetching,
  } = useGetAllContainerParameterlisten(dbName, contId);

  const {
    showDeleteDialog,
    selectedParameterlisten,
    deleteDialogPromise,
    setShowDeleteDialog,
  } = useParameterlistenState();

  const dataFrontend = React.useMemo(
    () => parameterlisten?.map(backendParameterlisteToFrontend),
    [parameterlisten]
  );

  if (isLoading) return <Loader />;

  return (
    <>
      <PLGrid
        parameterlisten={dataFrontend ?? []}
        dbName={dbName}
        contId={contId}
        isFetching={isFetching}
      />
      {showDeleteDialog && (
        <ABKConfirmationDialog
          message={
            selectedParameterlisten.length === 1
              ? `Wollen Sie die Parameterliste wirklich löschen?`
              : `Wollen Sie ${selectedParameterlisten.length} Parameterlisten wirklich löschen?`
          }
          onConfirm={async () => {
            if (deleteDialogPromise)
              deleteDialogPromise.resolve({ shouldProceed: true });
            setShowDeleteDialog(false);
          }}
          onClose={() => {
            if (deleteDialogPromise)
              deleteDialogPromise.resolve({ shouldProceed: false });
            setShowDeleteDialog(false);
          }}
        />
      )}
    </>
  );
};

export default Parameterlisten;
