import { SvgIcon } from "@progress/kendo-react-common";
import {
  Edit_Delete,
  Edit_Edit,
  Edit_Post,
} from "../../../interfaces/abk9SvgIcons";
import { SortableItemUIProps } from "@progress/kendo-react-sortable";
import ABKFormFieldInput from "../../atoms/ABKFormFieldInput";
import useListItem from "./useListItem";
import DragAndDropIcon from "../ABKBasisGrid/hooks/useGridColumns/gridActionColumns/DragAndDropIcon";

const getBaseItemStyle = (isActive: boolean) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#F5F5F5",
  padding: 4,
  textAlign: "center",
  outline: "none",
  margin: "4px 0",
  cursor: "move",
  borderRadius: 4,
  border: isActive ? "1px solid #B5B5B5" : "1px solid #F5F5F5",
});

const ListItem = (
  props: SortableItemUIProps,
  objectKeys: string[],
  setData: (data: any[]) => void,
  data: any[]
) => {
  const {
    handleSave,
    handleEdit,
    handleDelete,
    isEditMode,
    setEditedData,
    editedData,
  } = useListItem(props, data, setData);

  const { dataItem, isActive, style, forwardRef, attributes } = props;

  return (
    <div
      ref={forwardRef}
      {...attributes}
      style={{
        ...getBaseItemStyle(isActive),
        ...style,
      }}
    >
      <DragAndDropIcon />
      <div
        style={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        {objectKeys.map((key) => (
          <ABKFormFieldInput
            key={key}
            value={!editedData[key] && !isEditMode ? `(leer)` : editedData[key]}
            editorId={key}
            placeHolder={`${key} (leer)`}
            setValue={
              !isEditMode
                ? undefined
                : (value) => {
                    setEditedData({ ...editedData, [key]: value });
                  }
            }
          />
        ))}
      </div>

      <div style={{ display: "flex", gap: 1 }}>
        {!isEditMode && (
          <SvgIcon
            style={{ cursor: "pointer" }}
            icon={Edit_Edit}
            height={20}
            width={20}
            onClick={handleEdit}
          />
        )}

        {isEditMode && (
          <SvgIcon
            style={{ cursor: "pointer" }}
            icon={Edit_Post}
            height={20}
            width={20}
            onClick={handleSave}
          />
        )}

        <SvgIcon
          onClick={() => handleDelete(dataItem.id)}
          style={{ cursor: "pointer" }}
          icon={Edit_Delete}
          height={20}
          width={20}
        />
      </div>
    </div>
  );
};

export default ListItem;
