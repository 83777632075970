import {
  ParameterlisteStatus,
  ParameterlisteStatusFrontend,
} from "../../../../interfaces/parameterlisten";

// Mapper für Frontend nach Backend
export const statusMapper = new Map<
  ParameterlisteStatus,
  ParameterlisteStatusFrontend
>([
  [ParameterlisteStatus.Entwurf, ParameterlisteStatusFrontend.Entwurf],
  [ParameterlisteStatus.Freigegeben, ParameterlisteStatusFrontend.Freigegeben],
]);
