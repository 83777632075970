import {
  FILE_INFO_SUFFIX,
  FILE_PROTOCOL_SUFFIX,
  FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX,
  FILE_WEITEREPARAMETERLISTEN_SUFFIX,
} from "../../../../core/constants/urls";
import { FileInfo } from "../FileTypeMap";

const CPLFileInfo: FileInfo = {
  drawerItems: [
    FILE_INFO_SUFFIX,
    FILE_PROTOCOL_SUFFIX,
    FILE_WEITEREPARAMETERLISTEN_SUFFIX,
    FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX,
  ],
};

export default CPLFileInfo;
