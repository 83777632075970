import { useHotkeys } from "react-hotkeys-hook";
import { GenericObject } from "../../../../../interfaces/GenericObject";
import {
  DragAndDropDirection,
  FunctionReorderInternal,
} from "./gridReorderRowsInterfaces";
import getAnchorItem from "./getAnchorItem";

export default function useReorderWithArrowKeys(
  isReorderingEnabled: boolean,
  selectedItems: GenericObject[],
  dataResultWithSelectedField: GenericObject[],
  dataItemKey: string,
  isReordering: boolean,
  reorder: FunctionReorderInternal
) {
  const enabled =
    isReorderingEnabled && selectedItems.length !== 0 && !isReordering;

  useHotkeys(
    "up, down",
    async (_event, hotkeysEvent) => {
      if (!hotkeysEvent.keys) return;

      let direction: DragAndDropDirection = "before";

      const hotkeyPressed = hotkeysEvent.keys[0];
      switch (hotkeyPressed) {
        case "up":
          direction = "before";
          break;
        case "down":
          direction = "after";
          break;
        default:
          return;
      }

      const anchorItem = getAnchorItem(
        direction,
        selectedItems,
        dataResultWithSelectedField,
        dataItemKey
      );

      if (anchorItem === undefined) return;

      await reorder(anchorItem, direction);
    },
    { enableOnFormTags: true, preventDefault: true, enabled: enabled }
  );
}
