import useGetDBStatus from "../../queries/useGetDBStatus";
import ABKExpansionPanel from "../../../../abk-shared/components/atoms/ABKExpansionPanel";
import "./style.css";
import { ABKPageLoader } from "../../../../abk-shared/components/atoms/ABKPageLoader";
import React from "react";
import DbActionButtons from "./DbActionButtons";
import ABKFormFieldInput from "../../../../abk-shared/components/atoms/ABKFormFieldInput";
import ABKFormRow from "../../../../abk-shared/components/atoms/ABKFormRow";

const ID_ZUSTAND = "Zustand";
const DbStatusPage = () => {
  const { data: db, isLoading } = useGetDBStatus();

  let statusText: string;
  // Class für die Input, um die gleiche Width zu haben
  let statusClass = "k-input k-input-md k-rounded-md k-input-solid ";

  switch (db?.DBStatus?.Condition) {
    case "OK":
      statusText = "OK";
      statusClass += "status-ok";
      break;
    case "UpdateRequired":
      statusText = "Struktur-Update erforderlich";
      statusClass += "status-update-required";
      break;
    case "DBNotFound":
      statusText = "Datenbank nicht vorhanden";
      statusClass += "status-structure-error";
      break;
    case "StructureError":
      statusText = "Datenbankstruktur ungültig";
      statusClass += "status-structure-error";
      break;
    case "ConnectError":
      statusText = "Verbindung zur Datenbank nicht möglich";
      statusClass += "status-connect-error";
      break;
    default:
      statusText = "Unbekannter Status";
      statusClass += "status-unknown";
  }

  React.useEffect(() => {
    const statusElement = document.getElementById(ID_ZUSTAND);
    if (statusElement) {
      statusElement.textContent = statusText;
      statusElement.className = statusClass;
    } else {
      console.error("Status element not found");
    }
  }, [isLoading]);

  if (isLoading) return <ABKPageLoader />;

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <ABKExpansionPanel title="Datenbankkonfiguration" defaultExpanded={true}>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="Bezeichnung"
            label="Bezeichnung"
            value={db?.DBNAME}
          />
          <ABKFormFieldInput
            editorId="Datenbanktyp"
            label="Datenbanktyp"
            value={db?.DBTYPE}
          />
        </ABKFormRow>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="Server"
            label="Server"
            value={db?.SERVER}
          />
          <ABKFormFieldInput
            editorId="Databasename"
            label="Databasename"
            value={db?.DATABASE}
          />
        </ABKFormRow>
      </ABKExpansionPanel>
      <ABKExpansionPanel title="Status" defaultExpanded={true}>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="Server-Version"
            label="Server-Version"
            useJSXForValue={true}
            value={
              <span title={db?.DBStatus?.DBServerVersion}>
                {db?.DBStatus?.DBServerVersion}
              </span>
            }
            title={db?.DBStatus?.DBServerVersion}
          />
          <ABKFormFieldInput
            editorId="Datenbank-ID"
            label="Datenbank-ID"
            value={db?.DBStatus?.DBID}
          />
        </ABKFormRow>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="Version erforderlich"
            label="Version erforderlich"
            value={db?.DBStatus?.VersionRequired}
          />
          <ABKFormFieldInput
            editorId="Version aktuell"
            label="Version aktuell"
            value={db?.DBStatus?.VersionActual}
          />
        </ABKFormRow>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId={ID_ZUSTAND}
            label="Zustand"
            value={statusText}
            className={statusClass}
          />
          <ABKFormFieldInput
            editorId="Meldung"
            label="Meldung"
            value={
              db?.DBStatus?.ExceptionInfo?.title
                ? db?.DBStatus?.ExceptionInfo?.title
                : ""
            }
          />
        </ABKFormRow>
        <DbActionButtons db={db} />
      </ABKExpansionPanel>
    </div>
  );
};

export default DbStatusPage;
