export default function getValidLocale() {
  const userLocale = navigator.language;

  const availableLocales = ["de", "en"];
  const isLocaleAvailable = availableLocales.some((locale) =>
    userLocale.startsWith(locale)
  );

  /*
    Die "de-DE" `defaultLocale` wurde ausgewählt, weil sie entspricht, die
    vorhandene deutsche Locale von KendoReact.
    "de-AT" hat ein unterschiedliesches Format für Zahlen, und ist nicht von
    KendoReact unterstützt.
  */
  const defaultLocale = "de-DE";
  if (!isLocaleAvailable) return defaultLocale;

  return userLocale;
}
