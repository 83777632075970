import "./style.scss";
import useIsMobileTemplate from "../../../utility/useIsMobileTemplate";

const RawSaveBalken = ({
  inline,
  height,
  balkenMessage,
  children,
}: {
  inline?: boolean;
  height?: number;
  balkenMessage?: string;
  children: React.ReactNode;
}) => {
  const isMobileTemplate = useIsMobileTemplate();

  const classNameForm = `balken-k-form ${isMobileTemplate ? "mobile" : ""}`;

  return (
    <div
      className="balken"
      style={{
        height: height,
        position: inline ? "relative" : "fixed",
      }}
    >
      <form className={classNameForm} onSubmit={(e) => e.preventDefault()}>
        <div className="balken-message">{balkenMessage}</div>
        <div className={`balken-buttons ${isMobileTemplate ? "mobile" : ""}`}>
          {children}
        </div>
      </form>
    </div>
  );
};

export default RawSaveBalken;
