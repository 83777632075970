import React from "react";
import ABKConfirmationDialog from "../../../../../abk-shared/components/molecules/ABKConfirmationDialog";
import ABKBasisGrid from "../../../../../abk-shared/components/organisms/ABKBasisGrid";
import { EintragFrontend } from "../../../interfaces/parameterlisten";
import EintraegeCard from "./EintraegeCard";
import { useEintragBearbeitenState } from "./EintragBearbeitenDialog/useEintragBearbeitenState";
import useEintraege from "./useEintraege";
import { useEffect } from "react";
import { backendEintragToFrontend } from "./convertBackendEintrag";
import useGetEintraege from "./useGetEintraege";
import { ABKPageLoader } from "../../../../../abk-shared/components/atoms/ABKPageLoader";

type Props = {
  db: string;
  contId: string;
  plID: string;
};
const PLEintraegeGrid = ({ db, contId, plID }: Props) => {
  const {
    currentEintrag,
    setCurrentEintrag,
    showDeleteDialog,
    setShowDeleteDialog,
    selectedEintraege,
    setSelectedEintraege,
    setInstance,
    deleteDialogPromise,
    gridScrollTop,
  } = useEintragBearbeitenState();

  const { data, isLoading, isError, error, isFetching } = useGetEintraege(
    db,
    contId,
    plID
  );

  const eintraege = React.useMemo(
    () => data?.map(backendEintragToFrontend),
    [data]
  );

  const dataItemKey = "ITEMID";
  const {
    columnsDefinition,
    customCells,
    gridActions,
    reorderRows,
    isGridLoading,
  } = useEintraege(isFetching, dataItemKey);

  useEffect(() => {
    setInstance({
      DBNAME: db,
      CONTID: contId,
      DATAID: plID,
    });
  }, [db, contId, plID, setInstance]);

  /*
    Wir re-rendern das Grid auch bei `isFetching`.
    Wenn wir eine Aktion ausführen, die die Daten im Grid ändert, wird das Grid
    defaultmäßig re-rendern.
    Die Scroll-Position wird dadurch zurückgesetzt: die Scrollbar wird am ersten
    Eintrag sein. Das ist nicht gut, insbesondere für lange Listen.
    Wir wollen die Scroll-Position behalten, also zeigen wir nicht das Grid während wir
    die neue Liste abfragen.

    Wenn wir das Grid gezeigt hätten, wäre die Scroll-Position korrekt eingestellt,
    aber der User würde merken, dass die Scrollbar von der ersten Zeile zur vorherigen
    Scroll-Position springt.
    Um das zu vermeiden, zeigen wir das Grid nicht, und wir initialisieren das Grid mit
    der vorherigen Scroll-Position.
    https://ib-data.atlassian.net/browse/ABK9-826
  */
  if (isFetching || isLoading || !eintraege) return <ABKPageLoader />;

  if (isError) return <div>{error.message}</div>;

  return (
    <>
      <ABKBasisGrid
        initialization={{
          selectedItems: currentEintrag ? [currentEintrag] : [],
          scrollTop: gridScrollTop,
        }}
        groupable={false}
        sortable={false}
        data={eintraege}
        onSelectionChange={(itemsSelected) => {
          setSelectedEintraege([...itemsSelected] as EintragFrontend[]);
          const selectedItem = itemsSelected[0] as EintragFrontend;
          setCurrentEintrag(selectedItem);
        }}
        isLoading={isGridLoading}
        columnsDefinition={columnsDefinition}
        customCells={customCells}
        persistedDataStateId={{
          sector: "pl",
          unique: `pl-${db}-${contId}-${plID}`,
        }}
        dataItemKey={dataItemKey}
        gridActions={gridActions}
        reorderRowsConfig={{ reorderRows, dragHintKey: "BEZ" }}
        mobileGrid={{
          rowHeight: 60,
          renderRow: (
            rowRef,
            item,
            filterValue,
            isSelected,
            setDataItemSelected
          ) => (
            <EintraegeCard
              rowRef={rowRef}
              item={item as EintragFrontend}
              filterValue={filterValue}
              isSelected={isSelected}
              setDataItemSelected={setDataItemSelected}
            />
          ),
        }}
      />
      {showDeleteDialog && (
        <ABKConfirmationDialog
          message={
            selectedEintraege.length === 1
              ? `Wollen Sie den Eintrag wirklich löschen?`
              : `Wollen Sie ${selectedEintraege.length} Einträge wirklich löschen?`
          }
          onConfirm={async () => {
            if (deleteDialogPromise)
              deleteDialogPromise.resolve({ shouldProceed: true });
            setShowDeleteDialog(false);
          }}
          onClose={() => {
            if (deleteDialogPromise)
              deleteDialogPromise.resolve({ shouldProceed: false });
            setShowDeleteDialog(false);
          }}
        />
      )}
    </>
  );
};

export default PLEintraegeGrid;
