import { GridCustomCellProps } from "@progress/kendo-react-grid";
import { GridColumnPropsCustom } from "../../interfaces/GridColumns";
import useFieldsWithInternalValue from "../../hooks/useFieldsWithInternalValue";
import { DATA_KEY } from "../../constants";
import { getValueOfFirstGroupedItem } from "../../utils/groupingFunctions";

type Props = GridCustomCellProps & { columns: GridColumnPropsCustom[] };
export default function GroupHeaderCustomCell(props: Props) {
  const fieldsWithInternalValue = useFieldsWithInternalValue(props.columns);

  /*
    Leeres `td` ist notwendig um eine Einrückung zu haben, wenn mehr als eine
    Spalte gruppiert ist.
  */
  const { children, ...propsWithoutChildren } = props;
  const shouldDisplayEmptyTd = children == null;
  if (shouldDisplayEmptyTd) return <GroupHeaderTd {...propsWithoutChildren} />;

  const field = props.dataItem.field.replace(`.${DATA_KEY.INTERNAL}`, "");
  const isSpecificField = field && fieldsWithInternalValue.includes(field);
  if (!isSpecificField)
    return <GroupHeaderTd {...propsWithoutChildren}>{children}</GroupHeaderTd>;

  const value = getValueOfFirstGroupedItem(props.dataItem, field);
  // Inspiriert von: https://www.telerik.com/kendo-react-ui/components/knowledge-base/render-a-custom-content-inside-the-grid-group-header/#toc-solution
  const collapseArrow = (children as any)?.props?.children?.[0];
  return (
    <GroupHeaderTd {...props}>
      {collapseArrow}
      {value}
    </GroupHeaderTd>
  );
}

function GroupHeaderTd(props: Props) {
  return (
    <td {...props.tdProps} key={(props.tdProps as any)?.key}>
      {props.children}
    </td>
  );
}
