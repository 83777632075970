import { SvgIcon } from "@progress/kendo-react-common";
import { NavigationItem } from "../../../interfaces/NavigationItem";

const ABKDrawerItem = ({
  item,
  onDrawerItemSelect,
}: {
  item: NavigationItem;
  onDrawerItemSelect: (e: NavigationItem) => void;
}) => (
  <li
    className="k-drawer-item"
    role="menuitem"
    onClick={() => onDrawerItemSelect(item)}
  >
    <SvgIcon icon={item.svgIcon} />
    <span className="k-item-text">{item.text}</span>
  </li>
);

export default ABKDrawerItem;
