import InfoBox from "./MainPageTemplate/InfoBox";
import Breadcrumbs from "../molecules/ABKBreadcrumbs";
import AbkDrawer from "../../../abk-shared/components/molecules/AbkDrawer";
import AbkTabNavigation from "../organisms/AbkTabNavigation";
import "./style.css";
import ABKSideBar from "../../../abk-shared/components/organisms/ABKSideBar";
import { INotification } from "../../../abk-shared/components/organisms/ABKSideBar/NotificationBox";
import { DrawerSelectEvent } from "@progress/kendo-react-layout";
import { QueryClient } from "@tanstack/react-query";
import { QuickinfoContent } from "../../../abk-shared/components/organisms/ABKSideBar/QuickInfo";
import { FunctionOnNotificationClick } from "../../../abk-shared/components/organisms/ABKSideBar/SideBar";
import { NavigationItem } from "../../../abk-shared/interfaces/NavigationItem";

interface Props {
  children: React.ReactNode;
  currentDrawerItems: NavigationItem[];
  onDrawerItemSelect: (e: DrawerSelectEvent) => void;
  notifications: INotification[];
  onCloseNotification: (notification: INotification) => void;
  infoHubOptions?: React.ReactNode;
  onNotificationClick?: FunctionOnNotificationClick;
  removeNotification: (notification: INotification) => void;
  setQuickInfo: (
    quickInfo: QuickinfoContent,
    notification: INotification,
    quickInfoClipboard: string
  ) => void;
  clearAll: () => void;
  clearWarnings: () => void;
  clearErrors: () => void;
  clearInfos: () => void;
  queryClient: QueryClient;
  isDesktop: boolean;
  headerRef: React.RefObject<HTMLDivElement>;
  includeTitle: boolean;
  includeDescription: boolean;
  scrollableContainerRef: React.RefObject<HTMLDivElement>;
}

const DesktopStructure = ({
  children,
  currentDrawerItems,
  onDrawerItemSelect,
  notifications,
  onCloseNotification,
  infoHubOptions,
  onNotificationClick,
  headerRef,
  includeTitle,
  includeDescription,
  scrollableContainerRef,
}: Props) => {
  return (
    <ABKSideBar
      notifications={notifications}
      onCloseNotification={onCloseNotification}
      onNotificationClick={onNotificationClick}
      infoHubOptions={infoHubOptions}
      leftSideBarContent={
        <AbkDrawer
          currentDrawerItems={currentDrawerItems}
          onDrawerItemSelect={(e) => onDrawerItemSelect(e)}
        >
          <Breadcrumbs />
          <InfoBox
            headerRef={headerRef}
            includeTitle={includeTitle}
            includeDescription={includeDescription}
          />
          <AbkTabNavigation />
          <div
            ref={scrollableContainerRef}
            className="abk-viewport"
            id="outlet-container"
          >
            {children}
          </div>
        </AbkDrawer>
      }
    />
  );
};

export default DesktopStructure;
