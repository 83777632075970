import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import useCustomViewHtmlStore from "./useCustomViewHtmlStore";

const CustomViewHtmlHook = () => {
  const { setShowDialogWindow } = useCustomViewHtmlStore();

  return (
    <Button
      style={{ height: 30 }}
      icon={"view-html"}
      onClick={() => setShowDialogWindow(true)}
      title="HTML-Ansicht"
    >
      HTML
    </Button>
  );
};

export default CustomViewHtmlHook;
