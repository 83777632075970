import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { EintragBackend } from "../../../interfaces/parameterlisten";

export const QUERY_KEY_GET_EINTRAEGE = "eintraege";

export default function useGetEintraege(
  dbName: string,
  contId: string,
  plID: string
) {
  return useQuery({
    queryKey: [QUERY_KEY_GET_EINTRAEGE, dbName, contId, plID],
    queryFn: async () => {
      const url = `/api/db/${dbName}/cont/${contId}/pl/${plID}/items`;
      const response = await axios.get(url);
      const eintraege = response.data.Items as EintragBackend[];
      if (eintraege) return eintraege;
      else return [];
    },
  });
}
