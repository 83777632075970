import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_PARAMETERLISTE } from "../../../../queries/useGetSingleParameterliste";

export default function useGetTemplateItem(
  dbName: string,
  contId: string,
  plID: string
) {
  return useQuery({
    queryKey: [dbName, contId, plID, QUERY_KEY_PARAMETERLISTE, "templateItem"],
    queryFn: async () => {
      const response = await axios.get(
        `/api/db/${dbName}/cont/${contId}/pl/${plID}/items/_new`
      );
      return response.data;
    },
  });
}
