import { create } from "zustand";

interface MainPageTemplateState {
  lastBreadcrumb: React.ReactElement | null;
  setLastBreadcrumb: (lastBreadcrumb: React.ReactElement | null) => void;
}

const useLastBreadcrumb = create<MainPageTemplateState>((set) => ({
  lastBreadcrumb: null,
  setLastBreadcrumb: (lastBreadcrumb: React.ReactElement | null) => {
    set(() => ({ lastBreadcrumb }));
  },
}));

export default useLastBreadcrumb;
