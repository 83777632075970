import React from "react";
import { EintragFrontend } from "../../../../../interfaces/parameterlisten";
import useMoveEintraege from "../mutations/useMoveEintraege";
import { useEintragBearbeitenState } from "../../EintragBearbeitenDialog/useEintragBearbeitenState";
import { GridRowSelection } from "../../../../../../../abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelectionInterfaces";
import { DialogResponse } from "../../../../../../Startseite/pages/Verwalten/DeleteDialog";
import useDeleteEintrag from "../mutations/useDeleteEintrag";
import { produce } from "immer";
import { containersAreDisplayedInQuickInfo } from "../../../../../../Startseite/pages/Verwalten/useVerwalten";
import { useQuickInfoState } from "../../../../../../../abk-shared/stores/useQuickInfoState";
import { SaveType } from "../../SaveTypeEnum";
import useAddNewPLItem from "../../../../../queries/useAddNewPLItem";
import { showNewItemDialogAndMutateIfSuccess } from "./addNewItem";

export type EintraegeActionHandlers = ReturnType<
  typeof useEintraegeActionHandlers
>;

export default function useEintraegeActionHandlers(
  setIsGridLoading: React.Dispatch<React.SetStateAction<boolean>>,
  dataItemKey: string
) {
  const {
    setCurrentEintrag,
    setOpenDialog,
    setShowDeleteDialog,
    setShowNewItemDialog,
    selectedEintraege,
    setDeleteDialogPromise,
    setNewItemSaveType,
    setNewItemDialogPromise,
  } = useEintragBearbeitenState();

  const { resetQuickInfo, variableDisplayed } = useQuickInfoState();

  const mutationMoveEintraege = useMoveEintraege(setIsGridLoading);
  const mutationDeleteEintrag = useDeleteEintrag(setIsGridLoading);
  const mutationAddNewPLItem = useAddNewPLItem();

  const eintraegeActionHandlers = React.useMemo(
    () => ({
      open: (_event: any, selectedEintraege: EintragFrontend[]) => {
        const eintragSelected = selectedEintraege[0];
        setCurrentEintrag(eintragSelected);
        setOpenDialog(true);
      },
      delete: async (gridRowSelection: GridRowSelection) => {
        setShowDeleteDialog(true);
        const { shouldProceed } = await new Promise<DialogResponse>(
          (resolve) => {
            setDeleteDialogPromise({ resolve });
          }
        );
        if (!shouldProceed) return;

        await mutationDeleteEintrag.mutateAsync(selectedEintraege);

        const newSelectedState = produce(
          gridRowSelection.selectedState,
          (draft) => {
            for (const selectedEintrag of selectedEintraege)
              delete draft[selectedEintrag[dataItemKey as "ITEMID"]];
          }
        );
        gridRowSelection.setSelectedState(newSelectedState);

        if (
          containersAreDisplayedInQuickInfo(
            selectedEintraege,
            variableDisplayed
          )
        )
          resetQuickInfo();
      },
      newAtEnd: () => {
        setNewItemSaveType(SaveType.NewAtEnd);

        showNewItemDialogAndMutateIfSuccess(
          setShowNewItemDialog,
          setNewItemDialogPromise,
          mutationAddNewPLItem
        );
      },
      newBeforeItem: () => {
        setNewItemSaveType(SaveType.InsertBefore);

        showNewItemDialogAndMutateIfSuccess(
          setShowNewItemDialog,
          setNewItemDialogPromise,
          mutationAddNewPLItem
        );
      },
      moveDown: async (
        selectedEintraege: EintragFrontend[],
        anchorEintrag: EintragFrontend
      ) => {
        return mutationMoveEintraege.mutate({
          mode: "move-down",
          selectedEintraege,
          anchorEintrag,
        });
      },
      moveUp: async (
        selectedEintraege: EintragFrontend[],
        anchorEintrag: EintragFrontend
      ) => {
        return mutationMoveEintraege.mutate({
          mode: "move-up",
          selectedEintraege,
          anchorEintrag,
        });
      },
    }),
    [
      setCurrentEintrag,
      setOpenDialog,
      setShowDeleteDialog,
      mutationDeleteEintrag,
      selectedEintraege,
      variableDisplayed,
      resetQuickInfo,
      setDeleteDialogPromise,
      dataItemKey,
      setNewItemSaveType,
      setShowNewItemDialog,
      setNewItemDialogPromise,
      mutationAddNewPLItem,
      mutationMoveEintraege,
    ]
  );

  return eintraegeActionHandlers;
}
