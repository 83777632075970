import Highlighter from "react-highlight-words";
import CardCheckbox from "../../../../modules/Startseite/components/molecules/DateienCard/DateienCardCheckbox";
import { FunctionSetDataItemSelected } from "../../organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelectionInterfaces";

type Props = {
  rowRef: React.RefObject<HTMLDivElement>;
  item: any;
  filterValue: string;
  isSelected: boolean;
  setDataItemSelected: FunctionSetDataItemSelected;
  onRowClick: (item: any) => void;
  extraRowStyles?: React.CSSProperties;
  cardTitle: string;
  cardSubtitle: string;
  cardExtraTitle?: string;
  cardTitleStyles?: React.CSSProperties;
  cardSubtitleStyles?: React.CSSProperties;
  cardExtraTitleStyles?: React.CSSProperties;
};

const ABKCompactMobileCard = ({
  rowRef,
  item,
  filterValue,
  isSelected,
  setDataItemSelected,
  onRowClick,
  extraRowStyles,
  cardTitle,
  cardSubtitle,
  cardExtraTitle,
  cardTitleStyles,
  cardSubtitleStyles,
  cardExtraTitleStyles,
}: Props) => {
  return (
    <div
      aria-label={"row-" + item.VARNAME}
      data-testid="mobile-grid-row"
      ref={rowRef}
      title={item.BEZ}
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderTop: "1px solid black", // von borderBottom auf borderTop geändert, damit die Border-Linie nicht vom Background überdeckt wird.
        padding: "0.25rem",
        cursor: "pointer",
        height: "100%",
        ...extraRowStyles,
      }}
      onClick={() => {
        onRowClick(item);
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <span
          style={{
            fontWeight: "bold",
            ...cardTitleStyles,
          }}
          data-testid="cardTitle"
        >
          <Highlighter
            searchWords={[filterValue]}
            textToHighlight={cardTitle}
          />
          {cardExtraTitle && (
            <span
              style={{
                color: "gray",
                fontWeight: "normal",
                ...cardExtraTitleStyles,
              }}
            >{` [${cardExtraTitle}]`}</span>
          )}
        </span>
        <span style={{ ...cardSubtitleStyles }}>{cardSubtitle}</span>
      </div>

      <CardCheckbox
        testProps={{ ariaLabel: "Select Row" }}
        data={item}
        isSelected={isSelected}
        setDataItemSelected={setDataItemSelected}
      />
    </div>
  );
};

export default ABKCompactMobileCard;
