import AllgemeinPanel from "./AllgemeinPanel";
import KommentarPanel from "./KommentarPanel";
import AuswahllistePanel from "./AuswahllistePanel";
import ZuordnungFunktionenPanel from "./ZuordnungFunktionenPanel";
import ZuordnungProduktgruppenPanel from "./ZuordnungProduktgruppenPanel";
import ZahlPanel from "./ZahlPanel";
import ParameterPanel from "./ParameterPanel";
import RechenansatzPanel from "./Rechenansatz";
import { ItemTypeDisplayed } from "../../maps";
import { EintragFrontend } from "../../../../../interfaces/parameterlisten";

export type PropsEintragPanel = {
  pageState: EintragFrontend;
  setPageState: (eintrag: EintragFrontend) => void;
};

const createAllgemeinPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void
) => <AllgemeinPanel pageState={pageState} setPageState={setPageState} />;

const createParameterPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void
) => <ParameterPanel pageState={pageState} setPageState={setPageState} />;

const createZahlPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void
) => <ZahlPanel pageState={pageState} setPageState={setPageState} />;

const createRechenansatzPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void
) => <RechenansatzPanel pageState={pageState} setPageState={setPageState} />;

const createAuswahllistePanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void
) => <AuswahllistePanel pageState={pageState} setPageState={setPageState} />;

const createKommentarPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void
) => <KommentarPanel pageState={pageState} setPageState={setPageState} />;

const createZuordnungFunktionenPanel = () => <ZuordnungFunktionenPanel />;
const createZuordnungProduktgruppenPanel = () => (
  <ZuordnungProduktgruppenPanel />
);

export const parTypePanelsMap: Map<ItemTypeDisplayed, Function[]> = new Map([
  ["Überschrift", [createAllgemeinPanel, createKommentarPanel]],
  [
    "Parameterset",
    [
      createAllgemeinPanel,
      createKommentarPanel,
      createZuordnungFunktionenPanel,
      createZuordnungProduktgruppenPanel,
    ],
  ],
  [
    "Zahl",
    [
      createAllgemeinPanel,
      createParameterPanel,
      createZahlPanel,
      createRechenansatzPanel,
      createAuswahllistePanel,
      createKommentarPanel,
    ],
  ],
  [
    "Text (einzeilig)",
    [
      createAllgemeinPanel,
      createParameterPanel,
      createAuswahllistePanel,
      createKommentarPanel,
    ],
  ],
  [
    "Text (mehrzeilig)",
    [createAllgemeinPanel, createParameterPanel, createKommentarPanel],
  ],
  ["Datum", [createAllgemeinPanel, createParameterPanel, createKommentarPanel]],
  [
    "Ja/Nein",
    [createAllgemeinPanel, createParameterPanel, createKommentarPanel],
  ],
]);
