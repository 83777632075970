import React from "react";
import { GridContextMenuHandler } from "../hooks/useGridContextMenu";
import { Popup } from "@progress/kendo-react-popup";
import ABKNavigationButtonItem from "../../../atoms/ABKNavigationButtonItem";
import { GridActionObject } from "../interfaces/GridAction";
import DataStateContext from "../hooks/useGridDataState/DataStateContext";
import { SelectionContext } from "../hooks/useGridReorderRows/gridReorderRowsContexts";

type Props = {
  gridContextMenu: GridContextMenuHandler;
};

const ABKBasisGridContextMenu: React.FC<Props> = ({ gridContextMenu }) => {
  React.useEffect(() => {
    const handleBodyClick = () => {
      gridContextMenu.setShow(false);
    };

    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  const { dataState, dataResultWithSelectedField, gridActions } =
    React.useContext(DataStateContext);
  const gridRowSelection = React.useContext(SelectionContext);

  const actions = gridActions
    ? gridActions(gridRowSelection, dataResultWithSelectedField, dataState)
    : [];

  const menuItems = actions.map((action, index) => {
    if (React.isValidElement(action)) {
      return (
        <p key={index} className="k-px-lg">
          <i>{action}</i>
        </p>
      );
    }

    const actionObject = action as GridActionObject;
    return (
      <ABKNavigationButtonItem
        text={actionObject.text}
        onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
          actionObject.onClick(event, gridRowSelection.selectedItems);
        }}
        icon={actionObject.icon}
        key={actionObject.text}
      />
    );
  });

  return (
    <Popup show={gridContextMenu.show} offset={gridContextMenu.offset.current}>
      {menuItems}
    </Popup>
  );
};

export default ABKBasisGridContextMenu;
