import { OHNE_INHALT } from "../../../../../../../core/constants/text";
import { IField } from "./IField";

interface EintragBearbeitenTextAreaExpansionPanelVorschauProps {
  title: string;
  field: IField | undefined;
  shouldShowPreview: boolean;
}
const EintragBearbeitenTextAreaExpansionPanelVorschau = ({
  title,
  field,
  shouldShowPreview,
}: EintragBearbeitenTextAreaExpansionPanelVorschauProps) => {
  const preview = generatePreview(field!);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="k-expander-title" style={{ minWidth: "fit-content" }}>
          {title}
        </div>
        {shouldShowPreview && <span className="Textvorschau">{preview}</span>}
      </div>
    </>
  );
};

export default EintragBearbeitenTextAreaExpansionPanelVorschau;

function generatePreview(field: IField) {
  if (field === undefined) return "";
  if (field.value === "" || field.value === undefined || field.value === null)
    return OHNE_INHALT;
  return String(field.value);
}
