/*
  Job example:

  {
    "CONTID": "2024-07-12-14.01.36.8810@jlassi@abk.at",
    "ContInfo": {
        "CONTID": "2024-07-12-14.01.36.8810@jlassi@abk.at",
        "CONTTYPE": "cgs",
        "SUBTYPE": "",
        "BEZ": "ASI_TUERLISTE_V2",
        "BESCH": "",
        "NOTIZ": "",
        "ANMERK": "",
        "OWNER": "jlassi@abk.at",
        "LASTCHANGED": "2024-07-12T14:01:38.100+02:00",
        "LOCKSTATE": "",
        "SPECIALFILE": "",
        "GROUPNAME": "",
        "ContTypeInfo": {
            "CATNAME": "ContType",
            "CATITEMID": "cgs",
            "TXT": "Gliederungssytem",
            "TXTPLURAL": "Gliederungssyteme",
            "ABBREV": "cgs",
            "ICONID": "Data.Conttype.cgs"
        },
        "Meta": {
            "Rowchanged": "2024-07-12-14.01.38.0690@jlassi@abk.at",
            "Actualized": null,
            "Right": "",
            "apiurl": ""
        }
    },
    "JOBID": "2024-07-12-14.01.36.9290@jlassi@abk.at",
    "OWNER": "jlassi@abk.at",
    "TIMESTART": "2024-07-12T14:01:36.927+02:00",
    "TIMEEND": "2024-07-12T14:01:38.083+02:00",
    "COMMAND": "db_run_importfile",
    "SUBCOMMAND": "",
    "PARAMS": "",
    "DESCRIPTION": "Datei importieren",
    "STATUS": "OK",
    "ExceptionInfo": null,
    "RESULTDATA": "",
    "ERRCODE": "",
    "ERRTXT": "",
    "ERRTXTLONG": "",
    "ERRCALLSTACK": "",
    "CNTERR": 0,
    "CNTWARN": 0,
    "CNTINFO": 4,
    "LogUrl": "/db/Test-DB/cont/2024-07-12-14.01.36.8810@jlassi@abk.at/jobs/2024-07-12-14.01.36.9290@jlassi@abk.at/log",
    "FilesUrl": "/db/Test-DB/cont/2024-07-12-14.01.36.8810@jlassi@abk.at/jobs/2024-07-12-14.01.36.9290@jlassi@abk.at/files",
    "DefaultFile": {
        "CONTID": "2024-07-12-14.01.36.8810@jlassi@abk.at",
        "JOBID": "2024-07-12-14.01.36.9290@jlassi@abk.at",
        "FILEID": "default",
        "NAME": "ASI_TUERLISTE_V2.onbgs",
        "TYP": "onbgs",
        "BEZ": "",
        "DownloadUrl": "/api/db/Test-DB/cont/2024-07-12-14.01.36.8810@jlassi@abk.at/jobs/2024-07-12-14.01.36.9290@jlassi@abk.at/files/default/download"
    },
    "Meta": {
        "Rowchanged": "",
        "Actualized": null,
        "Right": "",
        "apiurl": ""
    }
  },
*/

import { ABKError } from "../../abk-shared/interfaces/Error";
import { Cont } from "./Cont";
import { Meta } from "./Meta";

export interface Job {
  meta?: Meta;
  CONTID: string;
  JOBID: string;
  OWNER: string;
  TIMESTART: string;
  TIMEEND: string;
  COMMAND: string;
  SUBCOMMAND: string;
  PARAMS: string;
  DESCRIPTION: string;
  STATUS: Status;
  RESULTDATA: string;
  ERRCALLSTACK: string;
  ERRCODE: string;
  ERRTXT: string;
  ERRTXTLONG: string;
  ExceptionInfo: ABKError | null;
  CNTERR: number;
  ContInfo: Cont | null;
  CNTWARN: number;
  CNTINFO: number;
  LogUrl: string;
  FilesUrl: string;
  DefaultFile: DefaultFile;
}

export enum Status {
  Draft = "Draft",
  Running = "Running",
  AbortRequested = "AbortRequested",
  Aborted = "Aborted",
  OK = "OK",
  Failed = "Failed",
}

export function translateJobStatus(status: Status) {
  switch (status) {
    case Status.Draft:
      return "Entwurf";
    case Status.Running:
      return "Laufend";
    case Status.AbortRequested:
      return "Abbruch angefordert";
    case Status.Aborted:
      return "Abgebrochen";
    case Status.OK:
      return "OK";
    case Status.Failed:
      return "Fehlgeschlagen";
    default:
      return status;
  }
}

export enum Command {
  Import = "db_run_importfile",
  Export = "cont_run_export",
  CreateDB = "db_run_createdb",
  UpdateDB = "db_run_updatedb",
  RepairDB = "db_run_repairdb",
  CheckContainer = "cont_run_check",
}

export interface DefaultFile {
  FILESIZE: number;
  CONTID: string;
  DownloadUrl: string;
  FILEID: string;
  JOBID: string;
  NAME: string;
  TYP: string;
}

export interface AISummary {
  summary: string;
  db: string;
  cont_id: string;
}
