import { AxiosResponse } from "axios";
import { Versioned } from "src/core/interfaces/Versioned";

/**
 * Add Etag to data, so react-query re-fetches if etag has been changed by other user
 * See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/ETag
 */
export function formatWithEtag<T>(
  response: AxiosResponse<T, any>
): Versioned<T> {
  const value: T = response.data;
  const etag: string = response.headers.etag;
  return { value, etag };
}
