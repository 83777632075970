import { create } from "zustand";

interface AppBarState {
  appBarTitle: string;
  setAppBarTitle: (title: string) => void;
  appBarActionButton: React.ReactNode;
  setAppBarActionButton: (actionButton: React.ReactNode) => void;
  openPopup: string | null;
  setOpenPopup: (popup: string | null) => void;
}

const useAppBarState = create<AppBarState>((set) => ({
  appBarTitle: "ABK-Dateien",
  appBarActionButton: null,
  openPopup: null,
  setAppBarTitle: (title: string) => {
    document.title = title;
    set(() => ({ appBarTitle: title }));
  },
  setAppBarActionButton: (actionButton: React.ReactNode) => {
    set(() => ({ appBarActionButton: actionButton }));
  },
  setOpenPopup: (popup: string | null) => {
    set(() => ({ openPopup: popup }));
  },
}));

export default useAppBarState;
