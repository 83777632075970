import ABKConfirmationDialog from "../../../../../abk-shared/components/molecules/ABKConfirmationDialog";
import { ContWithLastChangedModified } from "../../../../../core/interfaces/Cont";

export type DialogResponse = { shouldProceed: boolean };

type Props = {
  toggleDialog: () => void;
  selectedContainers: ContWithLastChangedModified[];
  deleteDialogPromise: {
    resolve: (value: DialogResponse) => void;
  } | null;
};

const DeleteDialog = ({
  toggleDialog,
  selectedContainers,
  deleteDialogPromise,
}: Props) => {
  let message = "Sind Sie sicher, dass Sie ";
  if (selectedContainers.length === 1)
    message += `die Datei "${selectedContainers[0].BEZ}"`;
  else message += `${selectedContainers.length} Dateien`;
  message += " löschen möchten?";

  return (
    <ABKConfirmationDialog
      onClose={() => {
        toggleDialog();
        if (deleteDialogPromise)
          deleteDialogPromise.resolve({ shouldProceed: false });
      }}
      message={<p>{message}</p>}
      onConfirm={() => {
        toggleDialog();
        if (deleteDialogPromise)
          deleteDialogPromise.resolve({ shouldProceed: true });
      }}
    />
  );
};

export default DeleteDialog;
