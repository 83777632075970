import { GenericObject } from "../../../../../interfaces/GenericObject";
import { FIELD_SELECTED } from "../useGridColumns/gridActionColumns";
import { DragAndDropDirection } from "./gridReorderRowsInterfaces";

export default function getAnchorItem<ItemType extends GenericObject>(
  direction: DragAndDropDirection,
  selectedItems: ItemType[],
  dataResultWithSelectedField: ItemType[],
  dataItemKey: string
) {
  if (selectedItems.length === 0) return undefined;

  // `closestItem` bedeutet entweder das Obere bei "before" oder das Untere bei "after"
  const indexClosestSelectedItem = findIndexOfClosestSelectedItem(
    direction,
    selectedItems,
    dataResultWithSelectedField,
    dataItemKey
  );
  if (indexClosestSelectedItem === -1) return undefined;

  const anchorItemClosestSelected = getAnchorItemForClosestSelected(
    direction,
    indexClosestSelectedItem,
    dataResultWithSelectedField
  );
  if (anchorItemClosestSelected !== undefined) return anchorItemClosestSelected;

  const canStillMoveOtherSelectedItems = selectedItems.length > 1;
  if (!canStillMoveOtherSelectedItems) return undefined;

  const nonSelectedItemsBetweenSelected = findNonSelectedItemsBetweenSelected(
    direction,
    selectedItems,
    dataResultWithSelectedField,
    dataItemKey
  );
  const canMoveAfterLastNonSelectedItem =
    nonSelectedItemsBetweenSelected.length !== 0;
  if (!canMoveAfterLastNonSelectedItem) return undefined;

  const anchorItemBetweenSelected = getAnchorItemForMultipleSelectedItems(
    direction,
    nonSelectedItemsBetweenSelected
  );
  return anchorItemBetweenSelected;
}

function findIndexOfClosestSelectedItem<ItemType extends GenericObject>(
  direction: DragAndDropDirection,
  selectedItems: ItemType[],
  dataResultWithSelectedField: ItemType[],
  dataItemKey: string
) {
  const closestItem =
    direction === "before"
      ? selectedItems[0]
      : selectedItems[selectedItems.length - 1];

  const indexClosestItem = dataResultWithSelectedField.findIndex(
    (item) => item[dataItemKey] === closestItem[dataItemKey]
  );

  return indexClosestItem;
}

function getAnchorItemForClosestSelected<ItemType extends GenericObject>(
  direction: DragAndDropDirection,
  indexClosestSelectedItem: number,
  dataResultWithSelectedField: ItemType[]
) {
  if (direction === "before")
    return dataResultWithSelectedField[indexClosestSelectedItem - 1];

  return dataResultWithSelectedField[indexClosestSelectedItem + 1];
}

function findNonSelectedItemsBetweenSelected<ItemType extends GenericObject>(
  direction: DragAndDropDirection,
  selectedItems: ItemType[],
  dataResultWithSelectedField: ItemType[],
  dataItemKey: string
) {
  const beginningItem =
    direction === "before"
      ? selectedItems[selectedItems.length - 1]
      : selectedItems[0];

  const indexBeginningItem = dataResultWithSelectedField.findIndex(
    (item) => item[dataItemKey] === beginningItem[dataItemKey]
  );

  const itemsBetweenSelected =
    direction === "before"
      ? dataResultWithSelectedField.slice(0, indexBeginningItem)
      : dataResultWithSelectedField.slice(indexBeginningItem + 1);

  const nonSelectedItemsBetweenSelected = itemsBetweenSelected.filter(
    (item) => item[FIELD_SELECTED] !== true
  );

  return nonSelectedItemsBetweenSelected;
}

function getAnchorItemForMultipleSelectedItems<ItemType extends GenericObject>(
  direction: DragAndDropDirection,
  nonSelectedItemsBetweenSelected: ItemType[]
) {
  if (direction === "before") return nonSelectedItemsBetweenSelected[0];

  return nonSelectedItemsBetweenSelected[
    nonSelectedItemsBetweenSelected.length - 1
  ];
}
