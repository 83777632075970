const ABK_INPUT_READ_ONLY = "abk-read-only";

export function addClassNameInputReadOnly(
  className: string,
  readOnly: boolean
) {
  if (!readOnly) return className;

  return `${className} ${ABK_INPUT_READ_ONLY}`;
}

const ABK_INPUT_ERROR = "abk-input-error";

export function addClassNameInputError(
  className: string,
  errorMessage: React.ReactNode
) {
  if (!errorMessage) return className;

  return `${className} ${ABK_INPUT_ERROR}`;
}
