import "./style.scss";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import ABKConfirmationDialog from "../ABKConfirmationDialog";
import { useHotkeys } from "react-hotkeys-hook";
import useIsMobileTemplate from "../../../utility/useIsMobileTemplate";
import RawSaveBalken from "./RawSaveBalken";

export interface BalkenProps {
  edited: boolean;
  conflictMessage?: string;
  onSave: () => void;
  onReset: () => void;
  height?: number;
  buttonsAreUnderTheText?: boolean;
  isInline?: boolean;
}

const ABKSaveBalken: React.FC<BalkenProps> = (props) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const isMobileTemplate = useIsMobileTemplate();

  useHotkeys(
    "ctrl+s",
    () => {
      props.onSave();
    },
    {
      enabled: props.edited,
      enableOnFormTags: true,
      preventDefault: true,
    },
    [props.onSave, props.edited]
  );

  if (!props.edited) {
    return <></>;
  }

  const classNameForm = `balken-k-form ${isMobileTemplate ? "mobile" : ""}`;

  return (
    <>
      {showDialog && (
        <ABKConfirmationDialog
          message={
            <p
              style={{
                margin: "25px",
                textAlign: "center",
              }}
            >
              Sind Sie sicher, dass Sie die Änderungen verwerfen möchten?
            </p>
          }
          onConfirm={() => {
            setShowDialog(false);
            props.onReset();
          }}
          onClose={() => setShowDialog(false)}
        />
      )}
      <RawSaveBalken
        inline={props.isInline}
        height={props.height}
        balkenMessage={
          props.conflictMessage
            ? props.conflictMessage
            : "Ihre Änderungen wurden noch nicht gespeichert."
        }
      >
        {!props.conflictMessage && (
          <>
            <Button themeColor="primary" onClick={() => props.onSave()}>
              Speichern
            </Button>
          </>
        )}
        <Button onClick={() => setShowDialog(true)}>Verwerfen</Button>
      </RawSaveBalken>
    </>
  );
};

export default ABKSaveBalken;
