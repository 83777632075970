import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { GridColumnPropsCustom } from "../../../interfaces/GridColumns";
import { AbkIconsMap } from "../../../../../../interfaces/abk9SvgIcons";
import "./style.scss";
import {
  createColumnCheckboxes,
  FunctionColumnToggleVisibility,
} from "../../../hooks/useGridColumns/toggleColumnVisibility";

type Props = {
  columns: GridColumnPropsCustom[];
  onColumnToggleVisibility: FunctionColumnToggleVisibility;
};

export default function ABKToggleColumns({
  columns,
  onColumnToggleVisibility,
}: Props) {
  const columnCheckboxes = createColumnCheckboxes(
    columns,
    onColumnToggleVisibility
  );

  return (
    <div
      data-testid="grid-menu-toggle-columns"
      onClick={(e) => e.stopPropagation()}
    >
      <PanelBar className="abk-toggle-columns">
        <PanelBarItem
          title="Spalten ein- und ausblenden"
          svgIcon={AbkIconsMap.get("General.Import")}
        >
          <div className="wrapper-checkboxes-toggle-column">
            {columnCheckboxes}
          </div>
        </PanelBarItem>
      </PanelBar>
    </div>
  );
}
