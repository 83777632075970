import {
  EintragCheckboxValue,
  ShowIf,
  ShowIfDisplayed,
} from "../../../interfaces/parameterlisten";

export const parTypeMapper = new Map<ItemType, ItemTypeDisplayed>([
  ["Set", "Parameterset"],
  ["Head", "Überschrift"],
  ["Num", "Zahl"],
  ["Text", "Text (einzeilig)"],
  ["Memo", "Text (mehrzeilig)"],
  ["Date", "Datum"],
  ["Bool", "Ja/Nein"],
]);

export const parTypeReverseMapper = new Map<ItemTypeDisplayed, ItemType>([
  ["Parameterset", "Set"],
  ["Überschrift", "Head"],
  ["Text (einzeilig)", "Text"],
  ["Text (mehrzeilig)", "Memo"],
  ["Ja/Nein", "Bool"],
  ["Zahl", "Num"],
  ["Datum", "Date"],
]);

export const parTypeDisplayedList = [
  "Parameterset",
  "Überschrift",
  "Text (einzeilig)",
  "Text (mehrzeilig)",
  "Ja/Nein",
  "Zahl",
  "Datum",
];
export const parTypeList = [
  "Set",
  "Head",
  "Text",
  "Memo",
  "Bool",
  "Num",
  "Date",
] as const;

export type ItemTypeDisplayed =
  | "Parameterset"
  | "Überschrift"
  | "Text (einzeilig)"
  | "Text (mehrzeilig)"
  | "Ja/Nein"
  | "Zahl"
  | "Datum";
export type ItemType = (typeof parTypeList)[number];

export const eintragShowIfMapper = new Map<ShowIf, ShowIfDisplayed>([
  [ShowIf.ueberallAnzeigen, ShowIfDisplayed.ueberallAnzeigen],
  [ShowIf.hoehererEbene, ShowIfDisplayed.hoehererEbene],
  [ShowIf.niedrigerEbene, ShowIfDisplayed.niedrigerEbene],
  [ShowIf.immer, ShowIfDisplayed.immer],
]);
export const eintragShowIfReverseMapper = new Map<ShowIfDisplayed, ShowIf>([
  [ShowIfDisplayed.ueberallAnzeigen, ShowIf.ueberallAnzeigen],
  [ShowIfDisplayed.hoehererEbene, ShowIf.hoehererEbene],
  [ShowIfDisplayed.niedrigerEbene, ShowIf.niedrigerEbene],
  [ShowIfDisplayed.immer, ShowIf.immer],
]);

export const booleanFieldMapper = new Map<
  boolean | undefined,
  EintragCheckboxValue
>([
  [false, EintragCheckboxValue.notSelected],
  [undefined, EintragCheckboxValue.notSelected],
  [true, EintragCheckboxValue.selected],
]);

export const booleanFieldReverseMapper = new Map<
  EintragCheckboxValue | undefined,
  boolean
>([
  [EintragCheckboxValue.notSelected, false],
  [undefined, false],
  [EintragCheckboxValue.selected, true],
]);
