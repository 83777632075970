import { useParams } from "react-router-dom";
import { useIsValidDbAndContIdInURL } from "../../../../../core/utility/useIsValidDB";
import EintraegePage from "./EintraegePage";

const Eintraege = () => {
  const { isValid, db, contId } = useIsValidDbAndContIdInURL();
  const { plID } = useParams();
  if (!isValid || !plID) return <></>;

  return (
    <EintraegePage
      key={`eintraege-${db}-${contId}-${plID}`}
      db={db as string}
      contId={contId as string}
      plID={plID}
    />
  );
};

export default Eintraege;
