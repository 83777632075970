import React from "react";
import "./style.scss";
import { CustomCellObject } from "./components/CustomCell";
import { CreateGridActionsFunction } from "./interfaces/GridAction";
import { GridColumnsDefinition } from "./interfaces/GridColumns";
import { isMobile } from "../../../utility/detectDevice";
import { useResizeDetector } from "react-resize-detector";
import BasisGrid from "./BasisGrid";
import { MobileGridProps } from "./components/VirtualizedMobileGridRows";
import { GenericObject } from "../../../interfaces/GenericObject";
import { ReorderRowsConfig } from "./hooks/useGridReorderRows/gridReorderRowsInterfaces";
import { FunctionOnSelectionChange } from "./hooks/useGridRowSelection/gridRowSelectionInterfaces";
import { ABKPageLoader } from "../../atoms/ABKPageLoader";

type LocalStorageId = {
  sector: string;
  unique: string;
};

type GridInitialization = {
  selectedItems?: GenericObject[];
  scrollTop?: number;
};

export type ABKBasisGridProps = {
  data: GenericObject[];
  columnsDefinition: GridColumnsDefinition;
  persistedDataStateId: LocalStorageId;
  dataItemKey: string;
  mobileGrid?: MobileGridProps;
  gridActions?: CreateGridActionsFunction;
  onSelectionChange?: FunctionOnSelectionChange;
  isLoading?: boolean;
  customCells?: CustomCellObject[];
  groupable?: boolean;
  sortable?: boolean;
  initialization?: GridInitialization;
  reorderRowsConfig?: ReorderRowsConfig;
};

const ABKBasisGrid: React.FC<ABKBasisGridProps> = (
  props: ABKBasisGridProps
) => {
  const { width, ref: wrapperGridRef } = useResizeDetector();
  const isCalculatingWidth = width == null;
  const isSmallGrid = !isCalculatingWidth && width < 600;
  const isMobileGrid = isMobile() || isSmallGrid;

  let classNameWrapperGrid = "wrapper-grid";
  if (isCalculatingWidth) classNameWrapperGrid += " center-loader";

  return (
    <div ref={wrapperGridRef} className={classNameWrapperGrid}>
      {isCalculatingWidth ? (
        <ABKPageLoader />
      ) : (
        <BasisGrid
          {...props}
          isMobileGrid={isMobileGrid}
          wrapperGridRef={wrapperGridRef}
        />
      )}
    </div>
  );
};

export default ABKBasisGrid;
