import { BreadcrumbLinkProps } from "@progress/kendo-react-layout";
import { Link, useParams } from "react-router-dom";
import BreadcrumbDropDown from "./BreadcrumbDropDown";
import createLinkURL from "./createLinkURL";
import useLastBreadcrumb from "./useLastBreadcrumb";
import useDrawerStore from "../../../../stores/useDrawerStore";

export default function ABKBreadcrumbLink(props: BreadcrumbLinkProps) {
  const params = useParams();
  const database = params.db;
  const contId = params.contId;

  const { currentDrawerItems } = useDrawerStore();
  const { lastBreadcrumb } = useLastBreadcrumb();

  const breadcrumbId = props.id;
  const breadcrumb = (props as any).data.find((breadcrumb: any) => {
    return breadcrumb.id === breadcrumbId;
  });

  if (props.isLast) {
    if (breadcrumb.shouldOverrideBreadcrumb) return lastBreadcrumb;

    return (
      <BreadcrumbDropDown
        navigationItems={currentDrawerItems}
        currentItemId={props.text ?? ""}
        keyForItemId="text"
      />
    );
  }

  const disabled = props.disabled;
  return (
    <Link
      to={createLinkURL(breadcrumb?.path, database, contId)}
      aria-current={disabled}
      role="link"
      id={breadcrumbId}
      tabIndex={disabled ? -1 : 0}
      className={`k-breadcrumb-root-link k-breadcrumb-icontext-link ${disabled ? "k-disabled" : ""}`}
      dir="ltr"
      aria-disabled={disabled}
    >
      {props.icon}
      <span className="k-breadcrumb-item-text">{props.text}</span>
    </Link>
  );
}
