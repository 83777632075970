import {
  FILE_AUFBAU_SUFFIX,
  FILE_EINTRAEGE_SUFFIX,
  FILE_GRAFIKEN_SUFFIX,
  FILE_INFO_SUFFIX,
  FILE_PROTOCOL_SUFFIX,
  FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX,
} from "../../../../core/constants/urls";
import { FileInfo } from "../FileTypeMap";

const CGSFileInfo: FileInfo = {
  drawerItems: [
    FILE_INFO_SUFFIX,
    FILE_PROTOCOL_SUFFIX,
    FILE_AUFBAU_SUFFIX,
    FILE_EINTRAEGE_SUFFIX,
    FILE_GRAFIKEN_SUFFIX,
    FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX,
  ],
};

export default CGSFileInfo;
