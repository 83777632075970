import { Breadcrumb } from "@progress/kendo-react-layout";
import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { navigationItems } from "../../../../Router/Layout/navigationItems";
import { SvgIcon } from "@progress/kendo-react-common";
import * as Icons from "../../../../abk-shared/interfaces/abk9SvgIcons";
import { OPEN } from "../../../constants/urls";
import useMainPageTemplateState, {
  MAIN_PAGE_TEMPLATE,
} from "../../../stores/useMainPageTemplateState";
import ABKBreadcrumbLink from "./ABKBreadcrumbLink";

const StartBreadCrumb = {
  id: "startseite",
  text: "Startseite",
  icon: <SvgIcon icon={Icons.Menu_Start} style={{ marginRight: 5 }} />,
  path: OPEN,
};

export default function Breadcrumbs() {
  const params = useParams();
  const dbName = params.db;
  const contId = params.contId;

  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([StartBreadCrumb]);

  const { setMainPageTemplateTitle, setMainPageTemplateDescription } =
    useMainPageTemplateState();

  useEffect(() => {
    const segments = location.pathname.split("/").filter(Boolean);
    const breadcrumbData = segments
      .map((segment) => {
        if (!navigationItems.hasOwnProperty(segment)) return undefined;
        return navigationItems[segment as keyof typeof navigationItems](
          dbName,
          contId
        );
      })
      .filter((item) => !!item);

    const lastBreadcrumb = breadcrumbData[breadcrumbData.length - 1];
    if (lastBreadcrumb) {
      breadcrumbData[breadcrumbData.length - 1] = {
        ...lastBreadcrumb,
        disabled: true,
      } as any;
    }

    setBreadcrumbs([StartBreadCrumb, ...(breadcrumbData as any)]);

    const title = (breadcrumbData[breadcrumbData.length - 1] as any)?.title;
    /*
      Wir schreiben den "title", nur wenn es vorhanden ist.
      Wenn es nicht vorhanden ist, das bedeutet, dass das Kind-Component
      ein "setMainPageTemplateTitle" macht.
    */
    if (title) setMainPageTemplateTitle(title);

    setMainPageTemplateDescription(
      lastBreadcrumb?.description || MAIN_PAGE_TEMPLATE.DESCRIPTION
    );
  }, [
    location,
    dbName,
    contId,
    setMainPageTemplateDescription,
    setMainPageTemplateTitle,
  ]);

  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Breadcrumb
          size="medium"
          data={breadcrumbs}
          breadcrumbLink={(props) => <ABKBreadcrumbLink {...props} />}
        />
      </div>
    </React.Fragment>
  );
}
