import * as React from "react";
import {
  NormalizedDragEvent,
  useDraggable,
  useDroppable,
  classNames,
} from "@progress/kendo-react-common";
import { GridRowProps } from "@progress/kendo-react-grid";
import DragAndDropLine from "./DragAndDropLine";
import { DragAndDropDirection } from "../../../hooks/useGridReorderRows/gridReorderRowsInterfaces";
import {
  DragHintContext,
  GridContext,
  ReorderContext,
  SelectionContext,
} from "../../../hooks/useGridReorderRows/gridReorderRowsContexts";

/*
  Der Code in dieser Datei ist inspiriert von:
  https://www.telerik.com/kendo-react-ui/components/grid/rows/row-reordering/#toc-kendoreact-dragdrop
*/
export const DraggableRow = (props: { elementProps: any } & GridRowProps) => {
  const scrollableContainer = React.useRef<HTMLElement | null>(null);

  const [dropped, setDropped] = React.useState(false);
  const [dragged, setDragged] = React.useState(false);
  const [direction, setDirection] = React.useState<DragAndDropDirection | null>(
    null
  );

  const dragHint = React.useContext(DragHintContext);
  const grid = React.useContext(GridContext);
  const { dragStart, dragEnd, reorder } = React.useContext(ReorderContext);
  const { selectedState, setDataItemSelected } =
    React.useContext(SelectionContext);

  const isSelected = selectedState[props.dataItem.ITEMID] === true;

  const element = React.useRef<HTMLTableRowElement>(null);

  const handlePress = () => {};

  const handleDragStart = (event: NormalizedDragEvent) => {
    const isOnDragHandle =
      event.originalEvent.target &&
      (event.originalEvent.target as HTMLElement).dataset.dragHandle;
    if (!isOnDragHandle) return;

    setDragged(true);
    dragStart(props.dataItem);
  };

  const handleDrag = (event: NormalizedDragEvent) => {
    if (
      !dragHint ||
      !dragHint.current ||
      !grid ||
      !grid.current ||
      !grid.current.element
    ) {
      return;
    }

    const gridRect = grid.current.element.getBoundingClientRect();
    const dragHintRect = dragHint.current.getBoundingClientRect();

    dragHint.current.style.top = `${
      event.clientY - gridRect.top - dragHintRect.height / 2
    }px`;
    dragHint.current.style.left = `${event.clientX - gridRect.left - 11}px`;
  };

  const handleDragEnd = () => {
    setDragged(false);
    setDropped(false);
    dragEnd(props.dataItem);
  };

  const handleRelease = () => {
    if (!dragHint || !dragHint.current) {
      return;
    }
    dragHint.current.style.top = "";
  };

  const handleDragEnter = () => {
    setDropped(true);
    setDirection(null);
  };

  const handleDragOver = (event: NormalizedDragEvent) => {
    if (!element || !element.current) {
      return;
    }
    const rect = element.current.getBoundingClientRect();
    setDirection(
      rect.top + rect.height / 2 <= event.clientY ? "after" : "before"
    );
  };

  const handleDragLeave = () => {
    setDropped(false);
    setDirection(null);
  };

  const handleDrop = () => {
    reorder(props.dataItem, direction);
    setDropped(false);
    setDirection(null);
  };

  React.useEffect(() => {
    const virtualContent = document.querySelector(
      ".k-grid-content.k-virtual-content"
    ) as HTMLElement;
    scrollableContainer.current = virtualContent;
  }, []);

  useDraggable(
    element,
    {
      onPress: handlePress,
      onDragStart: handleDragStart,
      onDrag: handleDrag,
      onDragEnd: handleDragEnd,
      onRelease: handleRelease,
    },
    {
      autoScroll: dragged,
      hint: dragHint,
      scrollContainer: scrollableContainer,
    }
  );
  useDroppable(element, {
    onDragEnter: handleDragEnter,
    onDragOver: handleDragOver,
    onDragLeave: handleDragLeave,
    onDrop: handleDrop,
  });

  return (
    <>
      {dropped && direction === "before" && <DragAndDropLine />}
      <tr
        {...props.elementProps}
        ref={element}
        className={classNames(
          {
            "k-selected": isSelected,
          },
          props.elementProps.className
        )}
        style={{
          ...props.elementProps.style,
          userSelect: "none",
          pointerEvents: dragged ? "none" : "auto",
        }}
        onClick={() => setDataItemSelected(props.dataItem, true, true)}
      />
      {dropped && direction === "after" && <DragAndDropLine />}
    </>
  );
};
