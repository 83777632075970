import {
  FILE_INFO_SUFFIX,
  FILE_PROTOCOL_SUFFIX,
} from "../../../../core/constants/urls";
import { FileInfo } from "../FileTypeMap";

const CEKFileInfo: FileInfo = {
  drawerItems: [FILE_INFO_SUFFIX, FILE_PROTOCOL_SUFFIX],
};

export default CEKFileInfo;
