import abk from "../../../../core/assets/images/abk.png";

const AppInfo = () => {
  return (
    <div>
      <div>
        <a href="https://www.abk.at/">
          <img src={abk} alt="ABK Logo" />
        </a>
      </div>
    </div>
  );
};

export default AppInfo;
