import { PopupPropsContext } from "@progress/kendo-react-popup";
import DataStateContext from "./hooks/useGridDataState/DataStateContext";
import {
  GridContext,
  DragHintContext,
  ReorderContext,
  SelectionContext,
  GridRef,
} from "./hooks/useGridReorderRows/gridReorderRowsContexts";
import { GridReorderRows } from "./hooks/useGridReorderRows/gridReorderRowsInterfaces";
import { GridRowSelection } from "./hooks/useGridRowSelection/gridRowSelectionInterfaces";
import { GenericObject } from "../../../interfaces/GenericObject";
import { State } from "@progress/kendo-data-query";
import { CreateGridActionsFunction } from "./interfaces/GridAction";
import React from "react";

type Props = {
  children: React.ReactNode;
  gridRef: GridRef;
  gridReorderRows: GridReorderRows;
  gridRowSelection: GridRowSelection;
  dataState: State;
  dataResultWithSelectedField: GenericObject[];
  gridActions: CreateGridActionsFunction | undefined;
};
const BasisGridContextProvider = ({
  gridRef,
  children,
  gridReorderRows,
  gridRowSelection,
  dataState,
  dataResultWithSelectedField,
  gridActions,
}: Props) => (
  <GridContext.Provider value={gridRef}>
    <DragHintContext.Provider value={gridReorderRows.hintRef}>
      <ReorderContext.Provider
        value={{
          reorder: gridReorderRows.reorder,
          dragStart: gridReorderRows.dragStart,
          dragEnd: gridReorderRows.dragEnd,
          isReorderOnlyOnActiveItem: gridReorderRows.isReorderOnlyOnActiveItem,
        }}
      >
        <SelectionContext.Provider value={gridRowSelection}>
          <DataStateContext.Provider
            value={{ dataState, dataResultWithSelectedField, gridActions }}
          >
            <PopupPropsContext.Provider
              /*
                Ändere die `collision` props für all die Popups im Grid. Das behebt den
                folgenden Fehler:
                - Das Browser-Fenster klein machen
                - Das Column-Menü einer Spalte klicken
                - Das Column-Menü öffnet sich. Wenn das Column-Menü höher als das Fenster
                ist, wird eine Vertical Scrollbar auftauchen
                - Wenn die Scrollbar auftaucht, schließt sich sofort das Column-Menü
                -> Der User kann nicht das Column-Menü mit einem kleinen Bildschirm benutzen!
                Daher haben wir die `collision` props geändert, um keine Scrollbar im Body
                zu erstellen, wenn wir ein Column-Menü öffnen.
              */
              value={(props) => ({
                ...props,
                collision: {
                  horizontal: "fit",
                  vertical: "fit",
                },
              })}
            >
              {children}
            </PopupPropsContext.Provider>
          </DataStateContext.Provider>
        </SelectionContext.Provider>
      </ReorderContext.Provider>
    </DragHintContext.Provider>
  </GridContext.Provider>
);

export default BasisGridContextProvider;
