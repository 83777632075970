import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { ContWithLastChangedModified } from "../../../core/interfaces/Cont";
import { BACKEND_URL } from "../../../core/constants/urls";

const useDeleteCont = (database: string | undefined) => {
  const url = `${BACKEND_URL}/api/db/${database}/cont`;

  return useMutation({
    mutationFn: async (container: ContWithLastChangedModified) => {
      const containerId = container.CONTID;
      return axios.delete(`${url}/${containerId}`);
    },
  });
};

export default useDeleteCont;
