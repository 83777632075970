import {
  ExcelExport,
  ExcelExportData,
} from "@progress/kendo-react-excel-export";
import React from "react";
import createColumns from "../createColumns";
import { GridColumnPropsCustom } from "../interfaces/GridColumns";
import { GenericObject } from "../../../../interfaces/GenericObject";
import { State } from "@progress/kendo-data-query";
import useFieldsWithInternalValue from "./useFieldsWithInternalValue";
import { produce } from "immer";
import { DATA_KEY } from "../constants";
import {
  isGroupedItem,
  getValueOfFirstGroupedItem,
} from "../utils/groupingFunctions";

const useExcelExport = (
  columns: GridColumnPropsCustom[],
  data: GenericObject[],
  dataState: State
) => {
  const ref = React.useRef<ExcelExport>(null);
  const fieldsWithInternalValue = useFieldsWithInternalValue(columns);

  const excelExport = React.useCallback(() => {
    if (ref.current !== null) {
      const items = generateItemsWithOnlyDisplayedValue(
        data,
        fieldsWithInternalValue
      );

      const excelExportData: ExcelExportData = {
        data: items,
        group: dataState.group,
      };

      ref.current.save(excelExportData);
    }
  }, [ref.current, data, dataState, fieldsWithInternalValue]);

  const columnsWithoutSelected = columns.slice(1);
  const excelColumns = columnsWithoutSelected.map((parentColumn) =>
    createColumns("excelColumn", parentColumn, [], [])
  );

  return { ref, excelExport, columns: excelColumns };
};

export function generateItemsWithOnlyDisplayedValue(
  items: GenericObject[],
  fieldsWithInternalValue: string[]
) {
  if (fieldsWithInternalValue.length === 0) return items;

  const itemsGenerated = produce(items, (draft) => {
    for (const item of draft)
      setItemKeyToDisplayedValue(item, fieldsWithInternalValue);
  });

  return itemsGenerated;
}

function setItemKeyToDisplayedValue(
  item: GenericObject,
  fieldsWithInternalValue: string[]
) {
  if (!isGroupedItem(item)) {
    for (const field of fieldsWithInternalValue) {
      const valueToFlatten = item[field];

      if (valueToFlatten && valueToFlatten.hasOwnProperty(DATA_KEY.DISPLAYED)) {
        item[field] = valueToFlatten[DATA_KEY.DISPLAYED];
      }
    }
    return;
  }

  const field = item.field.replace(`.${DATA_KEY.INTERNAL}`, "");
  if (fieldsWithInternalValue.includes(field)) {
    item.field = field;
    item.value = getValueOfFirstGroupedItem(item, field);
  }

  for (const itemInGroup of item.items) {
    setItemKeyToDisplayedValue(itemInGroup, fieldsWithInternalValue);
  }
}

export default useExcelExport;
