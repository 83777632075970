import useIsValidDB from "../../../../core/utility/useIsValidDB";
import DbProtocolPage from "./DbProtocolPage";

const DbProtocolPageWrapper = () => {
  const { isValidDB, db } = useIsValidDB();
  if (!isValidDB) return <></>;

  return <DbProtocolPage key={`db-protocol-${db}`} dbName={db!} />;
};

export default DbProtocolPageWrapper;
