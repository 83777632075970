import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Parameterliste } from "../../../../../interfaces/parameterlisten";
import createParameterlisteURL from "../../createParameterlisteURL";
import { useNavigate } from "react-router-dom";

const useAddParameterliste = (dbName: string, contId: string) => {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async () =>
      axios.post<Parameterliste>(`/api/db/${dbName}/cont/${contId}/pl`),
    onSuccess: (response) => {
      const newParameterliste = response.data;
      const parameterlisteURL = createParameterlisteURL(
        newParameterliste.DATAID,
        newParameterliste.DBNAME,
        newParameterliste.CONTID
      );
      navigate(parameterlisteURL);
    },
  });
};

export default useAddParameterliste;
