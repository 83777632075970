import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Cont } from "../../../../core/interfaces/Cont";
import { useValidationError } from "../../../../abk-shared/stores/ValidationErrorStoreProvider";
import { setValidationErrorFromABKError } from "../../../../abk-shared/stores/ValidationErrorStoreProvider/useGetValidationErrorMessage";
import { QUERY_KEY_GET_CONTAINER } from "../../../Startseite/hooks/useGetCont";
import { QUERY_KEY_GET_CONT_INFO } from "../../../Startseite/hooks/useGetContInfo";
import { formatWithEtag } from "../../queries/formatWithEtag";

type MutationSaveContainer = {
  contId: string;
  container: Cont;
  etag: string | undefined;
};
export const useSaveContainer = (dbName: string) => {
  const queryClient = useQueryClient();
  const { setValidationErrors } = useValidationError();

  return useMutation({
    mutationFn: ({ contId, container, etag }: MutationSaveContainer) => {
      return axios.put(`/api/db/${dbName}/cont/${contId}`, container, {
        headers: {
          "If-Match": `"${etag}"`,
          "Content-Type": "application/json",
        },
      });
    },
    onSuccess: (response, variables) => {
      setValidationErrors([]);
      queryClient.setQueryData(
        [
          dbName,
          QUERY_KEY_GET_CONTAINER,
          QUERY_KEY_GET_CONT_INFO,
          variables.contId,
        ],
        formatWithEtag(response)
      );
    },
    onError: (error) =>
      setValidationErrorFromABKError(error, setValidationErrors),
  });
};
