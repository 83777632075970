import Dexie, { type Table } from "dexie";
import { Cont } from "../interfaces/Cont";
import { Parameterliste } from "../../modules/Datei/interfaces/parameterlisten";

interface ContainerInfoDraft {
  database: string;
  contId: string;
  etag: string;
  latestChange?: number;
  edited: Cont;
  sections: Map<string, boolean>;
}

interface ParameterlisteDraft {
  database: string;
  contId: string;
  dataId: string;
  etag: string;
  edited: Parameterliste;
  sections: Map<string, boolean>;
}

const db = new Dexie("Drafts") as Dexie & {
  containerInfoDrafts: Table<ContainerInfoDraft, string>;
  parameterListeDrafts: Table<ParameterlisteDraft, [string, string]>;
};

// Schema declaration:
db.version(1).stores({
  containerInfoDrafts: "++contId, database",
  parameterListeDrafts: "[contId+dataId], database",
});

export type { ContainerInfoDraft, ParameterlisteDraft };
export { db };
