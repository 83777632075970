import {
  DropDownList,
  DropDownListProps,
} from "@progress/kendo-react-dropdowns";

import ABKFormFieldInputWrapper from "../ABKFormFieldInputWrapper";
import ABKLabel from "../ABKLabel";
import ABKInfoPopup from "../ABKInfoPopup";
import ABKFormErrorMessage from "../ABKFormErrorMessage";
import { addClassNameInputError } from "../../../utility/addClassNameFormInput";
import useGetValidationErrorMessage from "../../../stores/ValidationErrorStoreProvider/useGetValidationErrorMessage";

interface Props extends DropDownListProps {
  label: string;
  editorId: string;
  tooltip?: string;
  errorMessage?: React.ReactNode;
  backendKey?: string;
}

export default function ABKFormDropdown({
  label,
  editorId,
  tooltip,
  backendKey,
  ...dropDownListProps
}: Props) {
  const errorMessage = useGetValidationErrorMessage(backendKey);
  let classNameDropDown = dropDownListProps.className ?? "";
  classNameDropDown = addClassNameInputError(classNameDropDown, errorMessage);

  return (
    <ABKFormFieldInputWrapper>
      <ABKLabel editorId={editorId}>
        {label}
        {tooltip && <ABKInfoPopup popupContent={tooltip} />}
      </ABKLabel>
      <DropDownList
        {...dropDownListProps}
        name={editorId}
        title={label}
        className={classNameDropDown}
      />
      {errorMessage && (
        <ABKFormErrorMessage>{errorMessage}</ABKFormErrorMessage>
      )}
    </ABKFormFieldInputWrapper>
  );
}
