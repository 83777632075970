import { SvgIcon } from "@progress/kendo-react-common";
import {
  Edit_Delete,
  Edit_Edit,
  Edit_Post,
  Grid_MoveUp,
  Grid_MoveDown,
} from "../../../interfaces/abk9SvgIcons";
import ABKFormFieldInput from "../../atoms/ABKFormFieldInput";
import useListItem from "./useListItem";
import { useState } from "react";
import "./styles.css";

function splitIntoPairs(arr: string[]): string[][] {
  const result: string[][] = [];
  for (let i = 0; i < arr.length; i += 2) {
    result.push(arr.slice(i, i + 2));
  }
  return result;
}

interface Props {
  dataItem: any;
  data: any[];
  setData: (data: any[]) => void;
  objectKeys: string[];
  columns: string[];
}

const MobileListItem = ({
  dataItem,
  data,
  setData,
  objectKeys,
  columns,
}: Props) => {
  const {
    handleSave,
    handleEdit,
    handleDelete,
    isEditMode,
    setEditedData,
    editedData,
  } = useListItem(dataItem, data, setData);

  const [isActive, setIsActive] = useState(false);

  const pairedKeys = splitIntoPairs(objectKeys);
  const pairedColumns = splitIntoPairs(columns);

  const moveUp = () => {
    const index = data.findIndex((item) => item.id === dataItem.id);
    if (index === 0) return;
    const newData = [...data];
    newData[index] = data[index - 1];
    newData[index - 1] = data[index];
    setData(newData);
  };

  const moveDown = () => {
    const index = data.findIndex((item) => item.id === dataItem.id);
    if (index === data.length - 1) return;
    const newData = [...data];
    newData[index] = data[index + 1];
    newData[index + 1] = data[index];
    setData(newData);
  };

  return (
    <div
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#F5F5F5",
        padding: 2,
        textAlign: "center",
        outline: "none",
        margin: "4px 0",
        borderRadius: 4,
        border: isActive ? "1px solid #B5B5B5" : "1px solid #F5F5F5",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <SvgIcon
            style={{ cursor: "pointer", marginRight: 2 }}
            icon={Grid_MoveUp}
            height={20}
            width={20}
            onClick={moveUp}
          />
          <SvgIcon
            style={{ marginRight: 2, cursor: "pointer" }}
            icon={Grid_MoveDown}
            height={20}
            width={20}
            onClick={moveDown}
          />
        </div>
        <div className="mobile-list-item-pairs-container">
          {pairedKeys.map((pair, index) => {
            const columns = pairedColumns[index];

            return (
              <div
                key={pair.toString()}
                style={{
                  display: "flex",
                  flexGrow: 1,
                }}
                className="mobile-list-item-input"
              >
                {pair.map((key, index) => {
                  return (
                    <ABKFormFieldInput
                      key={key}
                      label={columns[index]}
                      value={
                        !editedData[key] && !isEditMode
                          ? `(leer)`
                          : editedData[key]
                      }
                      editorId={key}
                      placeHolder={`(leer)`}
                      setValue={
                        !isEditMode
                          ? undefined
                          : (value) => {
                              setEditedData({ ...editedData, [key]: value });
                            }
                      }
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {!isEditMode && (
          <SvgIcon
            style={{ cursor: "pointer" }}
            icon={Edit_Edit}
            height={20}
            width={20}
            onClick={handleEdit}
          />
        )}

        {isEditMode && (
          <SvgIcon
            style={{ cursor: "pointer" }}
            icon={Edit_Post}
            height={20}
            width={20}
            onClick={handleSave}
          />
        )}

        <SvgIcon
          onClick={() => handleDelete(dataItem.id)}
          style={{ cursor: "pointer" }}
          icon={Edit_Delete}
          height={20}
          width={20}
        />
      </div>
    </div>
  );
};

export default MobileListItem;
