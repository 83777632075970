import { GridCellProps } from "@progress/kendo-react-grid";
import DragAndDropIcon from "./DragAndDropIcon";

// Inspiriert von: https://www.telerik.com/kendo-react-ui/components/grid/rows/row-reordering/#toc-kendoreact-dragdrop
export const DragHandleCell = (props: GridCellProps) => {
  const {
    ariaColumnIndex,
    dataItem,
    selectionChange,
    columnIndex,
    columnsCount,
    rowType,
    expanded,
    dataIndex,
    isSelected,
    ...rest
  } = props;

  return (
    <td {...(rest as any)} className={`${rest.className} drag-handle-cell`}>
      <DragAndDropIcon style={{ cursor: "move" }} dataDragHandle="true" />
    </td>
  );
};
