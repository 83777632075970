import {
  FILE_PL_KENNDATEN,
  DATABASE_PLACEHOLDER,
  CONTID_PLACEHOLDER,
} from "../../../../../core/constants/urls";

const createParameterlisteURL = (
  plID: string,
  dbName: string,
  contId: string
) =>
  FILE_PL_KENNDATEN.replace("%plID%", plID)
    .replace(DATABASE_PLACEHOLDER, dbName)
    .replace(CONTID_PLACEHOLDER, contId);

export default createParameterlisteURL;
