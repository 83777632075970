import { Address } from "src/abk-shared/components/atoms/ABKAddressSelect";
import { GridFieldWithInternalValue } from "src/abk-shared/components/organisms/ABKBasisGrid/constants";
import { LogoFormat } from "src/core/utility/imageProcessing";
import {
  ItemType,
  ItemTypeDisplayed,
} from "../pages/Parameterlisten/Einträge/maps";

export interface Herausgeber {
  firma: Firma;
  person: Kontaktperson;
}

export interface Kommunikation {
  adresse: Address;
  email: string;
  telefon: string;
  fax: string;
  www: string;
  zusatzinfo: string;
}

export interface Firma {
  name: string;
  kommunikation: Kommunikation;
}

export interface Kontaktperson {
  vorname: string;
  nachname: string;
  kommunikation: Kommunikation;
}

export interface Parameterliste {
  Meta: {
    Rowchanged: string;
    Right: string;
  };
  DBNAME: string;
  CONTID: string;
  DATAID: string;
  SUBTYPE: string;
  Kenndaten: Kenndaten;
  NOTIZ: string;
  ANMERK: string;
  REFCONTID: string;
  REFDATAID: string;
  ONDTEXP: boolean;
}

export interface Kenndaten {
  KENNUNG: string;
  VERSIONSNR: number;
  VERSIONSDATE: string | null;
  STATUS: ParameterlisteStatus;
  BEZ: string;
  BESCH: string;
  DLURL: string;
  Herausgeber: Herausgeber;
  LOGO: string;
  LOGOFORMAT: LogoFormat;
  FUNKTGSKENN: string;
  FUNKTGSDATAID: string;
  PRODGSKENN: string;
  PRODGSDATAID: string;
}

type KenndatenFrontend = Omit<Kenndaten, "STATUS"> & {
  STATUS: ParameterlisteStatusFrontend;
};
export type ParameterlisteFrontend = Omit<Parameterliste, "Kenndaten"> & {
  Kenndaten: KenndatenFrontend;
};

export type LogoFormatParameterlisten =
  | LogoFormat.JPEG
  | LogoFormat.PNG
  | LogoFormat.GIF
  | LogoFormat.EMPTY;

export enum ParameterlisteStatus {
  Entwurf = "E",
  Freigegeben = "F",
}

export enum ParameterlisteStatusFrontend {
  Entwurf = "Entwurf",
  Freigegeben = "Freigegeben",
}

export enum EintragBerechnungsart {
  keine = "Keine (Eingabe Kennwert)",
  summeBilden = " Summe bilden",
}

export interface EintragBase {
  Meta?: {
    InstanceApiUrl: string;
    Rowchanged: string;
    Right: string;
  };
  DBNAME: string;
  CONTID: string;
  DATAID: string;
  ITEMID: string;
  BEZ: string;
  KOMMENTAR: string;
  Funktion: string[];
  Produkt: string[];
  VARNAME: string;
  PIKTID: string;
  IFCReferenzen:
    | {
        Entity: string;
        Set: string;
        Property: string;
      }[]
    | null;
  EH: string;
  NUMMIN: number;
  NUMMAX: number;

  FORMEL: string;
  AWList_Num: number[];
  AWList_Text: AWList_Text[] | null;
  AWUSEREN: boolean;
}

export type EintragBackend = EintragBase & {
  ITEMTYPE: ItemType;
  FAVORIT: boolean;
  REQUIRED: boolean;
  SUMMEBILDEN: boolean;
  MENGENABH: boolean;
  NUMNK: number;
  NUMMIN: number;
  NUMMAX: number;
  SHOWIF: ShowIf;
};
export enum ShowIf {
  ueberallAnzeigen = "",
  hoehererEbene = "Pos",
  niedrigerEbene = "Sum",
  immer = "X",
}

export type EintragFrontend = EintragBase & {
  ITEMTYPE: ItemTypeDisplayed;
  FAVORIT: EintragCheckboxValue;
  REQUIRED: EintragCheckboxValue;
  SUMMEBILDEN: EintragCheckboxValue;
  MENGENABH: EintragCheckboxValue;
  NUMNK: GridFieldWithInternalValue;
  NUMMIN: GridFieldWithInternalValue;
  NUMMAX: GridFieldWithInternalValue;
  SHOWIF: ShowIfDisplayed;
};

export enum ShowIfDisplayed {
  ueberallAnzeigen = "Überall anzeigen (ÖNORM)",
  hoehererEbene = "Bei höherer Ebene (Summendarstellung) ausblenden",
  niedrigerEbene = "Bei niedrigerer Ebene (Positionswerte) ausblenden",
  immer = "Immer ausblenden",
}

export enum EintragCheckboxValue {
  selected = "Selektiert",
  notSelected = "Nicht selektiert",
}

export interface AWList_Text {
  Eintrag: string;
  Bezeichnung: string;
}
