import ABKFormFieldInput from "../../../../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import { Kommunikation } from "../../../../../../interfaces/parameterlisten";
import Email from "./Email";
import Website from "./Website";
import Phone from "./Phone";
import Fax from "./Fax";
import ABKFormRow from "../../../../../../../../abk-shared/components/atoms/ABKFormRow";

export type PropsCommunicationComponents = {
  value: string;
  readOnly: boolean;
  changeValue: (field: any, newValue: any) => void;
  backendKeyPrefix: string;
};

const ABKKommunikationSelect = ({
  value,
  setValue,
  readOnly,
  backendKeyPrefix,
}: {
  value: Kommunikation;
  setValue: (value: Kommunikation) => void;
  readOnly: boolean;
  backendKeyPrefix: string;
}) => {
  const changeValue = (field: string, newValue: any) => {
    const newKommunikation = { ...value, [field]: newValue };
    setValue && setValue(newKommunikation);
  };

  return (
    <>
      <ABKFormRow>
        <Phone
          value={value.telefon}
          readOnly={readOnly}
          changeValue={changeValue}
          backendKeyPrefix={backendKeyPrefix}
        />
        <Fax
          value={value.fax}
          readOnly={readOnly}
          changeValue={changeValue}
          backendKeyPrefix={backendKeyPrefix}
        />
      </ABKFormRow>
      <ABKFormRow>
        <Email
          value={value.email}
          readOnly={readOnly}
          changeValue={changeValue}
          backendKeyPrefix={backendKeyPrefix}
        />
        <Website
          value={value.www}
          readOnly={readOnly}
          changeValue={changeValue}
          backendKeyPrefix={backendKeyPrefix}
        />
      </ABKFormRow>
      <ABKFormRow>
        <ABKFormFieldInput
          editorId="zusatzInfo"
          label="Zusatzinfo"
          value={value.zusatzinfo}
          setValue={
            readOnly ? undefined : (value) => changeValue("zusatzinfo", value)
          }
          backendKey={`${backendKeyPrefix}.kommunikation.zusatzinfo`}
        />
      </ABKFormRow>
    </>
  );
};

export default ABKKommunikationSelect;
