import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import ABKExpansionPanel from "../../../../../../../abk-shared/components/atoms/ABKExpansionPanel";
import ABKFormDropdown from "../../../../../../../abk-shared/components/atoms/ABKFormDropdown";
import ABKFormFieldInput from "../../../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import ABKFormRow from "../../../../../../../abk-shared/components/atoms/ABKFormRow";
import ABKForm from "../../../../../../../abk-shared/components/molecules/ABKForm";
import {
  EintragFrontend,
  ShowIfDisplayed,
} from "../../../../../interfaces/parameterlisten";
import { parTypeDisplayedList } from "../../maps";
import { PropsEintragPanel } from "./ParTypePanelsMap";

export default function AllgemeinPanel({
  setPageState,
  pageState,
}: PropsEintragPanel) {
  const valuesEintragShowIf: ShowIfDisplayed[] = [
    ShowIfDisplayed.ueberallAnzeigen,
    ShowIfDisplayed.hoehererEbene,
    ShowIfDisplayed.niedrigerEbene,
    ShowIfDisplayed.immer,
  ];

  return (
    <ABKExpansionPanel defaultExpanded={true} title="Allgemein">
      <ABKForm
        sections={[
          {
            formElements: [
              <ABKFormRow>
                <ABKFormDropdown
                  label="Kategorie"
                  value={pageState?.ITEMTYPE}
                  data={parTypeDisplayedList}
                  editorId="PARTYP"
                  backendKey="ITEMID"
                  onChange={(event: DropDownListChangeEvent) =>
                    setPageState({
                      ...pageState,
                      ITEMTYPE: event.value,
                    } as EintragFrontend)
                  }
                />
                <ABKFormFieldInput
                  label="Bezeichnung"
                  value={pageState?.BEZ}
                  editorId="BEZ"
                  setValue={(value: string) =>
                    setPageState({
                      ...pageState,
                      BEZ: value,
                    } as EintragFrontend)
                  }
                  backendKey="BEZ"
                />
              </ABKFormRow>,

              <ABKFormRow isHalfRow={true}>
                <ABKFormDropdown
                  label="Ausblenden"
                  value={pageState?.SHOWIF}
                  data={valuesEintragShowIf}
                  editorId="HIDEPAR"
                  onChange={(event: DropDownListChangeEvent) =>
                    setPageState({
                      ...pageState,
                      SHOWIF: event.value,
                    } as EintragFrontend)
                  }
                />
              </ABKFormRow>,
            ],
          },
        ]}
      />
    </ABKExpansionPanel>
  );
}
