import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EintragFrontend } from "../../../../../interfaces/parameterlisten";
import axios from "axios";
import getQueryDataFromFirstEintrag from "./getQueryDataFromFirstEintrag";
import { QUERY_KEY_GET_EINTRAEGE } from "../../useGetEintraege";

export default function useDeleteEintrag(
  setIsGridLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (selectedEintraege: EintragFrontend[]) => {
      setIsGridLoading(true);

      const { dbName, contId, plID } =
        getQueryDataFromFirstEintrag(selectedEintraege);

      const deleteEintrag = async (itemId: string) => {
        const response = await axios.delete(
          `/api/db/${dbName}/cont/${contId}/pl/${plID}/items/${itemId}`
        );
        return response.data;
      };

      for (const eintrag of selectedEintraege)
        await deleteEintrag(eintrag.ITEMID);
    },
    onSuccess: async (_response, selectedEintraege) => {
      const { dbName, contId, plID } =
        getQueryDataFromFirstEintrag(selectedEintraege);

      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_GET_EINTRAEGE, dbName, contId, plID],
      });
    },
    onError: () => {
      setIsGridLoading(false);
    },
  });
}
