import { Input } from "@progress/kendo-react-inputs";
import { useHotkeys } from "react-hotkeys-hook";
import React from "react";
import ABKSearchBar from "src/abk-shared/components/molecules/ABKSearchBar";
import ABKMobileSearchBar from "src/abk-shared/components/molecules/ABKMobileSearchBar";

type Props = {
  isMobileGrid: boolean;
  filterValue: string;
  onFilterChange: (newValue: string) => void;
};

function ABKBasisGridSearch({
  isMobileGrid,
  filterValue,
  onFilterChange,
}: Props) {
  const inputRef = React.useRef<Input>(null);

  useHotkeys(
    "ctrl+e",
    (event) => {
      if (inputRef.current) inputRef.current.focus();
      event.preventDefault();
    },
    []
  );

  if (isMobileGrid) {
    return (
      <ABKMobileSearchBar
        filterValue={filterValue}
        onFilterChange={onFilterChange}
        inputRef={inputRef}
        isMobileGrid={isMobileGrid}
      />
    );
  }

  return (
    <ABKSearchBar
      filterValue={filterValue}
      onFilterChange={onFilterChange}
      inputRef={inputRef}
    />
  );
}

export default ABKBasisGridSearch;
