import { FixedSizeList } from "react-window";
import CardContainer from "../../../molecules/ABKCardContainer";
import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { GenericObject } from "../../../../interfaces/GenericObject";
import { ungroupDataItems } from "../utils/groupingFunctions";
import { FunctionSetDataItemSelected } from "../hooks/useGridRowSelection/gridRowSelectionInterfaces";
import {
  getScrollTopLocalStorage,
  setScrollTopLocalStorage,
} from "../hooks/useSetInitialScrollTop/scrollTopLocalStorage";
import { FIELD_SELECTED } from "../hooks/useGridColumns/gridActionColumns";

export type MobileGridProps = {
  rowHeight: number;
  renderRow: FunctionMobileGridRow;
};

export type FunctionMobileGridRow = (
  rowRef: React.RefObject<HTMLDivElement>,
  item: GenericObject,
  filterValue: string,
  isSelected: boolean,
  setDataItemSelected: FunctionSetDataItemSelected
) => React.ReactElement;

type Props = {
  dataItems: GenericObject[];
  mobileGridProps: MobileGridProps;
  filterValue: string;
  setDataItemSelected: FunctionSetDataItemSelected;
  gridUniqueId: string;
};

export default function VirtualizedMobileGridRows({
  dataItems,
  mobileGridProps,
  filterValue,
  setDataItemSelected,
  gridUniqueId,
}: Props) {
  const listRef = React.useRef<FixedSizeList>(null);
  const mobileScrollContainerRef = React.useRef<HTMLDivElement>(null);

  const flattenedDataItems = React.useMemo(
    () => ungroupDataItems(dataItems),
    [dataItems]
  );

  const Row = ({ index, style }: { index: number; style: any }) => {
    const rowRef = React.useRef<HTMLDivElement>(null);

    return (
      <div style={style}>
        {mobileGridProps.renderRow(
          rowRef,
          flattenedDataItems[index],
          filterValue,
          flattenedDataItems[index][FIELD_SELECTED],
          setDataItemSelected
        )}
      </div>
    );
  };

  return (
    <CardContainer hasGridLayout={false}>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <FixedSizeList
            ref={listRef}
            outerRef={mobileScrollContainerRef}
            height={height}
            width={width}
            itemCount={flattenedDataItems.length}
            initialScrollOffset={getScrollTopLocalStorage(gridUniqueId, true)}
            itemSize={mobileGridProps.rowHeight}
            onScroll={() => {
              const element = mobileScrollContainerRef.current;
              if (!element) return;

              setScrollTopLocalStorage(element, gridUniqueId, true);
            }}
          >
            {Row}
          </FixedSizeList>
        )}
      </AutoSizer>
    </CardContainer>
  );
}
