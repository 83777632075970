import { GridCustomCellProps } from "@progress/kendo-react-grid";
import { Cell } from "../../../components/CustomCell/HighlightedCell";

const CheckboxCell = (props: GridCustomCellProps) => (
  <Cell
    {...props}
    tdProps={{
      ...props.tdProps,
      className: `${props.tdProps?.className} checkbox-cell`,
    }}
  />
);

export default CheckboxCell;
