import { useState } from "react";
import ABKListView from "../../../../../../../abk-shared/components/organisms/ABKListView";
import ABKControlledExpansionPanel from "../../../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import EintragBearbeitenExpansionPanelVorschau from "./EintragBearbeitenExpansionPanelVorschau";
import { PropsEintragPanel } from "./ParTypePanelsMap";
import _ from "lodash";
import useIsMobileTemplate from "../../../../../../../abk-shared/utility/useIsMobileTemplate";

const AuswahllistePanel = ({ setPageState, pageState }: PropsEintragPanel) => {
  const [expanded, setExpanded] = useState(false);

  const isMobileTemplate = useIsMobileTemplate();

  const setData = (data: any[]) => {
    if (pageState) {
      setPageState({
        ...pageState,
        AWList_Text: data,
      });
    }
  };

  return (
    <ABKControlledExpansionPanel
      title={
        <EintragBearbeitenExpansionPanelVorschau
          title="Auswahlliste"
          fields={[
            {
              label: "Anzahl",
              value: pageState?.AWList_Text?.length
                ? `${pageState?.AWList_Text?.length} Einträge`
                : "0 Einträge",
              isCheckbox: false,
            },
          ]}
          data-testid="test-expansion-panel-title"
          shouldShowPreview={!expanded}
        />
      }
      expanded={expanded}
      onAction={() => setExpanded(!expanded)}
    >
      <ABKListView
        columnNames={["Wert", "Bezeichnung"]}
        objectKeys={["Eintrag", "Bezeichnung"]}
        setData={setData}
        isMobile={isMobileTemplate}
        data={pageState?.AWList_Text || []}
      />
    </ABKControlledExpansionPanel>
  );
};

export default AuswahllistePanel;
