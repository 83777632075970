import { PropsCommunicationComponents } from ".";
import ABKFormFieldInput from "../../../../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import shouldDisplayJSXInsteadOfInput from "./shouldDisplayJSXInsteadOfInput";

export default function Phone({
  value,
  readOnly,
  changeValue,
  backendKeyPrefix,
}: PropsCommunicationComponents) {
  const useJSXForValue = shouldDisplayJSXInsteadOfInput(value, readOnly);
  const faxFormatted = useJSXForValue ? (
    <a className="kenndaten-link" href={`tel:${value}`}>
      {value}
    </a>
  ) : (
    value
  );

  const setFax = readOnly
    ? undefined
    : (value: string) => changeValue("fax", value);

  return (
    <ABKFormFieldInput
      editorId="fax"
      label="Fax"
      value={faxFormatted}
      setValue={setFax}
      useJSXForValue={useJSXForValue}
      backendKey={`${backendKeyPrefix}.kommunikation.fax`}
    />
  );
}
