import { NumericTextBox } from "@progress/kendo-react-inputs";
import React from "react";
import ABKFormFieldInputWrapper from "../ABKFormFieldInputWrapper";
import ABKLabel from "../ABKLabel";
import ABKFormErrorMessage from "../ABKFormErrorMessage";
import {
  addClassNameInputError,
  addClassNameInputReadOnly,
} from "../../../utility/addClassNameFormInput";
import useGetValidationErrorMessage from "../../../stores/ValidationErrorStoreProvider/useGetValidationErrorMessage";
import ABKInfoPopup from "../ABKInfoPopup";

interface AbkFormFieldInputProps {
  editorId: string;
  label: string;
  value: number | null | undefined;
  setValue: (value: number | null) => void;
  readOnly: boolean;
  minValue?: number;
  maxValue?: number;
  onlyDecimal?: boolean;
  customFormat?: string;
  backendKey?: string;
  tooltip?: string;
}

const ABKNumericInput: React.FC<AbkFormFieldInputProps> = (props) => {
  const errorMessage = useGetValidationErrorMessage(props.backendKey);

  const handleValueChange = (event: any) => {
    const newValue = event.value;

    // If onlyDecimal is true, restrict to integer values only
    if (props.onlyDecimal && newValue % 1 !== 0) {
      return;
    }

    props.setValue(newValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (props.onlyDecimal && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  let classNameInput = "";
  classNameInput = addClassNameInputReadOnly(classNameInput, props.readOnly);
  classNameInput = addClassNameInputError(classNameInput, errorMessage);

  return (
    <ABKFormFieldInputWrapper>
      <ABKLabel editorId={props.editorId} className="k-d-flex">
        {props.label}
        {props.tooltip && <ABKInfoPopup popupContent={props.tooltip} />}
      </ABKLabel>
      <NumericTextBox
        className={classNameInput}
        id={props.editorId}
        name={props.editorId}
        value={props.value}
        onChange={handleValueChange}
        readOnly={props.readOnly}
        spinners={!props.readOnly}
        format={
          !props.customFormat
            ? {
                useGrouping: true,
                style: "decimal",
              }
            : props.customFormat
        }
        min={props.minValue}
        max={props.maxValue}
        onKeyDown={handleKeyDown}
      />
      {errorMessage && (
        <ABKFormErrorMessage>{errorMessage}</ABKFormErrorMessage>
      )}
    </ABKFormFieldInputWrapper>
  );
};

export default ABKNumericInput;
