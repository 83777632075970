import {
  ExcelExportColumnGroup,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import {
  GridColumn,
  GridColumnMenuProps,
  GridColumnProps,
} from "@progress/kendo-react-grid";
import { GridColumnPropsCustom } from "./interfaces/GridColumns";
import { ABKColumnMenu } from "./components/ABKBasisGridToolbar/ABKColumnMenu";
import { GenericObject } from "../../../interfaces/GenericObject";
import { generateItemsWithOnlyDisplayedValue } from "./hooks/useExcelExport";
import { isFieldOfActionColumn } from "./hooks/useGridColumns/gridActionColumns";

const components = {
  gridColumn: {
    parentComponent: GridColumn,
    childComponent: GridColumn,
  },
  excelColumn: {
    parentComponent: ExcelExportColumnGroup,
    childComponent: ExcelExportColumn,
  },
};

function createColumns(
  columnType: keyof typeof components, // From https://stackoverflow.com/a/62764510
  parentColumn: GridColumnPropsCustom,
  data: GenericObject[],
  fieldsWithInternalValue: string[],
  setWidth?: (
    minWidth: number | undefined,
    manualWidth: number | undefined
  ) => number
) {
  const ParentComponent = components[columnType].parentComponent;
  const ChildComponent = components[columnType].childComponent;

  const children = parentColumn.children;
  const hasChildren = Array.isArray(children);

  const isExcelColumn = columnType === "excelColumn";
  /*
    Excel Column: Wenn wir ein Parent nur mit einem Children haben, ist das Children
    nicht berücksichtigt. Wir sollen dann das Parent nicht zeigen.
  */
  const shouldOnlyDisplayChildren =
    hasChildren && isExcelColumn && children.length === 1;

  if (shouldOnlyDisplayChildren) {
    const child = children[0] as GridColumnProps;
    /*
      Die ChildrenComponent haben nicht die gleiche definition für die Property "width",
      deswegen sollen wir TypeScript für dieses Component deaktivieren.
    */
    // @ts-ignore
    return <ChildComponent {...child} key={child.field} />;
  }

  const gridParentColumnProps: GridColumnProps = {
    width:
      setWidth && setWidth(parentColumn.minWidth, parentColumn.manualWidth),
  };
  if (hasChildren) {
    gridParentColumnProps.groupable = false;
    gridParentColumnProps.sortable = false;
  }

  const items = generateItemsWithOnlyDisplayedValue(
    data,
    fieldsWithInternalValue
  );
  const columnMenu = (props: GridColumnMenuProps) => (
    <ABKColumnMenu {...props} data={items} />
  );

  const shouldHideColumnMenuForParentColumn =
    isExcelColumn || hasChildren || isFieldOfActionColumn(parentColumn.field);

  return (
    // Grund: wie vorher beschrieben
    // @ts-ignore
    <ParentComponent
      {...parentColumn}
      {...(!isExcelColumn && gridParentColumnProps)}
      key={parentColumn.field}
      columnMenu={shouldHideColumnMenuForParentColumn ? undefined : columnMenu}
    >
      {hasChildren
        ? children.map((child) => (
            <ChildComponent
              {...child}
              key={child.field}
              columnMenu={isExcelColumn ? undefined : columnMenu}
              {...(isExcelColumn
                ? {
                    width:
                      typeof child.width === "number" // For TypeScript, conversion to number
                        ? child.width
                        : parseInt(child.width ?? "0"),
                  }
                : {
                    width:
                      setWidth && setWidth(child.minWidth, child.manualWidth),
                  })}
            />
          ))
        : []}
    </ParentComponent>
  );
}

export default createColumns;
