import { Button } from "@progress/kendo-react-buttons";
import { AbkIconsMap } from "src/abk-shared/interfaces/abk9SvgIcons";
import { Card, CardImage } from "@progress/kendo-react-layout";
import "./styles.scss";
import { Checkbox } from "@progress/kendo-react-inputs";

interface ABKPictogramCardProps {
  id: string;
  title: string;
  iconBase64: string;
  isSelected: boolean;
  onDelete: (id: string) => void;
  onOpen: (id: string) => void;
  setSelected: (id: string, selected: boolean) => void;
}

const ABKPictogramCard = ({
  id,
  title,
  iconBase64,
  isSelected,
  onDelete,
  onOpen,
  setSelected,
}: ABKPictogramCardProps) => {
  return (
    <Card
      data-testid="abk-pictogram-card"
      className="pictogram-card-container"
      key={`pictogram-${id}`}
    >
      <CardImage
        src={iconBase64}
        alt="Icon fehlt"
        data-testid="abk-pictogram-card-picture"
        className="pictogram-card-picture"
      />
      <div
        data-testid="abk-pictogram-card-title"
        className="pictogram-card-title"
      >
        {title ?? "Kein Name"}
      </div>
      <div
        data-testid="abk-pictogram-card-buttons"
        className="pictogram-card-buttons-container"
      >
        <Checkbox
          checked={isSelected}
          onChange={(e) => setSelected(id, Boolean(e.target.value))}
        />

        <Button
          onClick={() => onDelete(id)}
          svgIcon={AbkIconsMap.get("Edit.Delete")}
          title="Löschen"
        />
        <Button
          onClick={() => onOpen(id)}
          svgIcon={AbkIconsMap.get("View.Show")}
          title="Ansehen"
        />
      </div>
    </Card>
  );
};

export default ABKPictogramCard;
