import {
  Parameterliste,
  ParameterlisteStatus,
} from "../../../../../interfaces/parameterlisten";
import ABKFormDropdown from "../../../../../../../abk-shared/components/atoms/ABKFormDropdown";

type Props = {
  pageState: Parameterliste;
  setPageState: (parameterliste: Parameterliste) => void;
};

export default function DropdownStatus({ pageState, setPageState }: Props) {
  const dataItemKey = "id";
  const textField = "text";
  const defaultValue = {
    [textField]: "Entwurf",
    [dataItemKey]: ParameterlisteStatus.Entwurf,
  };
  const statusOptions = [
    defaultValue,
    {
      [textField]: "Freigegeben",
      [dataItemKey]: ParameterlisteStatus.Freigegeben,
    },
  ];
  const backendKey = "STATUS";
  const value = statusOptions.find(
    (option) => option[dataItemKey] === pageState?.Kenndaten?.[backendKey]
  );

  return (
    <ABKFormDropdown
      label="Status"
      name="allgemein-status"
      textField={textField}
      dataItemKey={dataItemKey}
      data={statusOptions}
      value={value}
      onChange={(event) =>
        setPageState({
          ...pageState,
          Kenndaten: {
            ...pageState.Kenndaten,
            [backendKey]: event.value[dataItemKey],
          },
        })
      }
      editorId={"status"}
      backendKey={backendKey}
    />
  );
}
